import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Router, ActivatedRoute } from '@angular/router';
import { IfStmt } from '@angular/compiler';
import { DataService } from 'src/app/services/data.service';
import { appConfig } from 'src/app/app.config';
@Component({
  selector: 'app-my-quiver-account',
  templateUrl: './my-quiver-account.component.html',
  styleUrls: ['./my-quiver-account.component.css']
})
export class MyQuiverAccountComponent implements OnInit {
  modal: any = {};
  public users: any = {};
  public userid;
  public productList :any = [];
  public finsProductList : any = [];
  public forSaleProductList:any =[];
  public forReserveProductList:any =[];

  stolenProductList:any= []
  public searchproductList;
  public cartlist;
  public previewUrl = null
  saledata: any;
  public subtotal = 0;
  public grandtotal = 0;
  payment: any = {};
  public Tax = 0;
  public val = 0;
  payData: any = {};
  public cardvalues;
  public cardvaluesDetails;
  public payPalConfig?: IPayPalConfig;
  public Whishlist : any =[];
  public likeWhishlist = [];
  public catcoutnt;
  fileData: File = null;
  previewUrluser: any = null;
  model: any = {};
  uploadForm: FormGroup;
  fileid: any = 0;
  userTypes = [{ label: "Admin", value: "admin" },
  { label: "Customer", value: "customer" }];
  isTextFieldType: boolean;
  isConfirmTextFieldType: boolean;
  locationL: any = {};
  iconconfirm: any = {};
  likeList : any = [];
  icon: any;
  useraddress: any;
  usercountry: any;
  userstate: any;
  userscity: any;
  userspostcode: any;
  userphone: any;
  showicon : any ="no";
  likelistproduct : any = [];
  likelistproductfins : any = [];
  public FollowerList: any = [];
  public Followinglist: any = [];
  starsCount = 5;

  FollowingList: any = [];
  loggedInUser: any;
  selected_product = null;
  ulist = false;
  hs = appConfig.hs;


  constructor(private firewireservices: FireWireServices, private dataService: DataService, private formBuilder: FormBuilder, private _route: ActivatedRoute, private router: Router, private toastr: ToastrService, private spinner: NgxSpinnerService) {
    this.userid = localStorage.getItem('userId');
    this.loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))
    this.model.starsCount = this.loggedInUser?.rating?.rating;

    // console.log(this.userid)
    this.getAllProduct()
    
    this.getcartProductByUserID();
    this.getAlllikebyproduct();
    this.updateUserProfile();
    this.getUserProfile();
    this.getAllWishList(); 
    // this.getAllFollower();
    // this.getAllFollowing();
    this.getFollowingByUser();
    this.getAllFollowersbyUser();

  }

  ngOnInit(): void {   
   
   
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    })
  }

  getcartProductByUserID() {
    this.userid = localStorage.getItem('userId');
    var vals = {
      user_id: JSON.parse(this.userid)
    }
    this.firewireservices.getcartProductByUserID(vals)
      .subscribe(
        response => {
          this.cartlist = response.data.result;
          this.spinner.hide();
          // console.log("cartlist",this.cartlist)

        },

      )
  }

  getFollowingByUser() {
    this.spinner.show();
    this.firewireservices.getFollowingByUser(Number(this.loggedInUser.user_id))
      .subscribe(
        res => {
          // debugger
          if (res.data.verified == 1) {
            // debugger
            this.FollowingList = res.data.info
            this.spinner.hide();
          } else {
            this.FollowingList = []
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  getAllFollowersbyUser() {
    this.spinner.show();
    this.firewireservices.getAllFollowersbyUser(Number(this.loggedInUser.user_id))
      .subscribe(
        res => {
          if (res.data.verified == 1) {
            this.FollowerList = res.data.info
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  
  AddCart(data) {
    if (this.cartlist.length == 0) {

      var val = {
        product_id: data.product_id,
        user_id: this.userid,
        name: data.name,
        address: data.address,
        product_type: data.type,
        fin_system: data.fin_system,
        color: data.color,
        technology: data.technology,
        price: data.price,
        image: data.image,
        qty: "1",

      }
      this.spinner.show();
      this.firewireservices.addtocart(val)
        .subscribe(
          response => {
            // console.log(response.data);
            if (response.data.status == 1) {
              this.toastr.success(response.data.message);
              this.getcartProductByUserID();
              this.spinner.hide();
            } else {
              console.log("error");
            }

          },
          err => {
            this.toastr.error('Add To Cart Unsuccessfully');
            this.spinner.hide();
          }
        )

    } else {
      if (data.product_id == this.cartlist[0].product_id) {
        var val = {
          product_id: data.product_id,
          user_id: this.userid,
          name: data.name,
          address: data.address,
          product_type: data.type,
          fin_system: data.fin_system,
          color: data.color,
          technology: data.technology,
          price: data.price,
          image: data.image,
          qty: "1",


        }
        this.spinner.show();
        this.firewireservices.addtocart(val)
          .subscribe(
            response => {
              if (response.data.status == 1) {
                this.toastr.success(response.data.message);
                this.getcartProductByUserID();
                this.spinner.hide();
              } else {
                console.log("error");
              }
            },
            err => {
              this.toastr.error('Add To Cart Unsuccessfully');
              this.spinner.hide();

            }
          )
      } else {
        this.toastr.error('Please Remove value from Cart');
      }
    }
  }

  updateUserProfile() {
    var data = {
      user_id: JSON.parse(this.userid)
    }

    this.firewireservices.getUsersByID(data)
      .subscribe(
        response => {
          var data = response.data.result;
          if (data.length != 0) {
            this.users = response.data.result[0]
            if( this.users.address !="")
            {
              this.useraddress = this.users.address  + ","
            }
            if( this.users.city !="")
            {
              this.userscity = this.users.city + ","
            }
            if( this.users.state !="")
            {
              this.userstate = this.users.state + ","
            }            
            if( this.users.post_code !="")
            {
              this.userspostcode = this.users.post_code + ","
            }
           
            if( this.users.country !="")
            {
              this.usercountry = this.users.country 
            }                    
            if (this.users.image != '' && this.users.image != null) {
              // this.previewUrl = location.origin + '/' + this.users.image
              this.previewUrluser = appConfig.hs + this.users.image
            }else{

              this.previewUrluser  = "assets/images/Picture2.png"
            }
            
          }

        }
      )
  }


  gotoFollowers() {
    this.router.navigate(['/follower'], { queryParams: { user_id: this.loggedInUser.user_id } });
  }

  gotoFollowing() {
    this.router.navigate(['/following'], { queryParams: { user_id: this.loggedInUser.user_id } });
  }

  getAllProduct() {
    var data = {
      user_id: this.userid
    }
    this.spinner.show();
    this.firewireservices.getProductByuserID(data)
      .subscribe(
        response => {
         
          if (response.data.status == 1) {
            var data = response.data.result;
            console.log('data',data)
            var datamsg = response.data;
            var dataimg = response.data.result
            this.finsProductList = [];
            this.forSaleProductList = [];
            this.stolenProductList = [];
            this.productList = [];
            this.forReserveProductList = [];
            if (data.length > 0) {

                        
              for (var i =0; i < data.length;i++)
              {
                if(data[i].images != '' && data[i].images != null){
                  data[i].image = appConfig.hs +  data[i].images[0].image
                }
                else{
                  data[i].image= 'assets/images/Picture2.png'

                }

                if (data[i].boardstatus === "1") { // for sale
                  this.forSaleProductList.push(data[i])
                }
                if (data[i].boardstatus === "2") { // Stolen
                  this.stolenProductList.push(data[i])
                }

                if (data[i].boardstatus === "3") { // Quiver
                  this.productList.push(data[i])
                }

                if (data[i].boardstatus === "1" && data[i].payment_status === 2) { // for reserve
                  this.forReserveProductList.push(data[i])
                }

                // if (data[i].type === "fins") {
                //   this.finsProductList.push(data[i])
                // } else {
                //   this.productList.push(data[i])
                // }             
                
               }
              
              // console.log(str)

              // data.images.forEach(element => {
               
              //   console.log("element",element)
              //   if (element.image != '' && element.image != null) {
              //     element.image = location.origin + '/'+ element.image;
              //     element.image = "https://dhol.app" + '/' + element.image;
              //   } else {
              //     element.image = 'assets/images/Picture2.png'
              //   }
              //   if (element.type === "fins") {
              //     this.finsProductList.push(element)
              //   } else {
              //     this.productList.push(element)
              //   }

              // });
              this.getAllikeList();
              this.getAlllikebyproduct();
              // console.log(this.finsProductList)
              // console.log(this.productList)          
              this.spinner.hide();
            } else {
              // console.log(datamsg.message)
              this.productList = [];
              this.finsProductList =[]; 
              this.spinner.hide();
            }
          }
          else{
            this.productList = [];
            this.finsProductList =[];
            this.forSaleProductList = [];
            this.stolenProductList = [];
            this.productList = [];
            this.forReserveProductList = [];
          }
        },
        err => {
          this.spinner.hide();

        }
      )

  }



  WishListFunction(vale) {
    // console.log(vale)
    var data = {
      product_id: vale.product_id,
      user_id: this.userid,
      name: vale.name,
      status: vale.status,
      image: vale.image,
      type: vale.type,
      price: vale.price
    }
    this.spinner.show();
    this.firewireservices.AddWishList(data)
      .subscribe(
        response => {
          var data = response.data
          // console.log(data)
          if (data.status == 1) {
            this.getAllWishList();
            this.toastr.success(data.message);
          } else {
            this.toastr.error(data.message);
            this.spinner.hide();
          }
        },
        err => {
          this.toastr.error('Add To WishList Unsuccessfully');
          this.spinner.hide();

        }
      )
  }


  getAllWishList() {
    var dataval = {
      user_id: JSON.parse(this.userid)
    }
    this.spinner.show();
    this.firewireservices.getAllWishlist(dataval)
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {

            data.forEach(element => {
              if (element.icon != '' && element.icon != null) {
                element.icon = element.icon;
              } else {
                element.icon = 'assets/images/Picture2.png'
              }
            })
            this.Whishlist = data;
            this.getAllikeList();
            this.getAlllikebyproduct();
            this.spinner.hide();
          } else {
            this.Whishlist = [];
            console.log("not data found");
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }
  SaleValue(item) {
    var value={product_id:item.product_id,user_id:item.product_user}
    var dataval = {
      value: value, 
    }
    this.dataService.updateData(dataval)
    this.router.navigate(['sale-page'])
  }

  productlike(val){
   
    var data = {
      user_id : this.userid,
      product_id :val.product_id,
      product_user:val.user_id,      
      name: val.name,
      status: val.status,    
      type: val.type,      
      price: val.price,
      icon : val.image
    }
    this.spinner.show();
    this.firewireservices.productlike(data)
      .subscribe(
        response => {
          var data = response.data          
          if (data.status == 1) {
            // this.toastr.success(data.message);
            this.getAllWishList();
            this.getAllikeList();
            this.getAlllikebyproduct();
            this.spinner.hide();
          } else {            
            this.spinner.hide();
            this.likeWhishlist = [];
            this.Whishlist = [];
          }
        },
        err => {          
          console.log("error");
         }
      )
  }

  viewProduct(data) {
    // console.log(data)
    var val = {
      value: data
    }
    // console.log(data)
    this.dataService.updateData(val);
    this.router.navigate(['sale-page']);
  }

  UpdateDetails(data) {
    console.log("dataaaaaaaaaaaaaaaad", data)
    var odersum = {
      data: data,
    }

    this.dataService.updateData(odersum);
    this.router.navigate(['saledetailpage'])
  }

  onFileSelect(event, param) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.formData.append('file', file, file.name);  
      // console.log(file);
      this.fileid = param;
      this.uploadForm.get('profile').setValue(file);
      this.fileData = file;
      // this.Addimage(file);
    }

    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.fileData = <File>event.target.files[0]
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (_event) => {
        this.previewUrluser = reader.result;
        this.updateUserProfileImage();
      }
    }
  }


  getUserProfile() {

    // console.log(this.userid);
    var data = {
      user_id: JSON.parse(this.userid)
    }

    this.firewireservices.getUsersByID(data)
      .subscribe(
        response => {
          var data = response.data;
          // console.log("users", data);
          this.editcliendataResponse(data)
        }
      )
  }

  editcliendataResponse(data) {
    // console.log("slugurl",data);
    this.model.first_name = data.result[0].first_name;
    this.model.address = data.result[0].address;
    this.model.city = data.result[0].city;
    this.model.state = data.result[0].state;
    this.model.country = data.result[0].country;
    this.model.phone = data.result[0].phone;
    this.model.post_code = data.result[0].post_code;
    this.model.paypal_key = data.result[0].paypal_key;
    this.model.email = data.result[0].email;

  

    if ( data.result[0].image != '' &&  data.result[0].image != null &&  data.result[0].image != undefined) {
      // this.previewUrl = location.origin + '/' + this.users.image
      this.previewUrluser = appConfig.hs + data.result[0].image;
    }else{

      this.previewUrluser  = "assets/images/Picture2.png"
    }
  }

  RemoveProfileImage() {
    var data = {
      user_id: this.userid
    }
    this.spinner.show();
    this.firewireservices.RemoveProfileImage(data)
      .subscribe(
        response => {
          var data = response.data
          if (data.status == 1) {
            this.spinner.hide();
            this.previewUrluser = 'assets/images/Picture2.png'
            this.toastr.success(data.message);
          } else {
            this.spinner.hide();
            this.toastr.success(data.message);
          }

        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }
  updateUserProfileImage() {
    var formData = new FormData();
    formData.append('file', this.uploadForm.get('profile').value);
    formData.append('user_id', JSON.parse(this.userid));
    this.spinner.show();
    this.firewireservices.Updatefile(formData)
      .subscribe(
        response => {
          var data = response.data
          if (data.status == 1) {
            this.spinner.hide();
            // this.toastr.success(data.message);
          } else {
            this.spinner.hide();
            this.toastr.success(data.message);
          }

        },
        err => {
          // console.log("error");
          this.spinner.hide();
        }
      )
  }
  viewicon(values){
    this.showicon = values;
  }

  SaleValueU(value) {
    var dataval = {
      value: value,

    }
    this.dataService.updateData(dataval)
       this.router.navigate(['/user-product'], { queryParams: { user_id: value.user_id } });

    // this.router.navigate(['user-product'])
  }

    getLikes(product) {
    console.log('product', product)
  
    this.ulist = true;
    this.selected_product = product
  }

  closel() {
    this.ulist = false;
    this.selected_product = null
  }


  updateUserdata() {
    // if(this.model.confirmpassword == undefined){
    //   this.model.confirmpassword = ""
    // }
    
    if (this.model.first_name != "" && this.model.email !== "" && this.userid != null) {     
        if (this.model.password === this.model.confirmpassword) {          
          var datavalue = {
            user_id: this.userid,
            first_name: this.model.first_name,
            address: this.model.address,
            country: this.model.country,
            state: this.model.state,
            city: this.model.city,
            phone: this.model.phone,
            post_code: this.model.post_code,
            email: this.model.email,
            password: this.model.password,
            paypal_key: this.model.paypal_key,
            user_type: "Customer"
          }
          this.spinner.show();
          this.firewireservices.UpdateUser(datavalue)
            .subscribe(
              response => {
                var data = response.data             
                if (data.status == 1) {
                  // console.log(data.message)
                  this.toastr.success(data.message);                 
                  this.getUserProfile();
                  this.updateUserProfile();
                  this.spinner.hide();
                  // document.getElementById('openModalForschedule').click();
                } else {
                  this.spinner.hide();
                  this.toastr.error(data.message)
                }
              },
              err => {
                console.log(err);
                this.spinner.hide();
              }
            )
        }
      
    }
  }

  togglePasswordFieldType(id) {
    this.icon = id
    this.isTextFieldType = !this.isTextFieldType;
  }
  toggleConfirmPasswordFieldType(val) {
    this.iconconfirm = val
    this.isConfirmTextFieldType = !this.isConfirmTextFieldType;
  }
  keyPress(event: any) {
    const pattern = /[0-9]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  getAllikeList() {
    // var dataval = {
    //   user_id: this.userid
    // }
    this.spinner.show();
    this.firewireservices.getAllLikelist()
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {  
            // data.forEach(element => {
            //   if (element.image != '' && element.image != null) {
            //     element.image = "https://dhol.app" + '/' + element.image;
            //   } else {
            //     element.image = 'assets/images/Picture2.png'
            //   }
            // })        
            this.likeList = data;
            // var count = []
            
          // console.log( this.likeuser)
          var count = 0;
            this.productList.forEach(element => {   
                var status = this.likeList.find(x => x.product_id === element.product_id );
                if(status !=undefined){                 
                  element.count = Number(count) + 1;
                }
            });
            // this.likeList = this
            // console.log( this.productList);
            this.spinner.hide();
          } else {
            console.log("not data found");
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  getAlllikebyproduct() {
    // var dataval = {
    //   user_id: JSON.parse(this.userid)
    // }
    this.spinner.show();
    this.firewireservices.getAlllikebyproduct()
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {
            data.forEach(element => {
              if (element.image != '' && element.image != null) {
                element.image = appConfig.hs + element.image;
              
              }
            })
            this.likelistproduct = data;
            this.likelistproductfins = data;
            this.likeWhishlist = data
            var list=[]

            this.productList.forEach(board => {
              var cnt = 0;            
              var product;
              var img = [];
              this.likelistproduct.forEach(likes => {
                  if((board.product_id == likes.product_id)){                     
                        cnt = cnt + 1;
                        product = likes.product_id;
                        
                        
                        var iconImg = "assets/images/Picture2.png"
                        if(likes.image !=undefined && likes.image !=null && likes.image !=''){
                          img.push(likes.image);
                        }else{
                          img.push(iconImg)
                        }
                  }
                }); 
                var str = {
                product_id:product,
                image:img,
                totalLikes:cnt
                }
             
               list.push(str)   
              
            });
            this.likelistproduct = list
            
            // console.log(list) 

            let res = [];
            list.map(function (item) {
              var existItem = res.find(x => x.product_id == item.product_id);
              if (existItem) {
             }    
              else {                
               res.push(item);                
                console.log(res)
              }    
            });         
                    
        
          // console.log("likelistproduct",this.likelistproduct)

          var listfins=[]
          // console.log("finsProductList",this.finsProductList)

          this.finsProductList.forEach(fins => {
            var cnt = 0;            
            var product;
            var img = [];
            this.likelistproductfins.forEach(likesfin => {              
                if(fins.product_id == likesfin.product_id){                     
                      cnt = cnt + 1;
                      product = likesfin.product_id;
                      // img.push(likesfin.image);    
                      
                      var iconImg = "assets/images/Picture2.png"
                      if(likesfin.image !=undefined && likesfin.image !=null && likesfin.image !=''){
                        img.push(likesfin.image);
                      }else{
                        img.push(iconImg)
                      }
                }
              }); 
              var str = {
              product_id:product,
              image:img,
              totalLikes:cnt
              }           
              listfins.push(str);  
            
          });
          this.likelistproductfins = listfins

          // Whishlist
          var listwish=[]
          this.Whishlist.forEach(wish => {
            var cnt = 0;            
            var product;
            var img = [];
            this.likeWhishlist.forEach(likeswish => {              
                if(wish.product_id == likeswish.product_id){                     
                      cnt = cnt + 1;
                      product = likeswish.product_id;
                      // img.push(likeswish.image);   
                      
                      var iconImg = "assets/images/Picture2.png"
                      if(likeswish.image !=undefined && likeswish.image !=null && likeswish.image !=''){
                        img.push(likeswish.image);
                      }else{
                        img.push(iconImg)
                      }
                }
              }); 
              var str = {
              product_id:product,
              image:img,
              totalLikes:cnt
              }           
              listwish.push(str); 
          
          });
          this.likeWhishlist = listwish
          // console.log("likelistproductfins",this.likeWhishlist);
            this.spinner.hide();
          } else {
            console.log("not data found");
            this.likeWhishlist = [];
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }
  delteproductID : any;
  DeleteModal(dataval){
    this.delteproductID = dataval
  }
  DeleteProduct() {
    // console.log("dataval",dataval)
    var data = {
      product_id: this.delteproductID .product_id,
      user_id : this.userid
    }
    this.spinner.show();
    this.firewireservices.deleteQuiverProduct(data)
      .subscribe(
        response => {
          var data = response.data;
          // if (data.status == 1) {
            this.toastr.success(data.message);
            this.DeleteProductLike(this.delteproductID);
            this.getAllProduct();
            this.getAllikeList();
            this.getAlllikebyproduct();
            this.spinner.hide();
         //} 
        },
        err => {
          this.spinner.hide();
          this.toastr.error('Record not Deleted');
        }
      )
  }

  DeleteProductLike(dataval) {
    // console.log("dataval",dataval)
    var data = {
      product_id: dataval.product_id,      
    }
    this.spinner.show();
    this.firewireservices.deleteLikeDislikes(data)
      .subscribe(
        response => {
          var data = response.data;
          if (data.status == 1) {
            // this.toastr.success(data.message);

            this.productList = [];
            this.finsProductList =[];
            this.getAllProduct();
            this.getAllikeList();
            this.getAlllikebyproduct();
            this.spinner.hide();
          }
        },
        err => {
          this.spinner.hide();
          // this.toastr.error('Record not Deleted');
        }
      )
  }

  // updateUserdata() {
  //   // console.log("dataval",dataval)
  //   var datavalue = {
  //     user_id: this.userid,
  //     first_name: this.model.first_name,
  //     address: this.model.address,
  //     country: this.model.country,
  //     state: this.model.state,
  //     city: this.model.city,
  //     phone: this.model.phone,
  //     post_code: this.model.post_code,
  //     email: this.model.email,
  //     password: this.model.password,
  //     user_type: "Customer"
  //   }
  //   this.spinner.show();      
  //   this.firewireservices.UpdateUser(datavalue)
  //     .subscribe(
  //       response => {
  //         var data = response.data;
  //         if (data.status == 1) {            
  //           this.toastr.success(data.message);    
  //           this.getAllProduct();
  //           this.getAllikeList();
  //           this.getAlllikebyproduct();
  //           this.spinner.hide();
  //         } 
  //       },
  //       err => {
  //         this.spinner.hide();
  //         this.toastr.error('Record not Updated');
  //       }
  //     )
  // }

  // TosterShow(){
  //   this.toastr.success("j");  
  // }

  getAllFollower() {
    var data ={
      follow_id: this.userid,
    }
    this.firewireservices.getAllFollower(data)
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {
            this.FollowerList = data      
           console.log(this.FollowerList)   
            var cnt = 0;
            var list  = [];            
        
            this.FollowerList.forEach(follo => {               
                if (follo.follow_id == this.userid) {
                  cnt = cnt + 1;                                                                             
                }                
            });
            var str = {              
              totalfollowers: cnt          
            }
            list.push(str)           
            this.FollowerList = list           
          }else{
            var list = [];
            var str = {              
              totalfollowers: 0         
            }
            list.push(str)           
            this.FollowerList = list            
            // console.log("FollowerList",this.FollowerList)

          }
        }, err => {
          console.log("error");
        }
      )
  }

  getAllFollowing() {
    var data = {
      user_id: this.userid
    }
    this.firewireservices.getAllFollowing(data)
      .subscribe(
        response => {
          var data = response.data.result
          console.log(data)
          if (data.length > 0) {
            this.Followinglist = data;
            console.log( this.Followinglist)          
           
              var cnt = 0;
              var list  = [];             
              this.Followinglist.forEach(follo => {               
                  if (follo.user_id == this.userid) {
                    cnt = cnt + 1;                                                             
                  }                
              });
              var str = {              
                totalfollowing: cnt          
              }
              list.push(str)           
            this.Followinglist = list
            // console.log(this.followid)
            // console.log("Followinglist",this.Followinglist)
          }else{  
            var list = []
            var str = {              
              totalfollowing: 0          
            }
            list.push(str)           
          this.Followinglist = list
          // console.log(this.Followinglist.length)
          }
        }, err => {
          console.log("error");
        }
      )
  }
}
