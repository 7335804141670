import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { appConfig } from '../app.config';

@Injectable()
export class FireWireServices {

    // private baseUrl = appConfig.apiUrl;
    options: any;
    constructor(private http: HttpClient) { }


    createReview(data){
        return this.http.post<any>(appConfig.apiUrl + '/product/createReview', data)
        .map(res => {
            return res;
        });
    }


    getloggedInUserReview(data){
        return this.http.post<any>(appConfig.apiUrl + '/product/getloggedInUserReview', data)
        .map(res => {
            return res;
        });
    }

    registerInsert(user) {
        return this.http.post<any>(appConfig.apiUrl + '/auth/register', user)
            .map(res => {
                return res;
            });

    }
    getAllUsers() {
        return this.http.get<any>(appConfig.apiUrl + '/user/getAllUsers')
            .map(res => {
                return res;
            });
    }

    getAllSearch(data) {
        return this.http.post<any>(appConfig.apiUrl + '/user/searchUser', data)
            .map(res => {
                return res;
            });
    }

    UpdateUser(id) {
        return this.http.post<any>(appConfig.apiUrl + '/user/updateUser', id)
            .map(res => {
                return res;
            });
    }
    Updatefile(id) {
        return this.http.post<any>(appConfig.apiUrl + '/user/updateFile', id)
            .map(res => {
                return res;
            });
    }
    RemoveProfileImage(data) {
        return this.http.post<any>(appConfig.apiUrl + '/user/removeUserImg', data)
            .map(res => {
                return res;
            });
    }
    DeleteUser(id) {
        return this.http.post<any>(appConfig.apiUrl + '/user/deleteUser', id)
            .map(res => {
                return res;
            });
    }
    getUsersByID(id) {
        return this.http.post<any>(appConfig.apiUrl + '/user/getUsersByID', id)
            .map(res => {
                return res;
            });
    }

    // Product API

    AddProduct(product) {
        return this.http.post<any>(appConfig.apiUrl + '/product/createProduct', product)
            .map(res => {
                return res;
            });

    }
    addtocart(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/addtocart', data)
            .map(res => {
                return res;
            });
    }
    getProductByuserID(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getProductByuserID', data)
            .map(res => {
                return res;
            });
    }
    getProductImagesByProduct_id(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getProductImagesByProduct_id', data)
            .map(res => {
                return res;
            });
    }
    getcartProductByProductID(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getcartProductByProductID', data)
            .map(res => {
                return res;
            });
    }
    getcartProductByUserID(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getcartProductByUserID', data)
            .map(res => {
                return res;
            });
    }
    getProductByID(id) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getProductByID', id)
            .map(res => {
                return res;
            });
    }
    getAllProduct() {
        return this.http.get<any>(appConfig.apiUrl + '/product/getAllProducts')
            .map(res => {
                return res;
            });

    } 
    
    getHomeProduct() {
        return this.http.get<any>(appConfig.apiUrl + '/product/getHomeProducts')
            .map(res => {
                return res;
            });

    }
    getAllSearchProduct(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/searchProduct', data)
            .map(res => {
                return res;
            });
    }
    updateProductDetails(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/updateProduct', data)
            .map(res => {
                return res;
            });
    }

    updateProductFlag(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/updateProductFlag', data)
            .map(res => {
                return res;
            });
    }
    DeleteProduct(id) {
        return this.http.post<any>(appConfig.apiUrl + '/product/deleteProduct', id)
            .map(res => {
                return res;
            });
    }
    removeProductFromCart(id) {
        return this.http.post<any>(appConfig.apiUrl + '/product/removeProductFromCart', id)
            .map(res => {
                return res;
            });
    }
    // Payment
    // https://208.109.15.102:3001/payment/createPayment

    addPayment(data) {
        return this.http.post<any>(appConfig.apiUrl + '/payment/createPayment', data)
            .map(res => {
                return res;
            });
    }

    changePassword(data) {
        return this.http.post<any>(appConfig.apiUrl + '/user/changePassword', data)
            .map(res => {
                return res;
            });
    }
    getPaymentByID(id) {
        return this.http.post<any>(appConfig.apiUrl + '/payment/getPaymentByID', id)
            .map(res => {
                return res;
            });
    }
    getAllPayments() {
        return this.http.get<any>(appConfig.apiUrl + '/payment/getAllPayments')
            .map(res => {
                return res;
            });

    }

    buyPackage(data) {
        return this.http.post<any>(appConfig.apiUrl + '/payment/createPaymentPaypal', data)
            .map(res => {
                return res;
            });
    }

    //Wish List 
    // addLikeDislike
    AddWishList(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/addtowishlist', data)
            .map(res => {
                return res;
            });

    }
    productlike(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/addLikeDislike', data)
            .map(res => {
                return res;
            });

    }  
    getFollowers(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getFollowers', data)
            .map(res => {
                return res;
            });

    }
    UserFollow(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/addFollowUnfollow', data)
            .map(res => {
                return res;
            });
    }
    getAllFollower(id) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getTotalFollowers',id)
            .map(res => {
                return res;
            });
    }
    getAllFollowing(id) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getTotalFollowings',id)
            .map(res => {
                return res;
            });
    }
    getAllLikelist() {
        return this.http.get<any>(appConfig.apiUrl + '/product/getAllLikesDislikes')
            .map(res => {
                return res;
            });
    }
    getAllFollowersbyUser(id) {
        return this.http.get<any>(appConfig.apiUrl + '/user/getAllFollowersbyUser/'+ id)
            .map(res => {
                return res;
            });
    }

    getFollowingByUser(id){
        return this.http.get<any>(appConfig.apiUrl + '/user/getFollowingByUser/'+ id)
        .map(res => {
            return res;
        });
    }

    getFollowingOfUser(id) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getFollowingOfUser', id)
        .map(res => {
            return res;
        });
      }
    getAlllikebyproduct() {
        return this.http.get<any>(appConfig.apiUrl + '/product/getAllNoLikes')
            .map(res => {
                return res;
            });
    }
    getAllWishlist(id) {
        return this.http.post<any>(appConfig.apiUrl + '/product/getwishlistByID', id)
            .map(res => {
                return res;
            });
    }
    deleteQuiverProduct(id) {
        return this.http.post<any>(appConfig.apiUrl + '/product/deleteQuiverProduct', id)
            .map(res => {
                return res;
            });
    }
    deleteLikeDislikes(id) {
        return this.http.post<any>(appConfig.apiUrl + '/product/deleteLikeDislikes', id)
            .map(res => {
                return res;
            });
    }
    removeItemFromWishlist(id) {
        return this.http.post<any>(appConfig.apiUrl + '/product/removeProductFromWishlist', id)
            .map(res => {
                return res;
            });
    }
    // Transaction API


    AddTransaction(product) {
        return this.http.post<any>(appConfig.apiUrl + '/transaction/createTransaction', product)
            .map(res => {
                return res;
            });

    }

    getTransactionByID(data) {
        return this.http.post<any>(appConfig.apiUrl + '/transaction/getTransactionByuserID', data)
            .map(res => {
                return res;
            });


    }
    updateTransaction(data) {
        return this.http.post<any>(appConfig.apiUrl + '/transaction/updateTransaction', data)
            .map(res => {
                return res;
            });
    }
    DeleteTransaction(id) {
        return this.http.post<any>(appConfig.apiUrl + '/transaction/deleteTransaction', id)
            .map(res => {
                return res;
            });
    }


    // Location API

    AddLocation(data) {
        return this.http.post<any>(appConfig.apiUrl + '/location/createLocation', data)
            .map(res => {
                return res;
            });

    }

    getAllLocation(data) {
        return this.http.get<any>(appConfig.apiUrl + '/location/getAllLocations', data)
            .map(res => {
                return res;
            });

    }

    getLocationByuserID(id) {
        return this.http.post<any>(appConfig.apiUrl + '/location/getLocationByuserID', id)
            .map(res => {
                return res;
            });
    }

    getAllSearchLocation(data) {
        return this.http.post<any>(appConfig.apiUrl + '/location/searchLocation', data)
            .map(res => {
                return res;
            });
    }
    updateLocation(data) {
        return this.http.post<any>(appConfig.apiUrl + '/location/updateLocation', data)
            .map(res => {
                return res;
            });
    }
    DeleteLocation(id) {
        return this.http.post<any>(appConfig.apiUrl + '/location/deleteLocation', id)
            .map(res => {
                return res;
            });
    }

    // Brand Uploads API


    getAllBrands() {
        return this.http.get<any>(appConfig.apiUrl + '/brands/getAllBrands')
            .map(res => {
                return res;
            });
    }

    // Marketing API  

    getAllMarketingLogo() {
        return this.http.get<any>(appConfig.apiUrl + '/marketing/getAllLogos')
            .map(res => {
                return res;
            });
    }


    // Message API

    sendMessage(user) {
        return this.http.post<any>(appConfig.apiUrl + '/message/sendMessage', user)
            .map(res => {
                return res;
            });
    }
    getMessageByUser(userid) {
        return this.http.post<any>(appConfig.apiUrl + '/message/getMessageByUser', userid)
            .map(res => {
                return res;
            });
    }
    getFooterData() {
        return this.http.get<any>(appConfig.apiUrl + '/product/getFooterData')
            .map(res => {
                return res;
            });
    }

    //Forgot password
    forgotPassword(val) {
        return this.http.post<any>(appConfig.apiUrl + '/auth/forgot_password_email', val)
            .map(res => {
                return res;
            });
    }

    //

    getmessageConvo(data) {
        return this.http.post<any>(appConfig.apiUrl + '/message/getmessageConvo', data)
            .map(res => {
                return res;
            });
    }
    deleteMessage(data) {
        return this.http.post<any>(appConfig.apiUrl + '/message/deleteMessage', data)
            .map(res => {
                return res;
            });
    }



    deleteGalleryImg(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/deleteProductImages', data)
            .map(res => {
                return res;
            });
    }

    reserveBoard(id) {
        return this.http.post<any>(appConfig.apiUrl + '/reserve_product/reserve_product', id)
            .map(res => {
                return res;
            });
    }

    getAllVideo() {
        return this.http.post<any>(appConfig.apiUrl + '/video/getAllVideo', '')
            .map(res => {
                return res;
            });
    }

    
    paymentstatus(data) {
        return this.http.post<any>(appConfig.apiUrl + '/product/updateProductPaymentStatus', data)
            .map(res => {
                return res;
            });
    }

}