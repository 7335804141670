import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from 'src/app/app.config';
import { DataService } from 'src/app/services/data.service';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-search-result-user',
  templateUrl: './search-result-user.component.html',
  styleUrls: ['./search-result-user.component.css']
})
export class SearchResultUserComponent implements OnInit {
  hs = appConfig.hs;
  follow = []
  ulist: boolean = false;
  selected_product = [];
  username: any = {};
  user: any = {};
  public itemlist = 0;
  public userid;
  public ProductDetails;
  selectedheart: any = [];
  ProductModal = false;
  public previewUrl = null
  itemval: any = {};
  varyfietoken: any;
  likelistproduct: any = [];
  selectedlike: any = [];
  productList: any = [];
  public searchuserlist: any = [];
  likeList: any;
  FollowerList: any = [];
  Followinglist: any = [];
  datava: any = {};
  loggedInUser:any;
  public userlist;


  constructor(private firewireservices: FireWireServices, private formBuilder: FormBuilder, private dataService: DataService, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private _route: ActivatedRoute, private router: Router) {
      this.loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))

    this.userid = localStorage.getItem('userId');
    this.varyfietoken = localStorage.getItem('token');
    this.datava = localStorage.getItem("productuser_id");    
      
  }

  ngOnInit(): void {
    this.dataService.share
      .subscribe(
        data => {
          this.username = data;
          if (this.username != "") {
            this.searchUser();
          }
        }
      )
  }
 

  chageslikeicon(vals, id) {
    // this.likeID = itemval.id
    this.selectedlike[id] = vals
    if (this.selectedlike[id] == "no") {
      // window.location.reload();
      // this.getAllProduct();    
    }
    // 
  }
  searchUser() {
    var data = {
      first_name: this.username.first_name,
    }
    this.spinner.show();
    this.firewireservices.getAllSearch(data)
      .subscribe(
        response => {
          var data = response.data.result
          this.itemlist = data.length;
          if (data.length > 0) {
            data.forEach(element => {
              if (element.image != '' && element.image != null) {
                // element.image = location.origin + '/' + element.image;
                element.image = appConfig.hs + element.image;
              } else {
                element.image = 'assets/images/Picture2.png'
              }
            })
            this.searchuserlist = data;
            this.spinner.hide();
            // this.getAllFollowersbyUser();
          } else {
            this.searchuserlist = [];
            this.spinner.hide();
          }

        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  openModal(dataval) {
    this.user = dataval.user_id
    localStorage.setItem("productuser_id", dataval.user_id);
   
  }

  SaleValue(value) {
    var dataval = {
      value: value,
    }
    this.dataService.updateData(dataval)
    localStorage.setItem('searchUser', JSON.stringify(dataval))
    console.log(dataval)
    // this.router.navigate(['user-product'])
   this.router.navigate(['/user-product'], { queryParams: { user_id: value.user_id } });
  }

  getAllFollowersbyUser() {
    // var dataval = {
    //   user_id: this.userid
    // }
    this.spinner.show();
    this.firewireservices.getAllFollowersbyUser(Number(this.loggedInUser.user_id))
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {
            this.follow = data;
            this.FollowerList = data;           
            console.log(this.FollowerList)
            var list = [];
            this.searchuserlist.forEach(searus => {
              var cnt = 0;
              var userID;
              var img1;
              var img2;
              var img = [];
              this.FollowerList.forEach(follous => {                            
                  if (searus.user_id == follous.follow_id) {
                    cnt = cnt + 1;
                    userID = follous.follow_id; 
                    var iconImg = "assets/images/Picture2.png"
                    if(follous.icon !=undefined && follous.icon !=null && follous.icon !=''){
                      img.push(follous.icon);
                    }else{
                      img.push(iconImg)
                    }                                        
                  }                
              });
              var str = {
                user_id: userID,  
                image:img,             
                totalLikes: cnt,            
              }
              list.push(str);
            });
            this.FollowerList = list          
            this.spinner.hide();
          } else {
            this.FollowerList = [];
            console.log("not data found");
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }


  
SaleValueU(value) {
  var dataval = {
    value: value,
    
  }
  this.dataService.updateData(dataval)
  // this.router.navigate(['user-product'])
 this.router.navigate(['/user-product'], { queryParams: { user_id: value.user_id } });

}

  getLikes(product){
    
    this.ulist = true;
    console.log(this.follow)
    this.follow.forEach(e => {
      if(e.follow_id == product.user_id){
        this.selected_product.push(e)
      }
    });
     console.log(this.selected_product)
  }
  closel(){
    this.ulist = false;
    this.selected_product = []
  }

  goToUserdata(item) {
    console.log(item)
    this.router.navigate(['/user-product'], { queryParams: { user_id: item.user_id } });
  }

}
