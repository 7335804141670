import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from 'src/app/app.config';
import { DataService } from 'src/app/services/data.service';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css']
})
export class MessageListComponent implements OnInit {
user = localStorage.getItem('userId');
  constructor(private router:Router,private userService:UserService,private dataService: DataService,private firewireservices: FireWireServices,private toastr: ToastrService,private spinner: NgxSpinnerService) {
    this.getAllUserMessages()
   }

     
public messageList =[]
  ngOnInit(): void {
   
  }
  gotoChat(item){
    // console.log(item)
    // alert()
    localStorage.setItem('chatTo',item.first_name)
    localStorage.setItem('subject_name',item.subject_name)
    localStorage.setItem('recid',item.user_id)
    localStorage.setItem('msgtbl_id',item.msgtbl_id)
    localStorage.setItem('id',item.id)
    this.dataService.updateData(item)

    // this.router.navigate['message-chat']
    this.router.navigate(['message-chat'])
  }
  

  // getAllUserMessages(){
    
  //   var userID = {
  //     user_id:this.user
  //   }
  //   // console.log(userID)
  //   this.spinner.show();
  //   this.firewireservices.getMessageByUser(userID)
  //     .subscribe(
  //       response => {
  //         var data = response
  //         var list = []
  //       var messageData =data.data.result;
  //         // console.log(this.messageList)
  //         // console.log( messageData)

  //     this.firewireservices.getAllUsers()
  //     .subscribe(
  //       response => {
  //         var data = response
  //         var userData =data.data.result;
  //         // console.log(this.messageList)
         
  //         var subjectname;
  //         userData.forEach(user => {
  //           var cnt = 0;
  //           var tempid;
  //           var userid1;
  //           var userid2;
  //           var userstatus1; 
  //           var userstatus2;
  //           var icon;
  //           var id;
  //           var msgtblID;
  //           var product_name;
  //             messageData.forEach(msg => {
  //               if(user.user_id == msg.sent || user.user_id ==msg.received){
                
                  
  //                 if(user.user_id != this.user){  
  //                   if(msg.received == this.user && msg.is_read =="0" ){
  //                     cnt = cnt + 1;
                      
  //                    }
  //                  tempid = user.user_id
  //                 } 
  //                 subjectname = msg.subject_name
  //                 msgtblID=msg.msgtbl_id
  //                 id = msg.id

  //                 // console.log(subjectname)
  //                 userid1 = msg.userid_1;
  //                 userid2 = msg.userid_2;
  //                 userstatus1 = msg.user1status;
  //                 userstatus2 = msg.user2status;
  //                 icon = appConfig.hs +msg.icon;
  //                 product_name = msg.product_name;
  //               }
  //             }); 
  //             var str ={user_id:user.user_id,
  //               id:id,
  //               msgtbl_id:msgtblID,
  //               first_name:user.first_name,
  //               image: 'assets/images/Picture2.png',
  //               icon: icon,
  //               product_name:product_name,
  //               subject_name:subjectname,
  //               msgUnread:cnt,
  //               userid1:userid1,
  //               userid2:userid2,
  //               userstatus1:userstatus1,
  //               userstatus2:userstatus2
              
  //             }
             
  //             if(user.user_id == tempid){
  //               list.push(str) 
  //             }
             
  //             // console.log(cnt) 
  //         });
  //         // console.log(list)
         
  //         let res = [];
  //         list.map(function (item) {
  //           var existItem = res.find(x => x.first_name == item.first_name);
  //           if (existItem) {
  
  //           }
  
  //           else {
              
  //             res.push(item);
              
  //             // console.log(res)
  //           }
  
  //         });
  //         this.messageList = res;
  //         console.log(res)

  //       var listmsg=[]
         
        
  //         // this.messageList = list;
  //         // console.log(this.messageList) 

  //         this.spinner.hide();
  //       },
  //       err => {
  //         // this.toastr.error('Add To WishList Unsuccessfully');
         

  //       }
  //     )
 
  //       },
  //       err => {
  //         // this.toastr.error('Add To WishList Unsuccessfully');
         

  //       }
  //     )
  // }
  getAllUserMessages() {
    var userID = {
      user_id: this.user
    }
    this.spinner.show();
    this.firewireservices.getMessageByUser(userID)
      .subscribe(
        res => {
          if (res.data.verified == 1) {
          var list = []
          var messageData = res.data.info;
          console.log('message',messageData)
          this.firewireservices.getAllUsers()
            .subscribe(
              response => {
                // var data = response
                var userData = response.data.result;
                var subjectname;
                userData.forEach(user => {
                  var cnt = 0;
                  var tempid;
                  var userid1;
                  var userid2;
                  var userstatus1;
                  var userstatus2;
                  var icon;
                  var id;
                  var msgtblID;
                  var product_name;
                  messageData.forEach(msg => {
                    if (user.user_id == msg.sent || user.user_id == msg.received) {
                      if (user.user_id != this.user) {
                        if (msg.received == this.user && msg.is_read == "0") {
                          cnt = cnt + 1;
                        }
                        tempid = user.user_id
                      }
                      subjectname = msg.subject_name
                      msgtblID = msg.msgtbl_id
                      id = msg.id
                      userid1 = msg.userid_1;
                      userid2 = msg.userid_2;
                      userstatus1 = msg.user1status;
                      userstatus2 = msg.user2status;
                      icon = appConfig.hs + msg.icon;
                      product_name = msg.product_name;
                    }
                  });
                  var str = {
                    user_id: user.user_id,
                    id: id,
                    msgtbl_id: msgtblID,
                    first_name: user.first_name,
                    image: 'assets/images/Picture2.png',
                    icon: icon,
                    product_name: product_name,
                    subject_name: subjectname,
                    msgUnread: cnt,
                    userid1: userid1,
                    userid2: userid2,
                    userstatus1: userstatus1,
                    userstatus2: userstatus2
                  }
                  console.log('userid',user.user_id )
                  console.log('tempid', tempid)
                  if (user.user_id == tempid) {
                    list.push(str)
                  }
                });
                console.log('list', list)
                let res = [];
                list.map(function (item) {
                  var existItem = res.find(x => x.first_name == item.first_name);
                  if (existItem) {
                  }
                  else {
                    res.push(item);
                  }
                });
                this.messageList = res;
                this.dataService.updateDataMessage(true);
                console.log(res)
                var listmsg = []
                this.spinner.hide();
             
              },
              err => {
              }
            )
              
            } else {
            
              this.messageList = []
            }
        },
        err => {
        }
      )
  }
  // getAllUserMessages() {
  //   var userID = {
  //     user_id: this.user
  //   }
  //   this.spinner.show();
  //   this.firewireservices.getMessageByUser(userID)
  //     .subscribe(
  //       res => {
  //         if (res.data.verified == 1) {
  //         var list = []
  //         var messageData = res.data.info;
  //         this.firewireservices.getAllUsers()
  //           .subscribe(
  //             response => {
  //               // var data = response
  //               var userData = response.data.result;
  //               var subjectname;
  //               userData.forEach(user => {
  //                 var cnt = 0;
  //                 var tempid;
  //                 var userid1;
  //                 var userid2;
  //                 var userstatus1;
  //                 var userstatus2;
  //                 var icon;
  //                 var id;
  //                 var msgtblID;
  //                 var product_name;
  //                 messageData.forEach(msg => {
  //                   if (user.user_id == msg.sent || user.user_id == msg.received) {
  //                     if (user.user_id != this.user) {
  //                       if (msg.received == this.user && msg.is_read == "0") {
  //                         cnt = cnt + 1;
  //                       }
  //                       tempid = user.user_id
  //                     }
  //                     subjectname = msg.subject_name
  //                     msgtblID = msg.msgtbl_id
  //                     id = msg.id
  //                     userid1 = msg.userid_1;
  //                     userid2 = msg.userid_2;
  //                     userstatus1 = msg.user1status;
  //                     userstatus2 = msg.user2status;
  //                     icon = appConfig.hs + msg.icon;
  //                     product_name = msg.product_name;
  //                   }
  //                 });
  //                 var str = {
  //                   user_id: user.user_id,
  //                   id: id,
  //                   msgtbl_id: msgtblID,
  //                   first_name: user.first_name,
  //                   image: 'assets/images/Picture2.png',
  //                   icon: icon,
  //                   product_name: product_name,
  //                   subject_name: subjectname,
  //                   msgUnread: cnt,
  //                   userid1: userid1,
  //                   userid2: userid2,
  //                   userstatus1: userstatus1,
  //                   userstatus2: userstatus2
  //                 }
  //                 if (user.user_id == tempid) {
  //                   list.push(str)
  //                 }
  //               });
  //               let res = [];
  //               list.map(function (item) {
  //                 var existItem = res.find(x => x.first_name == item.first_name);
  //                 if (existItem) {
  //                 }
  //                 else {
  //                   res.push(item);
  //                 }
  //               });
  //               this.messageList = res;
  //               console.log(res)
  //               var listmsg = []
  //               this.spinner.hide();
               
  //             },
  //             err => {
  //             }
  //           )
  //           } else {
  //             this.messageList = []
  //           }
  //       },
  //       err => {
  //       }
  //     )
  // }

  msgDelete:any={}
openDelete(items){
  this.msgDelete = items
  console.log(this.msgDelete)
}
  deleteMessage() {
    // console.log(items)
    // var recid = localStorage.getItem('recid')
    var data = {
      sent:this.user ,
      received:this.msgDelete.user_id,
      subject_name:this.msgDelete.subject_name,
      msgtbl_id:this.msgDelete.msgtbl_id
    }
    // console.log(data)
    this.spinner.show();
    this.firewireservices.deleteMessage(data)
      .subscribe(
        response => {
           var data = response.data
        if (data.status == 1) {
          this.getAllUserMessages();
          this.spinner.hide();
          this.toastr.success(data.message);
        } else {
          this.spinner.hide();
          this.toastr.error(data.message);
        }

      },
      err => {
        // console.log("error");
        this.spinner.hide();
      }
      )

  }
}


