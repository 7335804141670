import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { appConfig } from '../app.config';
import { JwtHelperService } from '@auth0/angular-jwt';

 
@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }
 
    login(username: string, password: string) {
        return this.http.post<any>(appConfig.apiUrl+'/auth/signin', { email: username, password: password })
            .map(res => {
                // console.log(res);
                var user = res.data;
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                   
                    // console.log('Login Success')
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('userId', user.user.user_id);
                    localStorage.setItem('userType', user.user.user_type);
                    localStorage.setItem('token', JSON.stringify(user.token));
                    localStorage.setItem('name', user.user.email);                   
                }
                return user;
            });
    }
    public isAuthenticated(): boolean {
        const token = localStorage.getItem('token');    
        // Check whether the token is expired and return
        // true or false
        const helper = new JwtHelperService();
        // console.log(!helper.isTokenExpired(token));
        return !helper.isTokenExpired(token);
    }

    public isLogout (){
        localStorage.removeItem('token');
        localStorage.clear();
    }
 
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('token');
    }
}