import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  
  private content = new BehaviorSubject<object>({});
  private productcontent = new BehaviorSubject<object>({});

  public share = this.content.asObservable()
  public prodcutshare = this.productcontent.asObservable() 
 
  private contentmsg = new BehaviorSubject<object>({});
  public productcontentmsg = this.contentmsg.asObservable()

  constructor() { }
  updateData(text) {
    this.content.next(text)
  }


  updateDataDetails(text) {
    this.productcontent.next(text)
  }

  updateDataMessage(text) {
    this.contentmsg.next(text)
  }
}
