import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { Router, ActivatedRoute, RoutesRecognized, NavigationStart } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { DatePipe } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import 'rxjs/add/operator/pairwise';
import { date } from 'ngx-custom-validators/src/app/date/validator';
import { number } from 'ngx-custom-validators/src/app/number/validator';

@Component({
  selector: 'app-reserve-payment-page',
  templateUrl: './reserve-payment-page.component.html',
  styleUrls: ['./reserve-payment-page.component.css']
})
export class ReservePaymentPageComponent implements OnInit {

  public userid;
  payment: any = {};
  public cardvalues;
  Radionbuttoncheck: null;
  public payPalConfig?: IPayPalConfig;
  public subtotal: any = 0;
  public grandtotal: any = 0;
  public Tax: any = 0;
  public val: any = 0;
  public userlist;
  public curentdate;
  payAmt: any;
  payData: any = {}
  public fixrate = null;
  public pic_up = null
  public isTax: boolean = false;
  previousUrl: any;
  currentUrl: string;
  reserveData: any;
  reserveStatus : any;
  sellerData: any;
  transactionId: any
  // private monthList: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  // selectmonth;
  public monthList = [
    { value: "1", label: 'Jan' },
    { value: "2", label: 'Feb' },
    { value: "3", label: 'Mar' },
    { value: "4", label: 'Apr' },
    { value: "5", label: 'May' },
    { value: "6", label: 'June' },
    { value: "7", label: 'July' },
    { value: "8", label: 'Aug' },
    { value: "9", label: 'Sep' },
    { value: "10", label: 'Oct' },
    { value: "11", label: 'Nov' },
    { value: "12", label: 'Dec' }
  ];
  paymentdata;
  isAmountValid : Boolean = true;
  paymentStatusData :any;
  buyerData: any;
  loggedInUser: any;

  constructor(private datePipe: DatePipe, private firewireservices: FireWireServices, private dataService: DataService, private formBuilder: FormBuilder, private toastr: ToastrService, private _route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService) {
    this.userid = localStorage.getItem('userId');
    this.paymentdata = localStorage.getItem('paymentDetails');
      this.sellerData = JSON.parse(localStorage.getItem('sellerData'))
      this.buyerData = localStorage.getItem('buyerData')
      this.loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))

    this.reserveData = JSON.parse(localStorage.getItem('Product_reserve'))
    console.log(this.reserveData)
    this.payment.reserve_amount = this.reserveData.reserve_amount
    this.subtotal = this.reserveData.calPrice
    this.grandtotal  = this.subtotal
    // if(this.reserveData.pic_up != "null" && this.reserveData.pic_up != "undefined" && this.reserveData.pic_up != null){
    //   this.grandtotal = this.reserveData.pic_up;
    // } else {
    //   this.grandtotal  = this.subtotal
    // }
    // if(this.reserveData.fixrate != "null"  && this.reserveData.fixrate != "undefined"){
      // this.fixrate = this.reserveData.fixrate;
    // }
    // this.payData = this.grandtotal
    this.payData = this.payment.reserve_amount
    this.getUserdatByData();
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects
        this.currentUrl = events[1].urlAfterRedirects
        // console.log("previousUrl", this.previousUrl);
        // console.log("currentUrl", this.currentUrl);


      });

  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  taxVal: any = {}
  ngOnInit(): void {
   
  

    // this.dataService.share
    //   .subscribe(
    //     data => {
    //       this.payAmt = data;
    //       // console.log(this.payAmt)       
    //       this.subtotal = this.payAmt.Subtotal,
    //         this.grandtotal = Number(this.payAmt.GrandTotalVal);
    //       this.payData = this.grandtotal
    //       // console.log(this.payAmt.pic_up)
    //       if (this.payAmt.pic_up != null && this.payAmt.pic_up != undefined && this.payAmt.pic_up != 'undefined') {
    //         this.pic_up = this.payAmt.pic_up;
    //       }
    //       if (this.payAmt.fixrate != null && this.payAmt.fixrate != undefined && this.payAmt.fixrate != 'undefined') {
    //         this.fixrate = this.payAmt.fixrate;
    //       }
    //       // console.log(this.payAmt.tax)
    //       if (this.payAmt.tax != null && this.payAmt.tax != 0 && this.payAmt.tax != 'undefined') {
    //         this.Tax = this.payAmt.tax
    //         this.taxVal = this.payAmt.taxval
    //         this.isTax = true;
    //       } else {
    //         this.isTax = false;
    //       }
    //     })
    this.initConfig();

    // var date = new Date();
    // this.curentdate = this.datePipe.transform(date, "yyyy-MM-dd")
    // this.payment.expire_date = this.curentdate;

    // console.log(this.payment.year)

  }

  checkAmount() {
    console.log(this.payment.reserve_amount)
    if( this.payment.reserve_amount < this.reserveData.reserve_amount){
      this.toastr.error('Payment amount must be greater than Reserve Amount')
      this.isAmountValid = false;
      
      } else {
        this.isAmountValid = true;
      }
  }

  SelectValuescard(cardvalue, no) {
    this.cardvalues = cardvalue;
    this.Radionbuttoncheck = no;
    // console.log(this.cardvalues)
  }
  // checkDate(){
  //   var currentDate = new Date();
  //   var dateOne = new Date(this.payment.year, this.payment.expire_date, 0)
  //   if(currentDate > dateOne){
  //     alert('Expired Year')
  //   } 
  // }
  

  addPayment() {
    var paymenttoken = localStorage.getItem('token');
    if (this.Radionbuttoncheck == '1') {


      // if (paymenttoken != null ) { 
 

      if (this.payment.name_on_card != undefined && this.payment.card_number != undefined && this.payment.expire_date != undefined && this.payment.year != undefined && this.userid != "") {
       
        var currentDate = new Date();
        var dateOne = new Date(this.payment.year, this.payment.expire_date, 0)
        if(currentDate > dateOne){
          this.toastr.error('Enter valid expire year or month')
        }else{
          // alert('valid')
        
       
        var data = {
          name_on_card: this.payment.name_on_card,
          user_id: JSON.parse(this.userid),
          card_number: this.payment.card_number,
          expire_date: this.payment.expire_date,
          year: this.payment.year,
          security_code: this.payment.security_code,
          sub_total: this.subtotal,
          shipping: this.val,
          tax: this.Tax,
          total: this.grandtotal,
          payment_type: "Completed",

        }
       
        // console.log(data)
        this.spinner.show();
        this.firewireservices.addPayment(data)
          .subscribe(
            response => {
              var data = response.data
              if (data.status == 1) {
                // this.toastr.success(data.message);                  
                this.AddTransaction();
              } else {
                console.log("error");
                this.toastr.error('Payment Cancel');

                this.spinner.hide();
              }
            }, err => {
              this.toastr.error('Payment Cancel');
              this.spinner.hide();
            });

      }
    }
    }
    else {
      this.toastr.error('Please Selected Payment Method');
    }


  }



  getUserdatByData() {
    var valdata = {
      user_id: this.userid
    }
    this.spinner.show();
    this.firewireservices.getUsersByID(valdata)
      .subscribe(
        response => {
          this.userlist = response.data.result[0]
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
        }
      )
  }

  AddTransaction() {
    // var comm = (this.grandtotal * 3 / 100);
    if(Number(this.payment.reserve_amount) < Number(this.grandtotal)){
      this.reserveStatus = 1 // reserve
      this.paymentStatusData = 2 //  not full payment
    }
    if(Number(this.payment.reserve_amount) == Number(this.grandtotal)){
      this.reserveStatus = 2 // sold
      this.paymentStatusData = 1 // full payment
    }
    var datavalue = {
      user_id: this.userid,
      product_id: this.reserveData.product_id,
      transaction_type: "Purchase",
      product_type: this.reserveData.product_type,
      product_name: this.reserveData.name,
      email: this.userlist.email,
      first_name: this.userlist.first_name,
      seller_name: this.reserveData.seller_name,
      contact: this.userlist.phone,
      commision: '',
      amount: this.grandtotal,
      reserve_amount: this.payment.reserve_amount, //
      image: this.reserveData.image,
      city: this.reserveData.city,
      address: this.reserveData.address,
      post_code: this.reserveData.post_code,
      country: this.reserveData.country,
      state: this.reserveData.state,
      paystatus: "Completed",
      reserve_status:  this.reserveStatus //
    }
    // console.log(datavalue)
    this.firewireservices.AddTransaction(datavalue)
      .subscribe(
        response => {
          var data = response.data
          if (data.status == 1) {
            // console.log(data.message)
            // this.removeProductFromCart(this.payAmt.product_id)
            this.paymentStatus();
           console.log(data)
            console.log("addcart")
            var dataid = response.data.result
            console.log(dataid)
            this.transactionId = dataid.transaction_id
            console.log(this.transactionId)
            this.addProductFromCart();
            this.router.navigate(['thankyou']);
            // localStorage.removeItem('Product_Details');
            // this.dataService.updateData(dataid);
            
            var datainfo = {
              value: dataid
            }
            this.dataService.updateData(datainfo)
           
            this.toastr.success('Payment Successfully');
            this.spinner.hide();
          } else {
            console.log("error");
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  // Transactiondata(dataval){

  //   var data = {
  //     value : dataval          
  //   }
  //   this.dataService.updateData(data)
  //   this.router.navigate(['sale-page'])

  // }

  paymentStatus() {
    let sendData= {
      product_id: this.reserveData.product_id,
      payment_status: this.paymentStatusData,
      reserve_user_id : this.userid
    }
    this.firewireservices.paymentstatus(sendData)
    .subscribe(
      response => {
        if (response.data.status == 1) {
            // this.toastr.success(response.data.message);
            // this.spinner.hide();          
        } else {
          // this.spinner.hide();
          // this.toastr.error(response.data.message);
        }
      },
      err => {
        console.log("error",err);
        // this.spinner.hide();
      }
    )
  }

  addProductFromCart() {
    console.log('incart')
    var val = {
      product_id: this.reserveData.product_id,
      seller_name: this.sellerData.first_name,
      seller_id : this.sellerData.user_id,
      user_fcm_token: this.loggedInUser.fcm_token,
      seller_fcm_token : this.sellerData.fcm_token,
      user_id: this.userid,
      name: this.reserveData.name,
      address: this.reserveData.address,
      product_type: this.reserveData.product_type,
      fin_system: this.reserveData.fin_system,
      color: this.reserveData.color,
      size: this.reserveData.size,
      technology: this.reserveData.technology,
      price: this.reserveData.price,
      calPrice:this.reserveData.price,
      reserve_amount: this.reserveData.reserve_amount,
      image :this.reserveData.image,
      tax: this.reserveData.tax,
      fixrate: this.reserveData.fixrate,
      pic_up: this.reserveData.pic_up,
      status: this.reserveData.status,
      trasaction_id: this.transactionId,
      qty: "1",

    }
    console.log('val', val)
    localStorage.setItem('Product_Details', JSON.stringify(val));
    console.log('product_details')
    this.router.navigate(['cart-page'])
  }
  
  removeProductFromCart(productid) {
    var data = {
      product_id: productid,
      user_id: JSON.parse(this.userid),
    }
    this.spinner.show();
    this.firewireservices.removeProductFromCart(data)
      .subscribe(
        response => {
          this.spinner.hide();
          var data = response.data;
          if (data.status == 1) {
            // this.toastr.success(data.message);
            // this.getcartProductByUserID();
          } else {
            // this.toastr.success(data.message);
          }
        },
        err => {
          this.spinner.hide();
          // this.toastr.error('Records Deleted Unsuccessfully');
        }
      )
  }

  // Paypal Account

  private initConfig(): void {

    
    this.payPalConfig = {
      currency: 'USD',
      clientId: 'sb',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        // purchase_units: [{
        //   amount: {
        //     currency_code: 'USD',
        //     value: '220.00'
        //   },
        //   payee: {
        //     email_address: 'receiveraccount@emaildomain.com'
        //   }
        // }]
        
       
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: this.payment.reserve_amount,
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: this.payment.reserve_amount
                }
              }
            },
            payee: {
              email_address: this.sellerData.paypal_key
            },
            // payment_instruction: {
            //   platform_fees: [
            //     {
            //       amount: {
            //         currency_code: "USD",
            //         value: this.payment.reserve_amount
            //       },
            //       payee: {
            //         email_address: this.buyerData.paypal_key
            //       }
            //     }
            //   ],
            //   disbursement_mode: "INSTANT"
            //   // "payee_pricing_tier_id": "999ZAE"
            // },
            items: [
              {
                name: 'Enterprise Subscription',
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'USD',
                  value: this.payment.reserve_amount,
                },
              }
            ]
          },
          
        ]
      },
      advanced: {
        commit: 'true'
      },
    
      style: {
        label: 'paypal',
        layout: 'vertical'
      },

      onApprove: (data, actions) => {
        // console.log('onApprove - transaction was approved, but not authorized', data, actions);
        // console.log(data)
        actions.order.get().then(details => {
          // console.log('onApprove - you can get full order details inside onApprove: ', details);
          // var paymenttoken = localStorage.getItem('token');
          // if (paymenttoken != null) {
          if (details.status == 'APPROVED') {
            // document.getElementById("openModalsubUserButton").click();
            // console.log(details)
            var dataget = {
              orderID: data.orderID,
              user_id: this.userid,
              paypal_reference_id: details.id,
              sub_total: this.subtotal,
              shipping: this.val,
              tax: this.Tax,
              total: this.grandtotal,
              reserve_amount: this.payment.reserve_amount,
              created: new Date()
            };
            // c9onsole.log(dataget)
            this.firewireservices.buyPackage(dataget).subscribe(
              (response) => {
                // sessionStorage.setItem('is_expire', JSON.stringify("N"));
                this.AddTransaction();
                // this.toastr.success(response.data.message);
                
              
              },
              (error) => {
                console.log(error);
              }
            );
          } else {

            this.toastr.error('Payment Declined')

          }
          // }else{
          //   var dataurl = '/payment';
          //   this.dataService.updateData(dataurl)
          //   this.router.navigate(['login']);
          // }
        });

        
      },
      onClientAuthorization: (data) => {
        // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        // this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        // console.log('OnCancel', data, actions);
      },
      onError: err => {
        // console.log('OnError', err);
      },
      onClick: (data, actions) => {
        // console.log('onClick', data, actions);
      },
    };
  }

}
