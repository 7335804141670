import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { SocialUser, GoogleLoginProvider, FacebookLoginProvider, AuthService } from 'angularx-social-login';
import { UserService } from 'src/app/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { filter, pairwise } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: any = {};
  model: any = {};
  usersocial: SocialUser;
  isTextFieldType: boolean = false;
  previousUrl: string;
  currentUrl: string;
   icon : any ={} 
   redirectURL : any;
   fcm_token: any;
   userId: any;

  constructor(private userService: UserService,private dataService: DataService,
     private toastr: ToastrService, private authService: AuthenticationService, 
     private _router: Router, private spinner: NgxSpinnerService, private AuthService: AuthService) {
  
 
   }

  ngOnInit(): void {
    this.AuthService.authState.subscribe((usersocial) => {
      this.usersocial = usersocial;
    

    });
    this.dataService.share

    // document.getElementById('registerModal').click()
    // .subscribe(
    //   data => {
    //     this.redirectURL = data;      
    //     console.log(this.redirectURL);
    //   })
  }

  logindd() {
    // console.log(this.user)
    if (this.user.email !== "" && this.user.password !== "") {
      this.spinner.show()
      this.authService.login(this.user.email, this.user.password)
        .subscribe(
          data => {
            // if (data.status == 1) {
             if(data.verified == 1){
              this.userId = data.user.user_id
              this.fcmUpdate();
               localStorage.setItem("loggedInUser", JSON.stringify(data.user))
              this.redirectURL = localStorage.getItem('redirectUrl');
              if( this.redirectURL !== null && this.redirectURL !== undefined)
              {   
                // this.toastr.success('Login Successfully');
                            
                this._router.navigate([this.redirectURL]);
                this.spinner.hide();
              }else{
                // this.toastr.success('Login Successfully');
                this._router.navigate(['mainpage']);
                this.spinner.hide();
              }
              this.spinner.hide();
             } else {
              this.spinner.hide();
             }
            
              
            // } else {
            //   // console.log("error");
            //   this.toastr.error('Email Or Password Incorrect');
            //   this.spinner.hide();
            // }
          },
          err => {
            this.spinner.hide();
            this.toastr.error('Email or password invalid');
          });
    }
  }

  fcmUpdate() {
    var fcm_token =  JSON.parse(localStorage.getItem('fcm_token'));
    var data  = {
      fcm_token : fcm_token,
      user_id : this.userId
    }
    this.spinner.show();
    this.userService.fcmUpdate(data)
      .subscribe(
        response => {
          console.log('res', response)
          if (response.data.status == 1) {

          } else {
          }
          this.spinner.hide();
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )
  }

  togglePasswordFieldType() {
    this.isTextFieldType = !this.isTextFieldType;
  }
}

