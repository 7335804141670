import { Component, OnInit } from '@angular/core';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { DataService } from 'src/app/services/data.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from 'src/app/app.config';

@Component({
  selector: 'app-message-page',
  templateUrl: './message-page.component.html',
  styleUrls: ['./message-page.component.css']
})
export class MessagePageComponent implements OnInit {
  public subtotal = 0;
  public grandtotal = 0;
  payment: any = {};
  public Tax = 0;
  public val = 0;
  public previewUrl = null
  saledata: any;
  msg:any={};
  image
  user =localStorage.getItem('userId')
  constructor(private firewireservices: FireWireServices, private dataService: DataService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private _route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.dataService.share
      .subscribe(
        data => {
          this.saledata = data;
          this.subtotal = this.subtotal + this.saledata.price
          this.Tax = this.subtotal /this.saledata.tax;
          this.grandtotal = this.subtotal + this.Tax;
          this.image = appConfig.hs+this.saledata.image
          // console.log(this.saledata);     

        })
  }

  ShippingModalValue() {
    var total = {
      product_id: this.saledata.product_id,
      product_type: this.saledata.type,
      name: this.saledata.name,
      image: this.saledata.image,
      SubTotal: this.saledata.price,
      Tax: this.subtotal / this.saledata.tax,
      GrandTotal: this.subtotal + this.Tax
    }
    this.dataService.updateData(total);
    this.router.navigate(['checkout-mobile'])

  }
  onSubmit(){

    var dataval = {
      received:this.saledata.user_id,
      sent:this.user,
      subject_name:'',
      message: this.msg.message,
      icon:this.saledata.image,
      product_name:this.saledata.name
    }
    // console.log(dataval)
    // this.router.navigate(['checkout-mobile']) 
    this.spinner.show();
    this.firewireservices.sendMessage(dataval)
      .subscribe(
        response => {
          var data = response.data
          // console.log(data)
          if (data.status == 1) {
            // this.getAllWishList();
            this.toastr.success(data.message); 
            this.router.navigate(['message-list'])
          } else {
            this.toastr.error(data.message);
            this.spinner.hide();
          }
        },
        err => {
          // this.toastr.error('Add To WishList Unsuccessfully');
          this.spinner.hide();

        }
      )
  
    }


}
