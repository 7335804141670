import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal'
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-checkout-mobile',
  templateUrl: './checkout-mobile.component.html',
  styleUrls: ['./checkout-mobile.component.css']
})
export class CheckoutMobileComponent implements OnInit {
  public userid;
  payment: any = {};
  model: any = {};
  public cartlist;
  public subtotal = 0;
  public checkgrandtotal = 0;
  Amout: any;

  public Tax = 0;
  public paymentpage = false;
  public shippingpage = false;
  public val = 0;
  public valsta = 0;
  public userlist;
  public cardvalues = false;
  public payPalConfig?: IPayPalConfig;
  public isActive:Boolean =false;
  public fixrate = null;
  public pic_up = null
  private qtyoptions: string[] = ["5%", "7%", "10%", "12%", "15%", "18%"];
  checkoutData : any;
  reserve_amount : any = 0;


  constructor(private firewireservices: FireWireServices, private dataService: DataService, private router: Router, private formBuilder: FormBuilder, private toastr: ToastrService, private spinner: NgxSpinnerService) {
    this.userid = localStorage.getItem('userId');
    this.getUserdatByData();
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    this.checkoutData =  JSON.parse(localStorage.getItem('checkout-mobile'))
    this.reserve_amount = this.checkoutData.reserve_amount
    this.dataService.share
      .subscribe(
        data => {
          this.Amout = data;
        
          this.checkgrandtotal = this.Amout.GrandTotal;
          // console.log( this.checkgrandtotal);    
        //  if(this.Amout.pic_up != "null" && this.Amout.pic_up != "undefined" &&  this.Amout.pic_up != null){
        //    this.pic_up = this.Amout.pic_up;
        //  }else {
        //   this.checkgrandtotal = this.checkgrandtotal + Number(this.fixrate)
        //  }
        //  if(this.Amout.fixrate != "null"  && this.Amout.fixrate != "undefined"){
        //    this.fixrate = this.Amout.fixrate;
        //  }

        })

  }
 
  paymentMethod() {
    // console.log(this.Amout.name)
  //   if(this.Amout.name != undefined && this.Amout.product_id != undefined && this.Amout.product_type != undefined && this.Amout.SubTotal != undefined && this.checkgrandtotal != undefined )
  //  {
    var odersum = {
      product_id: this.checkoutData.product_id,
      seller_name : this.checkoutData.seller_name,
      product_type: this.checkoutData.product_type,
      name: this.checkoutData.name,      
      image: this.checkoutData.image,
      Subtotal: this.checkoutData.SubTotal, 
      Taxval: this.checkoutData.Tax,
      tax:this.checkoutData.tax,
      taxval:this.checkoutData.taxval,
      fixrate:this.fixrate,
      pic_up:this.pic_up,
      country:this.model.country,
      state:this.model.state,
      city : this.model.city,
      address : this.model.address,
      post_code : this.model.post_code,
      reserve_amount : this.checkoutData.reserve_amount,
      // GrandTotalVal: this.checkgrandtotal
      GrandTotalVal: this.checkoutData.GrandTotal,
      trasaction_id: this.checkoutData.trasaction_id,
      calPrice: this.checkoutData.calPrice,
    }
    // console.log(odersum)
    this.dataService.updateData(odersum);
    localStorage.setItem('paymentData', JSON.stringify(odersum))
    this.updateUser();
    this.router.navigate(['payment'])
  // }
}

   SelectValues(values) {
    this.val = 0;
    var shipDaySelected = 0;
    var total = 0;
    var grandtotal = this.checkgrandtotal;
    if (values == '1') {
      shipDaySelected = 75;
      this.val = shipDaySelected;
    }
    if (values == '2') {
      shipDaySelected = 150;
      this.val = shipDaySelected;
    }
  }


  getUserdatByData() {
    var valdata = {
      user_id: this.userid
    }
    this.spinner.show();
    this.firewireservices.getUsersByID(valdata)
      .subscribe(
        response => {
          if (response.data.status == 1) {
            this.userlist = response.data
            this.editUserdataResponse(this.userlist)
            this.spinner.hide();
          } else {
            console.log("not data found");
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();

        }
      )
  }

  editUserdataResponse(data) {
    this.model.first_name = data.result[0].first_name;
    this.model.phone = data.result[0].phone;
    this.model.address = data.result[0].address;
    this.model.city = data.result[0].city;
    this.model.post_code = data.result[0].post_code;
    this.model.country = data.result[0].country;
    this.model.state = data.result[0].state;
    this.model.user_id = data.result[0].user_id;
    this.model.email = data.result[0].email
    this.model.user_type = data.result[0].user_type
  }

  updateUser() {
 
          var datavalue = {
            user_id: this.userid,
            first_name: this.model.first_name,
            address: this.model.address,
            country: this.model.country,
            state: this.model.state,
            city: this.model.city,
            phone: this.model.phone,
            post_code: this.model.post_code,
            email: this.model.email, 
            user_type: this.model.user_type 
          }
      
          this.firewireservices.UpdateUser(datavalue)
            .subscribe(
              response => {

                if (response.data.status == 1) {
                  this.spinner.hide();               
                  // document.getElementById('openModalForschedule').click();
                } else {
                  this.spinner.hide();                  
                }
              },
              err => {
                console.log(err);
                this.spinner.hide();
              }
            )
        
  }

  SelectValuescard(cardvalue) {
    this.cardvalues = cardvalue;
  }

}
