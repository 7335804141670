import { Component, OnInit } from '@angular/core';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { DataService } from 'src/app/services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-mobile-board',
  templateUrl: './search-mobile-board.component.html',
  styleUrls: ['./search-mobile-board.component.css']
})
export class SearchMobileBoardComponent implements OnInit {
  model : any ={};
  modelChanged: Subject<string> = new Subject<string>();

  constructor(private firewireservices : FireWireServices,private dataService : DataService,
    private _route: ActivatedRoute, private router: Router) {
      
      // this.modelChanged.pipe(
      //   debounceTime(500))
      //   .subscribe(model => {
      //     // console.log(model);
      //     this.searchBoard();
      //   });
  }

  ngOnInit(): void {
  }

//   changed(text: string) {
//     this.modelChanged.next(text);
    
// }
  searchBoard()
  {    
    var data = {
      name : this.model.product
    }    
    this.dataService.updateData(data)
    localStorage.setItem('searchterm', JSON.stringify(data))
    this.router.navigate(['search-result-mobile']);
  }
}
