import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Router, ActivatedRoute } from '@angular/router';
import { IfStmt } from '@angular/compiler';
import { DataService } from 'src/app/services/data.service';
import { appConfig } from 'src/app/app.config';

@Component({
  selector: 'app-follower',
  templateUrl: './follower.component.html',
  styleUrls: ['./follower.component.css']
})
export class FollowerComponent implements OnInit {

  public users: any = {};
  public userid;
  public FollowerList: any = [];
  FollowingList: any = [];
  model: any = {};
  useraddress: any;
  usercountry: any;
  userstate: any;
  userscity: any;
  userspostcode: any;
  userphone: any;
  previewUrluser: any = null;
  follow = []
  public searchuserlist: any = [];
  previewURL: any;
  varyfietoken: any;
  usernameid: any = {};
  followFlag: Boolean = true;
  unfollowFlag: Boolean = false;
  followId: any;
  public isFollow: boolean = false;
  loggedInUser: any;
  user_id: any;
  public userlist: any = []
  imagepath: any;
  followerImage: any;

  constructor(private firewireservices: FireWireServices, private dataService: DataService,
     private formBuilder: FormBuilder, private _route: ActivatedRoute,
     private router: Router, private toastr: ToastrService, private spinner: NgxSpinnerService) {
    this.loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))
    this.userid = localStorage.getItem('userId');
    this.varyfietoken = localStorage.getItem('token');
    this.usernameid = JSON.parse(localStorage.getItem('sellerData'))
  

    this._route.queryParams.subscribe(params => {
      console.log(params)
      if (params['user_id']) {
        console.log('if')
        this.user_id= params['user_id'];
        console.log(this.user_id)
        this.updateUserProfile();
        this.getUserProfile();
        this.getFollowingByUser();
        this.userById();
      } else {
        console.log('else')
      }
    })

  }

  ngOnInit(): void {
  }

  onClick(){
  }

  getAllFollower() {
    var data = {
      follow_id: this.userid,
    }
    this.firewireservices.getAllFollower(data)
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {
            this.FollowerList = data
            var cnt = 0;
            var list = [];

            this.FollowerList.forEach(follo => {
              if (follo.follow_id == this.userid) {
                cnt = cnt + 1;
              }
            });
            var str = {
              totalfollowers: cnt
            }
            list.push(str)
            this.FollowerList = list
          } else {
            var list = [];
            var str = {
              totalfollowers: 0
            }
            list.push(str)
            this.FollowerList = list
            // console.log("FollowerList",this.FollowerList)

          }
        }, err => {
          console.log("error");
        }
      )
  }

  goToUserdata(item) {
    console.log(item)
    this.router.navigate(['/user-product'], { queryParams: { user_id: item.user_id } });
  }
  
  getAllFollowersbyUser() {
    // var dataval = {
    //   user_id: this.loggedInUser.user_id
    // }
    this.spinner.show();
    this.firewireservices.getAllFollowersbyUser(Number(this.user_id))
      .subscribe(
        res => {
          if (res.data.verified == 1) {
            this.FollowerList = res.data.info
            console.log(this.FollowerList)
            this.spinner.hide();
            this.FollowerList.forEach(element => {
              this.followerImage = appConfig.hs + element.image
              console.log(this.followerImage)
              // if (element.image) {
              //   element.image = appConfig.hs + element.image
              // } else {
              //   element.image = "assets/images/Picture2.png"
              // }

            });
            // let op = this.FollowerList.map((e,i)=>{
            //   let temp = this.FollowingList.find(element=> element.user_id === e.user_id)
            //   console.log(temp)
            //   if(temp) {
            //     e.isFollow = true;
            //   }
            //   return e;
            // });
            // console.log(op);
              this.getFollowersByLoggedInUser();
            // for(let i=0; i<this.FollowerList.length; i++){
            //   let fin = this.FollowingList.filter(x => Number(x.user_id) === this.FollowerList[i].user_id) 
            //   debugger
            // console.log(fin)
            //   for(let j=0; j<this.FollowingList.length; j++){
            //     if(Number(this.FollowerList[i].user_id) === Number(this.FollowingList[j].user_id)){
            //       this.FollowerList[i].isFollow = true;
            //       console.log(this.FollowerList[i].isFollow)
            //     }else{
            //       this.FollowerList[i].isFollow = false;
            //     }
            //   }
            // }  
              // this.FollowingList.forEach(element => {
              //   this.FollowerList.forEach(follower => {
              //     if(element.user_id === follower.user_id){
              //         follower.isFollow= true;
              //     }else{
              //       follower.isFollow = false;
              //     }
              //   });
              // });
            // }
            

            // for(let i=0; i<this.FollowerList.length; i++){
            //   let fnd = this.FollowingList.find(x=>Number(x.user_id) === this.FollowerList[i].user_id)
            //   console.log('fnd', fnd)
            // }

           
            // console.log('this.FollowerList', this.FollowerList)
            // console.log('this.FollowingList', this.FollowingList)

          } else {
            this.getFollowersByLoggedInUser();

            // this.FollowerList = []
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  getFollowersByLoggedInUser() {
    this.spinner.show();
    this.firewireservices.getAllFollowersbyUser(Number(this.loggedInUser.user_id))
      .subscribe(
        res => {
          this.spinner.hide();
          if (res.data.verified == 1) {
            let loginUserFollowerList = res.data.info;
            // debugger
            if(this.loggedInUser.user_id === this.user_id){
              let op = loginUserFollowerList.map((e,i)=>{
                console.log(this.FollowingList)
                let temp = this.FollowingList.find(element=> element.user_id === e.user_id)
                if(temp) {
                  e.isFollow = true;
                }
                return e;
              });
              this.FollowerList = op;
              // this.FollowerList.forEach(element => {
              //   element.images = appConfig.hs + element.images
              // });

            }else{
              let op = this.FollowerList.map((e,i)=>{
                let temp = this.FollowingList.find(element=> element.user_id === e.user_id)
                if(temp) {
                  e.isFollow = true;
                }
                return e;
              });
            }
           
            // console.log(op);

            // let op1 = op.map((e,i)=>{
            //   let temp = FollowerList.find(element=> element.user_id === e.user_id)
            //   console.log('loggedinuser',temp)
            //   if(temp) {
            //     e.isFollow = true;
            //   }
            //   return e;
            // });

            // console.log(op1)
      
            // this.FollowerList.forEach(element => {
            //   if (element.image) {
            //     element.image = appConfig.hs + element.image
            //   } else {
            //     element.image = "assets/images/Picture2.png"
            //   }

            // });
            // console.log('this.FollowerList', this.FollowerList)
            // console.log('this.FollowingList', this.FollowingList)

          } else {
            // this.FollowerList = []

          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  getFollowingByUser() {
    this.spinner.show();
    this.firewireservices.getFollowingByUser(Number(this.user_id))
      .subscribe(
        res => {
          // debugger
          if (res.data.verified == 1) {
            // debugger
            this.getAllFollowersbyUser();
            this.FollowingList = res.data.info
            // this.FollowingList.forEach(element => {
            //   element.image = appConfig.hs + element.image
            // });
            this.spinner.hide();
          } else {
            this.getAllFollowersbyUser();
            this.FollowingList = []
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  updateUserProfile() {
    var data = {
      user_id: JSON.parse(this.userid)
    }

    this.firewireservices.getUsersByID(data)
      .subscribe(
        response => {
          var data = response.data.result;
          if (data.length != 0) {
            this.users = response.data.result[0]
            if (this.users.address != "") {
              this.useraddress = this.users.address + ","
            }
            if (this.users.city != "") {
              this.userscity = this.users.city + ","
            }
            if (this.users.state != "") {
              this.userstate = this.users.state + ","
            }
            if (this.users.post_code != "") {
              this.userspostcode = this.users.post_code + ","
            }

            if (this.users.country != "") {
              this.usercountry = this.users.country
            }
            if (this.users.image != '' && this.users.image != null) {
              // this.previewUrl = location.origin + '/' + this.users.image
              this.previewUrluser = appConfig.hs + this.users.image
            } else {

              this.previewUrluser = "assets/images/Picture2.png"
            }

          }

        }
      )
  }

  getUserProfile() {

    // console.log(this.userid);
    var data = {
      user_id: JSON.parse(this.userid)
    }

    this.firewireservices.getUsersByID(data)
      .subscribe(
        response => {
          var data = response.data;
          // console.log("users", data);
          this.editcliendataResponse(data)
        }
      )
  }

  editcliendataResponse(data) {
    // console.log("slugurl",data);
    this.model.first_name = data.result[0].first_name;
    this.model.address = data.result[0].address;
    this.model.city = data.result[0].city;
    this.model.state = data.result[0].state;
    this.model.country = data.result[0].country;
    this.model.phone = data.result[0].phone;
    this.model.post_code = data.result[0].post_code;
    this.model.email = data.result[0].email;



    if (data.result[0].image != '' && data.result[0].image != null && data.result[0].image != undefined) {
      // this.previewUrl = location.origin + '/' + this.users.image
      this.previewUrluser = appConfig.hs + data.result[0].image;
    } else {

      this.previewUrluser = "assets/images/Picture2.png"
    }
  }

  followdata(item) {
    console.log(item)
    this.followId = item.user_id
    this.userFollow();
  }

  goToProfile() {
    if(this.user_id == this.loggedInUser.user_id) {
      this.router.navigate(['/my-quiver-account']);
    }
  }

  userFollow() {
    if (this.varyfietoken != null) {
      var data = {
        user_id: this.loggedInUser.user_id,
        follow_id: this.followId,
        icon: this.previewUrluser
      }
      this.spinner.show();
      this.firewireservices.UserFollow(data)
        .subscribe(
          response => {
            var data = response.data
            if (data.status == 1) {

              // this.isFollow = !this.isFollow;
              this.toastr.success(data.message);
              this.getFollowingByUser()
              this.spinner.hide();
            } else {
             this.getFollowingByUser()
              this.spinner.hide();
            }
          },
          err => {
            console.log("error");
            this.spinner.hide();
          }
        )
    } else {
      document.getElementById('registerModal').click();
    }
  }

  userById() {
    var valdata = {
      user_id: this.user_id
    }
    console.log(valdata)
    this.spinner.show();
    this.firewireservices.getUsersByID(valdata)
      .subscribe(
        response => {
          this.userlist = response.data.result[0]
          this.imagepath = appConfig.hs + this.userlist.image 
          console.log(this.userlist)
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
        }
      )
  }
  
}
