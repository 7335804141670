import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { Router, ActivatedRoute, RoutesRecognized, NavigationStart } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { DatePipe } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import 'rxjs/add/operator/pairwise';
import { date } from 'ngx-custom-validators/src/app/date/validator';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  public userid;
  payment: any = {};
  public cardvalues;
  Radionbuttoncheck: null;
  public payPalConfig?: IPayPalConfig;
  public subtotal: any = 0;
  public grandtotal: any = 0;
  public Tax: any = 0;
  public val: any = 0;
  public userlist;
  public curentdate;
  payAmt: any;
  payData: any = {}
  public fixrate = null;
  public pic_up = null
  public isTax: boolean = false;
  previousUrl: any;
  currentUrl: string;
  // private monthList: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  // selectmonth;
  public monthList = [
    { value: "1", label: 'Jan' },
    { value: "2", label: 'Feb' },
    { value: "3", label: 'Mar' },
    { value: "4", label: 'Apr' },
    { value: "5", label: 'May' },
    { value: "6", label: 'June' },
    { value: "7", label: 'July' },
    { value: "8", label: 'Aug' },
    { value: "9", label: 'Sep' },
    { value: "10", label: 'Oct' },
    { value: "11", label: 'Nov' },
    { value: "12", label: 'Dec' }
  ];
  paymentdata;
  paymentData: any;
  sellerData: any;

  constructor(private datePipe: DatePipe, private firewireservices: FireWireServices, private dataService: DataService, private formBuilder: FormBuilder, private toastr: ToastrService, private _route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService) {
    this.userid = localStorage.getItem('userId');
    // this.paymentdata = localStorage.getItem('paymentDetails');
    JSON.parse(localStorage.getItem('Product_reserve'))
    this.paymentData = JSON.parse(localStorage.getItem('paymentData'))
    this.sellerData = JSON.parse(localStorage.getItem('sellerData'))

    this.getUserdatByData();
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects
        this.currentUrl = events[1].urlAfterRedirects
        // console.log("previousUrl", this.previousUrl);
        // console.log("currentUrl", this.currentUrl);


      });

  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  taxVal: any = {}

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.dataService.share
      .subscribe(
        data => {
          this.payAmt = data;
          console.log(data);
          if (!this.isEmpty(data)) {
            // this.grandtotal = Number(this.paymentData.calPrice);
            this.grandtotal = Number(this.paymentData.reserve_amount);
              this.payData = this.grandtotal
            console.log(1)
          } else {
            console.log(2)
            console.log(this.payAmt)
            this.subtotal = this.payAmt.Subtotal,
              this.grandtotal = Number(this.payAmt.GrandTotalVal);
            this.payData = this.grandtotal
            // console.log(this.payAmt.pic_up)
            // if (this.payAmt.pic_up != null && this.payAmt.pic_up != undefined && this.payAmt.pic_up != 'undefined') {
            //   this.pic_up = this.payAmt.pic_up;
            // }
            // if (this.payAmt.fixrate != null && this.payAmt.fixrate != undefined && this.payAmt.fixrate != 'undefined') {
            //   this.fixrate = this.payAmt.fixrate;
            // }
            // console.log(this.payAmt.tax)
            // if (this.payAmt.tax != null && this.payAmt.tax != 0 && this.payAmt.tax != 'undefined') {
            //   this.Tax = this.payAmt.tax
            //   this.taxVal = this.payAmt.taxval
            //   this.isTax = true;
            // } else {
            //   this.isTax = false;
            // }
            // console.log(2)
          }
        })


    this.initConfig();

    // var date = new Date();
    // this.curentdate = this.datePipe.transform(date, "yyyy-MM-dd")
    // this.payment.expire_date = this.curentdate;

    // console.log(this.payment.year)

  }


  SelectValuescard(cardvalue, no) {
    this.cardvalues = cardvalue;
    this.Radionbuttoncheck = no;
    // console.log(this.cardvalues)
  }
  // checkDate(){
  //   var currentDate = new Date();
  //   var dateOne = new Date(this.payment.year, this.payment.expire_date, 0)
  //   if(currentDate > dateOne){
  //     alert('Expired Year')
  //   } 
  // }


  addPayment() {
    var paymenttoken = localStorage.getItem('token');
    if (this.Radionbuttoncheck == '1') {


      // if (paymenttoken != null ) { 


      if (this.payment.name_on_card != undefined && this.payment.card_number != undefined && this.payment.expire_date != undefined && this.payment.year != undefined && this.userid != "") {

        var currentDate = new Date();
        var dateOne = new Date(this.payment.year, this.payment.expire_date, 0)
        if (currentDate > dateOne) {
          this.toastr.error('Enter valid expire year or month')
        } else {
          // alert('valid')


          var data = {
            name_on_card: this.payment.name_on_card,
            user_id: JSON.parse(this.userid),
            card_number: this.payment.card_number,
            expire_date: this.payment.expire_date,
            year: this.payment.year,
            security_code: this.payment.security_code,
            sub_total: this.subtotal,
            shipping: this.val,
            tax: this.Tax,
            total: this.grandtotal,
            payment_type: "Completed",

          }

          // console.log(data)
          this.spinner.show();
          this.firewireservices.addPayment(data)
            .subscribe(
              response => {
                var data = response.data
                if (data.status == 1) {
                  // this.toastr.success(data.message);                  
                  this.AddTransaction();
                } else {
                  console.log("error");
                  this.toastr.error('Payment Cancel');

                  this.spinner.hide();
                }
              }, err => {
                this.toastr.error('Payment Cancel');
                this.spinner.hide();
              });

        }
      }
    }
    else {
      this.toastr.error('Please Selected Payment Method');
    }


  }



  getUserdatByData() {
    var valdata = {
      user_id: this.userid
    }
    this.spinner.show();
    this.firewireservices.getUsersByID(valdata)
      .subscribe(
        response => {
          this.userlist = response.data.result[0]
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
        }
      )
  }

  AddTransaction() {
    var comm = (this.grandtotal * 3 / 100);
    var datavalue = {
      user_id: this.userid,
      product_id: this.payAmt.product_id,
      transaction_type: "Purchase",
      product_type: this.payAmt.product_type,
      product_name: this.payAmt.name,
      email: this.userlist.email,
      first_name: this.userlist.first_name,
      seller_name: this.payAmt.seller_name,
      contact: this.userlist.phone,
      commision: comm,
      amount: this.grandtotal,
      image: this.payAmt.image,
      city: this.payAmt.city,
      address: this.payAmt.address,
      post_code: this.payAmt.post_code,
      country: this.payAmt.country,
      state: this.payAmt.state,
      paystatus: "Completed",
    }
    // console.log(datavalue)
    this.firewireservices.AddTransaction(datavalue)
      .subscribe(
        response => {
          var data = response.data
          if (data.status == 1) {
            // console.log(data.message)
            this.removeProductFromCart(this.payAmt.product_id)
            this.paymentStatus()
            var dataid = response.data.result
            localStorage.removeItem('Product_Details');
            // this.dataService.updateData(dataid);
            var datainfo = {
              value: dataid
            }
            this.dataService.updateData(datainfo)
            this.router.navigate(['thankyou']);
            this.toastr.success('Payment Successfully');
            this.spinner.hide();
          } else {
            console.log("error");
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }


  updateTransaction() {
    // let res_amt = 0
    // if (this.paymentData.fixrate != null || this.paymentData.fixrate != undefined) {
    //   res_amt = Number(this.paymentData.calPrice) + Number(this.paymentData.fixrate)

    // } else {
    //   res_amt = this.paymentData.calPrice
    // }
    var datavalue = {
      transaction_id: this.paymentData.trasaction_id,
      reserve_amount: this.paymentData.calPrice,
      reserve_status: 2 // full payment

    }
    // console.log(datavalue)
    this.firewireservices.updateTransaction(datavalue)
      .subscribe(
        response => {
          var data = response.data
          if (data.status == 1) {
            // console.log(data.message)
            this.removeProductFromCart(this.payAmt.product_id)
            this.paymentStatus()
            var dataid = response.data.result
            localStorage.removeItem('Product_Details');
            // this.dataService.updateData(dataid);
            var datainfo = {
              value: dataid
            }
            this.dataService.updateData(datainfo)
            this.router.navigate(['thankyou']);
            this.toastr.success('Payment Successfully');
            this.spinner.hide();
          } else {
            console.log("error");
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  // Transactiondata(dataval){

  //   var data = {
  //     value : dataval          
  //   }
  //   this.dataService.updateData(data)
  //   this.router.navigate(['sale-page'])

  // }
  removeProductFromCart(productid) {
    var data = {
      product_id: productid,
      user_id: JSON.parse(this.userid),
    }
    this.spinner.show();
    this.firewireservices.removeProductFromCart(data)
      .subscribe(
        response => {
          this.spinner.hide();
          var data = response.data;
          if (data.status == 1) {
            // this.toastr.success(data.message);
            // this.getcartProductByUserID();
          } else {
            // this.toastr.success(data.message);
          }
        },
        err => {
          this.spinner.hide();
          // this.toastr.error('Records Deleted Unsuccessfully');
        }
      )
  }

  // Paypal Account

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'USD',
      clientId: 'sb',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'USD',
              value: this.payData,
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: this.payData
                }
              }
            },
            payee: {
              email_address: this.sellerData.paypal_key
            },
            items: [
              {
                name: this.paymentData.name,
                quantity: '1',
                category: "DIGITAL_GOODS",
                unit_amount: {
                  currency_code: 'USD',
                  value: this.payData,
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },

      onApprove: (data, actions) => {
        // console.log('onApprove - transaction was approved, but not authorized', data, actions);
        // console.log(data)
        actions.order.get().then(details => {
          // console.log('onApprove - you can get full order details inside onApprove: ', details);
          // var paymenttoken = localStorage.getItem('token');
          // if (paymenttoken != null) {
          if (details.status == 'APPROVED') {
            // document.getElementById("openModalsubUserButton").click();
            // console.log(details)
            var dataget = {
              orderID: data.orderID,
              user_id: this.userid,
              paypal_reference_id: details.id,
              sub_total: this.subtotal,
              shipping: this.val,
              tax: this.Tax,
              total: this.grandtotal,
              created: new Date()
            };
            // c9onsole.log(dataget)
            this.firewireservices.buyPackage(dataget).subscribe(
              (response) => {
                // sessionStorage.setItem('is_expire', JSON.stringify("N"));
                // this.AddTransaction();
                this.updateTransaction();
                // this.toastr.success(response.data.message);
                // this.router.navigate(['thankyou'])
              },
              (error) => {
                console.log(error);
              }
            );
          } else {

            this.toastr.error('Payment Declined')

          }
          // }else{
          //   var dataurl = '/payment';
          //   this.dataService.updateData(dataurl)
          //   this.router.navigate(['login']);
          // }
        });
      },
      onClientAuthorization: (data) => {
        // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        // this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        // console.log('OnCancel', data, actions);
      },
      onError: err => {
        // console.log('OnError', err);
      },
      onClick: (data, actions) => {
        // console.log('onClick', data, actions);
      },
    };
  }

  paymentStatus() {
    let sendData = {
      product_id: this.payAmt.product_id,
      payment_status: '1',
      reserve_user_id: this.userid
    }
    this.firewireservices.paymentstatus(sendData)
      .subscribe(
        response => {
          if (response.data.status == 1) {
            // this.toastr.success(response.data.message);
            // this.spinner.hide();          
          } else {
            // this.spinner.hide();
            // this.toastr.error(response.data.message);
          }
        },
        err => {
          console.log("error", err);
          // this.spinner.hide();
        }
      )
  }
}
