import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { AuthenticationService } from 'src/app/services';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-menu-drop-down',
  templateUrl: './menu-drop-down.component.html',
  styleUrls: ['./menu-drop-down.component.css']
})


export class MenuDropDownComponent implements OnInit {
  user = localStorage.getItem('userId');

  returnUrl: string; 
  social : any;

  userid = localStorage.getItem("userid");
  facebookid= JSON.parse(localStorage.getItem("Facebook_ID"));
   
  googleid= JSON.parse(localStorage.getItem("Google_ID"));
  paymenttoken;
  public messageList = [];
  public isRead :any = [];
  constructor(private spinner: NgxSpinnerService, private userService: UserService,private toastr: ToastrService,private firewireservices:FireWireServices,private route:ActivatedRoute, private authService: AuthService, private _router: Router, private AuthService: AuthService, private router: Router,private dataService: DataService) {  
     this.paymenttoken = localStorage.getItem('token');
  }

  ngOnInit(): void {

    this.getAllUserMessages();
   
  }
  getAllUserMessages(){
    
    var userID = {
      user_id:this.user
    }
    // console.log(userID)
    this.spinner.show();
    this.firewireservices.getMessageByUser(userID)
      .subscribe(
        response => {
          var data = response
          var list = []
        var messageData =data.data.result;
          // console.log(this.messageList)
          // console.log( messageData)

      this.firewireservices.getAllUsers()
      .subscribe(
        response => {
          var data = response
          var userData =data.data.result;
          // console.log(this.messageList)
         
          var subjectname;
          userData.forEach(user => {
            var cnt = 0;
            var tempid;
            var userid1;
            var userid2;
            var userstatus1; 
            var userstatus2;
            var icon;
            var product_name;
              messageData.forEach(msg => {
                if(user.user_id == msg.sent || user.user_id ==msg.received){
                
                  
                  if(user.user_id != this.user){  
                    if(msg.received == this.user && msg.is_read =="0" ){
                      cnt = cnt + 1;
                      
                     }
                   tempid = user.user_id
                  } 
                  subjectname = msg.subject_name
                  // console.log(subjectname)
                  userid1 = msg.userid_1;
                  userid2 = msg.userid_2;
                  userstatus1 = msg.user1status;
                  userstatus2 = msg.user2status;
                  icon = msg.icon;
                  product_name = msg.product_name;
                }
              }); 
              var str ={user_id:user.user_id,
                first_name:user.first_name,
                image: 'assets/images/Picture2.png',
                icon:icon,
                product_name:product_name,
                subject_name:subjectname,
                msgUnread:cnt,
                userid1:userid1,
                userid2:userid2,
                userstatus1:userstatus1,
                userstatus2:userstatus2 
              }
              
              
              if(user.user_id == tempid){
                list.push(str)  
              }
             
               
          });
          // console.log(list)
         
          let res = [];
          list.map(function (item) {
            var existItem = res.find(x => x.first_name == item.first_name);
            if (existItem) {
  
            }
  
            else {
              
              res.push(item);
              
              
              // console.log(res)
            }
  
          });
          res.forEach(element => {
            if(element.msgUnread == '0'){
              this.isRead  =element.msgUnread
            }else{
              this.isRead = '1';
            }
          });
          // console.log(this.isRead)
          this.messageList = res;


        var listmsg=[]
         
        
          // this.messageList = list;
          // console.log(this.messageList) 

          this.spinner.hide();
        },
        err => {
          // this.toastr.error('Add To WishList Unsuccessfully');
         

        }
      )
 
        },
        err => {
          // this.toastr.error('Add To WishList Unsuccessfully');
         

        }
      )
  }
  
  SalepageDetailsModal(){
    if(this.paymenttoken !=null){
      this.router.navigate(['/saledetailpage']);
      this.UpdateDetails();
    }else{
      document.getElementById('registerModal').click()
          //  this.router.navigate(['/login']);      
    }      
  }
  MessageModal(){
    if(this.paymenttoken !=null){
      this.router.navigate(['/message-list']);
    }else{
      document.getElementById('registerModal').click()
          //  this.router.navigate(['/login']);      
    }      
  }
  ProfileModal(){
    if(this.paymenttoken !=null){
      this.router.navigate(['/my-quiver-account']);
    }else{
      document.getElementById('registerModal').click()
          //  this.router.navigate(['/login']);      
    }      
  }
  TransactionModal(){
    if(this.paymenttoken !=null){
      this.router.navigate(['/transaction']);
    }else{
      document.getElementById('registerModal').click()
          //  this.router.navigate(['/login']);      
    }      
  }
//   LogtoutModal(){
//     if(this.googleid !="" && this.googleid !=null){
//       // alert("google")
//     this.authService.signOut();  
//     localStorage.removeItem('token');
//     localStorage.removeItem('name');
//     localStorage.removeItem('userId');
//     localStorage.removeItem('userType');
//     localStorage.removeItem('redirectUrl');    
    
//   }else if(this.facebookid !="" && this.facebookid !=null){
//     // alert("facebook")
//     this.authService.signOut();  
//     localStorage.removeItem('token');
//     localStorage.removeItem('name');
//     localStorage.removeItem('userId');
//     localStorage.removeItem('userType');
//     localStorage.removeItem('redirectUrl');
//       // localStorage.clear();
//       // this._router.navigate(['/login']);
//   }else{
//     // alert("simple")
//     localStorage.removeItem('token');
//     localStorage.removeItem('name');
//     localStorage.removeItem('userId');
//     localStorage.removeItem('userType');
//     localStorage.removeItem('redirectUrl');
    
//       // this._router.navigate(['/login']);
//     // console.log(this.userid)
//  }
//     if(this.paymenttoken !=null){      
//       document.getElementById('registerModal').click();
      
//     }      
//   }
  searchModal(){
    if(this.paymenttoken !=null){
      this.router.navigate(['/search-mobile']);
    }else{
      document.getElementById('registerModal').click()
          //  this.router.navigate(['/login']);      
    } 
  }
  UpdateDetails() {
    var odersum = {
      data : undefined,
    }

    this.dataService.updateData(odersum);
    this.router.navigate(['saledetailpage'])
  }

      // <-----------Facebook------------>

      signInWithFB(): void {
        // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => console.log(x));
        let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
        this.AuthService
          .signIn(FacebookLoginProvider.PROVIDER_ID)
          .then((userData) => {
            //this will return user data from facebook. What you need is a user token which you will send it to the server
            // console.log("user facebook",userData.photoUrl)
            var path = userData.photoUrl;
            // sessionStorage.setItem("socialimg",JSON.stringify(path));
            this.SavesresponseFacebook(userData);
            // this._router.navigate(['dashboard']);
            // console.log(userData)
          });
      }
      SavesresponseFacebook(userData) {
        var data = {
          first_name: userData.firstName,
          last_name: userData.lastName,
          email: userData.email,
          facebook_id: userData.id,
          image: userData.photoUrl,
    
        }
        this.spinner.show()
        this.userService.socialRegister(data)
          .subscribe(
            data => {
              var custdetails = data;
              this.socialLoginFacebook(userData.id)
            },
            error => {
              console.log(error);
            });
        // console.log(data)
      }
    
      socialLoginFacebook(userid) {
        var userSet = {
          facebook_id: userid,
          provider: 'facebook'
        };
    
        this.userService.social_login(userSet).subscribe((res: any) => {
          this.spinner.hide()
          // debugger;
          this._router.navigate(['my-quiver-account']);
        });
      }
    
      // <-----------Facebook  End------------>
    
    
    
    
      // <-----------Google------------>
    
    
      signInWithGoogle(): void {
        // alert()
        // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => console.log(x));
    
        let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
        this.AuthService
          .signIn(GoogleLoginProvider.PROVIDER_ID)
          .then((userData) => {
            // var path = userData.photoUrl;
            // sessionStorage.setItem("socialimg",JSON.stringify(path));
            //this will return user data from facebook. What you need is a user token which you will send it to the server
            this.SavesresponseGoogle(userData);
            // this._router.navigate(['dashboard']);
            // console.log(userData)
          });
      }
      SavesresponseGoogle(userData) {
        var data = {
          first_name: userData.firstName,
          last_name: userData.lastName,
          email: userData.email,
          google_id: userData.id,
          image: userData.photoUrl,
        }
    
        this.spinner.show()
    
        this.userService.socialRegister(data)
          .subscribe(
            data => {
              var custdetails = data;
              this.socialLoginGoogle(userData.id)
            },
            error => {
              console.log(error);
            });
      }
      socialLoginGoogle(userid) {
        var userSet = {
          google_id: userid,
          provider: 'google'
        };
        this.userService.social_login(userSet).subscribe((res: any) => {
          // debugger;
          this.spinner.hide()
          this._router.navigate(['my-quiver-account']);
        });
      }
    
      // <-----------Google End------------>
  
}


 