import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  form:any ={};
  constructor(private fireWireService:FireWireServices,private formBuilder: FormBuilder,private toastr:ToastrService,private router:Router) { }

  ngOnInit(): void {
  }

  forgot(){

    var val = {
      email:this.form.email
    }

    this.fireWireService.forgotPassword(val)
          .subscribe(
            response => {
              if(response.data.status == 1)
              {
                this.toastr.success(response.data.message);
                // this.getcartProductByUserID(this.saledata.product_id);
                this.router.navigate(['login']) 
              }else{
                console.log("error");
              }
            },
            err => {
              this.toastr.error('This user does not exist');
               

            }
          )

  }

}
