import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { HeaderComponent } from './views/header/header.component';
import { FooterComponent } from './views/footer/footer.component';
import { AuthGuard } from './guards';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { LogoutComponent } from './views/logout/logout.component';
import { ScanComponent } from './views/scan/scan.component';
import { AboutBoardComponent } from './views/about-board/about-board.component';
import { MyQuiverAccountComponent } from './views/my-quiver-account/my-quiver-account.component';
import { MenuDropDownComponent } from './views/menu-drop-down/menu-drop-down.component';
import { CartPageComponent } from './views/cart-page/cart-page.component';

import { ThankyouComponent } from './views/thankyou/thankyou.component';
import { MainpageComponent } from './views/mainpage/mainpage.component';
import { SaledetailpageComponent } from './views/saledetailpage/saledetailpage.component';
import { SearchMobileComponent } from './views/search-mobile/search-mobile.component';
import { PaymentComponent } from './views/payment/payment.component';
import { SearchResultMobileComponent } from './views/search-result-mobile/search-result-mobile.component';
import { MessagePageComponent } from './views/message-page/message-page.component';
import { SalePageComponent } from './views/sale-page/sale-page.component';
import { SearchMobileBoardComponent } from './views/search-mobile-board/search-mobile-board.component';
import { LocationComponent } from './views/location/location.component';
import { MessageListComponent } from './views/message-list/message-list.component';
import { MessageChatComponent } from './views/message-chat/message-chat.component';
import { ForgotComponent } from './views/forgot/forgot.component';
import { TransactionComponent } from './views/transaction/transaction.component';
import { CheckoutMobileComponent } from './views/checkout-mobile/checkout-mobile.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { SearchResultUserComponent } from './views/search-result-user/search-result-user.component';
import { UserProductComponent } from './views/user-product/user-product.component';
import { FollowerComponent } from './views/follower/follower.component';
import { FollowingComponent } from './views/following/following.component';
import { ReviewComponent } from './views/review/review.component';
import { TermsComponent } from './views/terms/terms.component';
import { PaypalPageComponent } from './views/paypal-page/paypal-page.component';
import { HowToComponent } from './views/how-to/how-to.component';
import { ReservePaymentPageComponent } from './views/reserve-payment-page/reserve-payment-page.component';
import { PurchaseComponent } from './views/purchase/purchase.component';

const routes: Routes = [
  {path : '',redirectTo:'/mainpage',pathMatch:'full'},
  {path : 'header',component:HeaderComponent,canActivate :[AuthGuard]},
  {path : 'footer',component:FooterComponent},
  {path : 'login',component:LoginComponent },
  {path : "scan/:slug_url",component:ScanComponent},
  {path : "about-board",component:AboutBoardComponent},
  {path : "my-quiver-account",component:MyQuiverAccountComponent,canActivate :[AuthGuard]},
  {path : 'register',component:RegisterComponent},
  {path : "menu-drop-down",component:MenuDropDownComponent},
  {path : "cart-page",component:CartPageComponent},
  {path : "checkout-mobile",component:CheckoutMobileComponent},
  {path : "thankyou",component:ThankyouComponent,canActivate :[AuthGuard]},
  {path : "mainpage",component:MainpageComponent},
  {path : "search-mobile",component :SearchMobileComponent},
  {path : "search-mobile-board",component :SearchMobileBoardComponent},
  {path : "saledetailpage",component:SaledetailpageComponent,canActivate : [AuthGuard]},
  {path : "payment",component:PaymentComponent,canActivate :[AuthGuard]},
  {path : "search-result-mobile",component:SearchResultMobileComponent,canActivate :[AuthGuard]},
  {path : "sale-page",component:SalePageComponent },
  {path : "message-page",component:MessagePageComponent,canActivate : [AuthGuard]},
  {path : "message-list",component : MessageListComponent,canActivate : [AuthGuard]},
  {path : "message-chat",component : MessageChatComponent,canActivate : [AuthGuard]},
  {path : "forgot",component : ForgotComponent},
  {path : "forgot:/id",component : ForgotComponent},
  {path : "transaction",component : TransactionComponent,canActivate :[AuthGuard]},
  {path : "location",component : LocationComponent,canActivate : [AuthGuard]},
  {path : "logout",component:LogoutComponent,canActivate : [AuthGuard]},
  {path : "change-password",component:ChangePasswordComponent,canActivate :[AuthGuard]},
  {path : "search-result-user",component:SearchResultUserComponent,canActivate :[AuthGuard]},
  {path : "user-product",component : UserProductComponent},
  {path : "follower",component : FollowerComponent,canActivate : [AuthGuard]},
  {path : "following",component : FollowingComponent, canActivate : [AuthGuard]},
  {path : "review",component : ReviewComponent,canActivate : [AuthGuard]},
  {path : "paypal-page",component : PaypalPageComponent,canActivate : [AuthGuard]},
  {path : "terms",component : TermsComponent},
  {path : "how-to",component : HowToComponent,canActivate :[AuthGuard]},
  {path : "reserve-payment-page",component : ReservePaymentPageComponent,canActivate :[AuthGuard]},
  {path : "purchase",component : PurchaseComponent,canActivate :[AuthGuard]}


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash : true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
