import { Component, OnInit } from '@angular/core';
import { FacebookLoginProvider, AuthService, SocialUser, GoogleLoginProvider } from 'angularx-social-login';
import { AuthenticationService } from 'src/app/services';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { DataService } from 'src/app/services/data.service';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from 'src/app/app.config';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.css']
})
export class ScanComponent implements OnInit {

  user: any = {};
  usersocial: SocialUser;
  saledata : any = {};
  cartlist : any = {};
  userid;
  public coloroptions: string[] = [];
  public selectedColor: any;
  public systemoptions: string[] = ["Five Fin - Futures", "Five Fin - FCS"];
  selectedSystem;
  public sizeoptions: string[] = [];
  selectedSize: any = []
  dataproduct : any= [];
  constructor(private userService: UserService,private toastr: ToastrService,private router :Router,private firewireservices:FireWireServices,private dataService: DataService,private route:ActivatedRoute,private spinner: NgxSpinnerService, private authService: AuthenticationService, private _router: Router, private AuthService: AuthService) { }

  ngOnInit(): void {
    this.AuthService.authState.subscribe((usersocial) => {
      this.usersocial = usersocial;
      // this.loggedIn = (usersocial != null); 

    });

    this.route.params.subscribe(data => {
      var id = data['slug_url']; 
      //  console.log(id) 
      this.getProductImages(id)
      this.getAllProductDetailsById(id)
      //  this.getAllcourseData(id)

    });
  }

  public imageList = [];
  img:any={};
  public isImg;
  getProductImages(product_id){
    var id={
      product_id:product_id
    }
    this.spinner.show();
    this.firewireservices.getProductImagesByProduct_id(id)
    .subscribe(
      response => {
        
        var imgList = response.data.result; 
        var str;
        var list = []

        if(response.data.status !=0){

          imgList.forEach(element => {
             str={path: appConfig.hs+element.image}
            list.push(str)
          });

          this.isImg = 1;
        }else{
          this.img="assets/images/Picture2.png"

          this.isImg = 0;
             
        }
       
        // this.imageList = list;
        const slideItems = [
      { path: 'assets/images/1.png', title: 'Title 1' },
      { path: 'assets/images/a.jpg', title: 'Title 2' }, 
    ];

         this.imageList = list;
        // console.log(this.slider.items)
        this.spinner.hide();
        // console.log("cartlist",this.cartlist)

      },

    )
      
  }
   
boardDetails:any=[];
ProductDetails = []
  getAllProductDetailsById(product_id) {
    var data = {
      product_id : product_id
    }

    this.firewireservices.getProductByID(data)
      .subscribe(
        response => {

          // if()
            var data = response.data.result[0];
            this.boardDetails = data;
            if(response.data.result.length > 0)
          {
            this.dataproduct = response.data.result
            this.saledata = response.data.result[0];
            if(this.saledata.color != undefined || this.saledata.color != null){
              var color = this.saledata.color.replace(/"/g, '');
              // console.log(color)
              this.coloroptions = color.split(",");
            }
            if(this.saledata.size != undefined || this.saledata.size != null){
              var sizeopt = this.saledata.size.replace(/"/g, '');
  
              this.sizeoptions = sizeopt.split(",");
            } 
            if(this.saledata.fin_system != undefined || this.saledata.fin_system != null){
              this.selectedSystem = this.saledata.fin_system; 
            }
            this.ProductDetails = response.data.result
            this.spinner.hide();
          }
          
        },
        err => {
          this.spinner.hide();

        }
      )

  }
  
  purchase() {
    
    // console.log(this.dataproduct)
    
    this.cartlist = localStorage.getItem('Product_Details');
    var dataval = JSON.parse(this.cartlist);
    if (dataval == null) {

      var val = {
        product_id: this.saledata.product_id,
        user_id: this.userid,
        name: this.saledata.name,
        address: this.saledata.address,
        product_type: this.saledata.type,
        fin_system: this.saledata.fin_system,
        color: this.selectedColor,
        technology:this.saledata.technology,
        price: this.saledata.price,
        image: this.saledata.image,
        tax:this.saledata.tax,
        fixrate:this.saledata.fixrate,
        pic_up:this.saledata.pic_up,
        status:this.saledata.status,
        qty: "1",

      }
      var pro_list =[]
        pro_list.push(val)
      this.spinner.show();

      localStorage.setItem('Product_Details',JSON.stringify(pro_list));
      this.firewireservices.addtocart(val)
        .subscribe(
          response => {
            // console.log(response.data);
            if(response.data.status == 1)
            {
              this.toastr.success(response.data.message);
              this.router.navigate(['cart-page'])
              // this.getcartProductByUserID();
              this.spinner.hide();
            }else{
              console.log("error");
            }
          
          },
          err => {
            this.toastr.error('Add To Cart Unsuccessfully');
            this.spinner.hide();
          }
        )

    } else {
      if (this.saledata.product_id == dataval[0].product_id) {
        var val = {
          product_id: this.saledata.product_id,
          user_id: this.userid,
          name: this.saledata.name,
          address: this.saledata.address,
          product_type: this.saledata.type,
          fin_system: this.saledata.fin_system,
          color: this.selectedColor,
          technology:this.saledata.technology,
          price: this.saledata.price,
          image: this.saledata.image,
          tax:this.saledata.tax,
          fixrate:this.saledata.fixrate,
          pic_up:this.saledata.pic_up,
          status:this.saledata.status,
          qty: "1",


        }
        var pro_list =[]
        pro_list.push(val)
      this.spinner.show();

      localStorage.setItem('Product_Details',JSON.stringify(pro_list));
        this.spinner.show();
        this.firewireservices.addtocart(val)
          .subscribe(
            response => {
              if(response.data.status == 1)
              {
                this.toastr.success(response.data.message);
                // this.getcartProductByUserID(this.saledata.product_id);
                this.router.navigate(['cart-page'])
                this.spinner.hide();
              }else{
                console.log("error");
              }
            },
            err => {
              this.toastr.error('Add To Cart Unsuccessfully');
              this.spinner.hide();

            }
          )
      } else {
        this.toastr.error('One Product Alerdy Exist in Cart');
      }
    }
  }

  aboutBoard(){
    // console.log(this.boardDetails)   
    var data = {
      value : this.boardDetails,
      ProductsDetails : this.ProductDetails
    }
    this.dataService.updateData(data)
    this.router.navigate(['sale-page'])
  
  }


  // <-----------Facebook------------>

  signInWithFB(): void {
    // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => console.log(x));
    let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    this.AuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((userData) => {
        //this will return user data from facebook. What you need is a user token which you will send it to the server
        // console.log("user facebook",userData.photoUrl)
        var path = userData.photoUrl;
        // sessionStorage.setItem("socialimg",JSON.stringify(path));
        this.SavesresponseFacebook(userData);
        // this._router.navigate(['dashboard']);
        // console.log(userData)
      });
  }
  SavesresponseFacebook(userData) {
    var data = {
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      facebook_id: userData.id,
      image: userData.photoUrl,

    }
    this.spinner.show()
    this.userService.socialRegister(data)
      .subscribe(
        data => {
          var custdetails = data;
          this.socialLoginFacebook(userData.id)
        },
        error => {
          console.log(error);
        });
    // console.log(data)
  }

  socialLoginFacebook(userid) {
    var userSet = {
      facebook_id: userid,
      provider: 'facebook'
    };

    this.userService.social_login(userSet).subscribe((res: any) => {
      this.spinner.hide()
      // debugger;
      this._router.navigate(['my-quiver-account']);
    });
  }

  // <-----------Facebook  End------------>




  // <-----------Google------------>


  signInWithGoogle(): void {
    // alert()
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => console.log(x));

    let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    this.AuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((userData) => {
        // var path = userData.photoUrl;
        // sessionStorage.setItem("socialimg",JSON.stringify(path));
        //this will return user data from facebook. What you need is a user token which you will send it to the server
        this.SavesresponseGoogle(userData);
        // this._router.navigate(['dashboard']);
        // console.log(userData)
      });
  }
  SavesresponseGoogle(userData) {
    var data = {
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      google_id: userData.id,
      image: userData.photoUrl,
    }

    this.spinner.show()

    this.userService.socialRegister(data)
      .subscribe(
        data => {
          var custdetails = data;
          this.socialLoginGoogle(userData.id)
        },
        error => {
          console.log(error);
        });
  }
  socialLoginGoogle(userid) {
    var userSet = {
      google_id: userid,
      provider: 'google'
    };
    this.userService.social_login(userSet).subscribe((res: any) => {
      // debugger;
      this.spinner.hide()
      this._router.navigate(['my-quiver-account']);
    });
  }

  // <-----------Google End------------>
  
}
