export const appConfig = {

        // apiUrl: "https://redsparktech.a2hosted.com:30005",
        // hs:"https://redsparktech.a2hosted.com/",

        //  apiUrl: "https://reds.a2hosted.com:30005",
        // hs:"https://reds.a2hosted.com/",

        apiUrl: "https://surfform.com:30005",
         hs:"https://surfform.com/",
         
       
};