import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConfig } from 'src/app/app.config';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {
  model: any = {};
  public userid;
  public users: any = {};
  previewUrluser: any = null;
  public FollowerList: any = [];
  public Followinglist: any = [];
  starsCount = 5;
  writeFlag = false;
  reviewObj: any = {};
  loggedInUser: any = {}
  userList = [];
  reviewList =[];
  thankyouFlag= false;
  FollowingList: any = [];

  constructor(public firewireservices: FireWireServices,
    public spinner: NgxSpinnerService, public router: Router) {
    this.userid = localStorage.getItem('userId');
    this.loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
    this.model.starsCount = this.loggedInUser?.rating?.rating;
    this.getUserProfile()
    // this.getAllFollower()
    // this.getAllFollowing()
    this.getFollowingByUser();
    this.getAllFollowersbyUser();
    this.getAllUsers();
    this.getloggedInUserReview();

  }

  ngOnInit(): void {
  }

  getloggedInUserReview() {
    this.spinner.show();
    let sendData = {
      user_id: this.loggedInUser.user_id,
    }
    this.firewireservices.getloggedInUserReview(sendData)
      .subscribe(
        response => {
          var data = response.data;
          console.log(data)
          if (data.status == 1) {
            let reviewList = data.result;
            reviewList.forEach(element => {
              if(element.isApproved == 1){
                if (element.image != '' && element.image != null && element.image != undefined) {
                  element.image = appConfig.hs + element.image;
                } else {
                  element.image = "assets/images/Picture2.png"
                }
               
                this.reviewList.push(element)
              }
            });
          } else {
            this.reviewList = [];
          }
          this.spinner.hide();
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )

  }

  getFollowingByUser() {
    this.spinner.show();
    this.firewireservices.getFollowingByUser(Number(this.loggedInUser.user_id))
      .subscribe(
        res => {
          // debugger
          if (res.data.verified == 1) {
            // debugger
            this.FollowingList = res.data.info
            this.spinner.hide();
          } else {
            this.FollowingList = []
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  goToUserdata(item) {
    console.log(item)
    this.router.navigate(['/user-product'], { queryParams: { user_id: item.reviewerid } });
  }

  getAllFollowersbyUser() {
    this.spinner.show();
    this.firewireservices.getAllFollowersbyUser(Number(this.loggedInUser.user_id))
      .subscribe(
        res => {
          if (res.data.verified == 1) {
            this.FollowerList = res.data.info
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  redirectAfterDone(){
    this.router.navigate (['/my-quiver-account'])
  }

  submitReview() {
    this.spinner.show();

    // let spl = this.reviewObj.touserdetail.
    let sendData = {
      touserid: this.reviewObj?.touserdetail?.user_id,
      tousername: this.reviewObj?.touserdetail?.first_name,
      rating: this.reviewObj.rating,
      review: this.reviewObj.review,
      reviewername: this.loggedInUser.first_name,
      reviewerid: this.loggedInUser.user_id
    }

    console.log('sendData', sendData)
    
    this.firewireservices.createReview(sendData)
      .subscribe(
        response => {
          var data = response.data;
          console.log(data)
          if (data.status == 1) {
            // alert()
            this.thankyouFlag = true;
             this.writeFlag = false;
          }
          this.spinner.hide();
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )

  }

  gotoFollowers() {
    this.router.navigate(['/follower'], { queryParams: { user_id: this.loggedInUser.user_id } });
  }

  gotoFollowing() {
    this.router.navigate(['/following'], { queryParams: { user_id: this.loggedInUser.user_id } });
  }

  getAllUsers() {
    this.spinner.show();
    this.firewireservices.getAllUsers()
      .subscribe(
        response => {
          var data = response;
          let userData = response.data
          if (data.data.status == 1) {
            console.log("data", userData)
            let users = []
            for (let i = 0; i < userData.result.length; i++) {
              if( userData.result[i].user_id != this.loggedInUser.user_id){
                var str = { label: userData.result[i].first_name, value: userData.result[i]}

              }
              // var str = { label: userData.result[i].first_name, value: userData.result[i].user_id + ',' + userData.result[i].first_name }

              users.push(str)
            }
            console.log(users)
            this.userList = users;
          }
          this.spinner.hide();
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )
  }

  getUserProfile() {

    // console.log(this.userid);
    var data = {
      user_id: JSON.parse(this.userid)
    }

    this.firewireservices.getUsersByID(data)
      .subscribe(
        response => {
          var data = response.data;
          // console.log("users", data);
          this.editcliendataResponse(data)
        }
      )
  }

  editcliendataResponse(data) {
    // console.log("slugurl",data);
    this.model.first_name = data.result[0].first_name;
    this.model.address = data.result[0].address;
    this.model.city = data.result[0].city;
    this.model.state = data.result[0].state;
    this.model.country = data.result[0].country;
    this.model.phone = data.result[0].phone;
    this.model.post_code = data.result[0].post_code;
    this.model.email = data.result[0].email;

    if (data.result[0].image != '' && data.result[0].image != null && data.result[0].image != undefined) {
      // this.previewUrl = location.origin + '/' + this.users.image
      this.previewUrluser = appConfig.hs + data.result[0].image;
    } else {
      this.previewUrluser = "assets/images/Picture2.png"
    }
    console.log('this.model', this.model)
  }

  getAllFollower() {
    var data = {
      follow_id: this.userid,
    }
    this.firewireservices.getAllFollower(data)
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {
            this.FollowerList = data
            var cnt = 0;
            var list = [];

            this.FollowerList.forEach(follo => {
              if (follo.follow_id == this.userid) {
                cnt = cnt + 1;
              }
            });
            var str = {
              totalfollowers: cnt
            }
            list.push(str)
            this.FollowerList = list
          } else {
            var list = [];
            var str = {
              totalfollowers: 0
            }
            list.push(str)
            this.FollowerList = list
            // console.log("FollowerList",this.FollowerList)

          }
        }, err => {
          console.log("error");
        }
      )
  }

  getAllFollowing() {
    var data = {
      user_id: this.userid
    }
    this.firewireservices.getAllFollowing(data)
      .subscribe(
        response => {
          var data = response.data.result
          console.log(data)
          if (data.length > 0) {
            this.Followinglist = data;

            var cnt = 0;
            var list = [];
            this.Followinglist.forEach(follo => {
              if (follo.user_id == this.userid) {
                cnt = cnt + 1;
              }
            });
            var str = {
              totalfollowers: cnt
            }
            list.push(str)
            this.Followinglist = list
            // console.log(this.followid)
            // console.log("Followinglist",this.Followinglist)
          } else {
            var list = []
            var str = {
              totalfollowers: 0
            }
            list.push(str)
            this.Followinglist = list
            // console.log(this.Followinglist.length)
          }
        }, err => {
          console.log("error");
        }
      )
  }


}
