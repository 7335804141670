import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { appConfig } from '../../app.config'
@Component({
  selector: 'app-user-product',
  templateUrl: './user-product.component.html',
  styleUrls: ['./user-product.component.css']
})
export class UserProductComponent implements OnInit {
  hs = appConfig.hs;
  usernameid: any = {};
  user: any = {};
  ulist = false;
  public followers : any = []
  public itemlist = 0;
  public userid;
  itemval: any = {};
  varyfietoken: any;
  likelistproduct: any = [];
  selectedlike: any = [];
  productList: any = [];
  tempProductList: any = [];
  public userlist: any = []

  public ProductDetails;
  public searchuserlist: any = [];
  likeList: any;
  public FollowerList: any = [];
  public Followinglist: any = [];
  previewUrluser = null;
  followid: any = {};
  userList = [];
  datava: any = {};
  public boardStatus: any =
    [
      { id: 0, value: "All" },
      { id: 1, value: "For sale" },
      { id: 2, value: "Stolen" },
      { id: 3, value: "Quivers" }
    ];
  filter: any = null;
  user_id: any;
  imagepath: any;
  followerimage: any;
  loggedInUser: any;
  FollowingList: any = [];
  selected_product = null;
  Whishlist = []
  boardlist = []

  constructor(private firewireservices: FireWireServices, private formBuilder: FormBuilder, private dataService: DataService, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private _route: ActivatedRoute, private router: Router,public activatedRoute: ActivatedRoute,) {
    this.userid = localStorage.getItem('userId');
    this.varyfietoken = localStorage.getItem('token');
    this.datava = localStorage.getItem("productuser_id");
    this.loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))

    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params)
      if (params['user_id']) {
        console.log('if')
        this.user_id= params['user_id'];
        console.log(this.user_id)
        // this.getAllFollower();
        //   this.getAllFollowing();
        this.getFollowingByUser();
        this.getAllFollowersbyUser();
          this.updateUserProfile();
          this.getAllProduct();
          this.getAllUsers();
        this.userById();
      } else {
        console.log('else')
        // this.user_id= params['user_id'];
        // console.log(this.user_id)
        // this.userById();
        // this.getAllProduct();
      }
    })

  }
  ngOnInit(): void {

    // let temp= localStorage.getItem("userProduct")
    // // if(temp){
      // this.usernameid = JSON.parse(localStorage.getItem("userProduct"));
    //   console.log(this.usernameid)
    // // }


    this.usernameid = JSON.parse(localStorage.getItem('sellerData'))
    console.log(this.usernameid)
    // this.dataService.share
    //   .subscribe(
    //     data => {
    //       this.closel()
    //       var userdata: any = data;
    //       if (userdata.value != "" && userdata.value != undefined) {

    //         // this.usernameid = userdata.value
    //         if (this.varyfietoken != null) {
    //           localStorage.getItem('searchUser')
    //           // localStorage.setItem("userProduct", JSON.stringify(this.usernameid))
    //         }
    //         this.getAllProduct();
    //       } else {
    //         var dataitem = JSON.parse(localStorage.getItem("userProduct"));
    //         if (dataitem != null) {
    //           this.getAllProduct();
    //         }
    //       }

    //       this.getAllFollower();
    //       this.getAllFollowing();
    //       this.updateUserProfile();
    //     }
    //   )

    // this.getAllFollower();
    // this.getAllFollowing(); 
    // this.updateUserProfile();   

  }
  
  getFollowingByUser() {
    this.spinner.show();
    this.firewireservices.getFollowingByUser(Number(this.user_id))
      .subscribe(
        res => {
          // debugger
          if (res.data.verified == 1) {
            // debugger
            this.FollowingList = res.data.info
            this.spinner.hide();
          } else {
            this.FollowingList = []
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  gotoFollowers() {
    this.router.navigate(['/follower'], { queryParams: { user_id: this.user_id } });
  }

  gotoFollowing() {
    this.router.navigate(['/following'], { queryParams: { user_id: this.user_id } });
  }
  flag = 0;

  getAllFollowersbyUser() {
    this.spinner.show();
    this.firewireservices.getAllFollowersbyUser(Number(this.user_id))
      .subscribe(
        res => {
          if (res.data.verified == 1) {
            this.FollowerList = res.data.info
            // debugger
            this.FollowerList.forEach(follo => {
              if (follo.follow_id == this.user_id) {
                if (follo.user_id == this.userid) {
                  this.flag = 1;
                } else {
                  this.flag = 0;
                }
              }
            });

            this.spinner.hide();
          } else {
            this.FollowerList = []
            this.spinner.hide();
            this.flag = 0;
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  SaleValueU(value) {
    var dataval = {
      value: value,

    }
    this.dataService.updateData(dataval)
       this.router.navigate(['/user-product'], { queryParams: { user_id: value.user_id } });

    // this.router.navigate(['user-product'])
  }


  getFollowers(id) {
    this.spinner.show();
    let data = {
      id: id
    }
    this.firewireservices.getFollowers(data)
      .subscribe(data => {
        this.followers = data.data.result;
        console.log(this.followers);
        this.followers.forEach(element => {
          element.image = appConfig.hs + element.image
          console.log(element.images)
        });

        this.ulist = true;
        this.spinner.hide();
      })
  }

  productlike(val) {
    var data = {
      user_id: this.userid,
      product_user: val.user_id,
      product_id: val.product_id,
      name: val.name,
      status: val.status,
      type: val.type,
      price: val.price,
      icon: val.image
    }
    this.spinner.show();
    this.firewireservices.productlike(data)
      .subscribe(
        response => {
          var data = response.data
          if (data.status == 1) {
            // this.toastr.success(data.message);
            this.getAllikeList();
            this.getAlllikebyproduct();
            this.spinner.hide();
          } else {
            this.getAllikeList();
            this.getAlllikebyproduct();
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }
  chageslikeicon(vals, id) {
    this.selectedlike[id] = vals
    if (this.selectedlike[id] == "no") {
      this.productList = [];
      this.getAllProduct();
    }
    // 
  }
  getAllProduct() {
    var dataitem = JSON.parse(localStorage.getItem("userProduct"));
    if (dataitem != null) {
      this.productList = [];
      // this.usernameid = dataitem;
    }
    var data = {
      user_id: this.user_id
    }
    this.spinner.show();
    this.firewireservices.getProductByuserID(data)
      .subscribe(
        response => {

          if (response.data.status == 1) {
            var data = response.data.result;
            var datamsg = response.data;
            var dataimg = response.data.result
            if (data.length > 0) {

              for (var i = 0; i < data.length; i++) {
                if (data[i].images != '' && data[i].images != null) {
                  data[i].image = appConfig.hs + data[i].images[0].image
                }
                else {
                  data[i].image = 'assets/images/Picture2.png'
                }

                this.productList.push(data[i])
                this.getAllWishList()
                this.getAllikeList();
                this.getAlllikebyproduct();

              }
              this.tempProductList = this.productList
              this.spinner.hide();
            }
          } else {
            // this.productList = {};
            this.spinner.hide();
          }
        },
        err => {
          this.spinner.hide();

        }
      )

  }
  onSelect() {
    if (this.filter == null) {
      this.productList = this.tempProductList
    } else {
      this.productList = this.tempProductList.filter(m => Number(m.boardstatus) === Number(this.filter));
    }
  }


  SaleValue(value) {
    var dataval = {
      value: value,
      ProductsDetails: this.ProductDetails
    }
    this.closel()
    this.dataService.updateData(dataval)
    this.router.navigate(['sale-page'])
  }

  getAllikeList() {

    this.firewireservices.getAllLikelist()
      .subscribe(
        response => {

          var data = response.data.result
          console.log(data)
          if (data.length > 0) {

            this.likeList = data;
            // console.log( this.likeuser)
            this.boardlist.forEach(element => {
              var status = this.likeList.find(x => x.product_id === element.product_id && x.user_id === this.userid);
              if (status != undefined) {
                element.like = true;
              }
            });
            
          } else {
            this.likelistproduct = [];
            console.log("not data found");

          }
        },
        err => {
          console.log("error");

        }
      )
  }

  getAlllikebyproduct() {
    // var dataval = {
    //   user_id: JSON.parse(this.userid)
    // }
    this.spinner.show();
    this.firewireservices.getAlllikebyproduct()
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {

            data.forEach(element => {
              if (element.image != '' && element.image != null) {
                element.image = appConfig.hs + element.image;

              }
            })
            this.likelistproduct = data;
            var list = []
            var proImage = []
            this.productList.forEach(board => {
              var cnt = 0;
              var product;
              var img1;
              var img2;
              var img = [];
              this.likelistproduct.forEach(likes => {
                console.log('likeeeeeeeeeeeeeee', likes)
                if (board.product_id != undefined && likes.product_id != undefined) {
                  if (board.product_id == likes.product_id) {
                    cnt = cnt + 1;
                    product = likes.product_id;
                    var iconImg = "assets/images/Picture2.png"
                    if (likes.image != undefined && likes.image != null && likes.image != '') {
                      img.push(likes.image);
                    } else {
                      img.push(iconImg)
                    }

                    // proImage.push(likes.product_id,likes.image);                                        
                  }
                }
              });
              var str = {
                product_id: product,
                image: img,
                totalLikes: cnt,
                //  proImage : proImage,

              }
              list.push(str)
            });
            this.likelistproduct = list

            // console.log("pro",proImage)
            console.log("likelistproduct", this.likelistproduct )

            // console.log(list) 

            let res = [];
            this.spinner.hide();
          } else {
            this.likelistproduct = [];
            console.log("not data found");
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }
  updateUserProfile() {
    var data = {
      // user_id: JSON.parse(this.userid)
      user_id : this.usernameid.user_id
    }

    this.firewireservices.getUsersByID(data)
      .subscribe(
        response => {
          var data = response.data.result;
          console.log('data', data)
          if (data.length != 0) {
            var users = response.data.result[0]
            this.user = users;
            this.user.fulladdress = this.user.address + ", " + this.user.city + ", " + this.user.country
            console.log('fulladdress',this.user.fulladdress)
            if (users.image != '' && users.image != null) {
              // this.previewUrl = location.origin + '/' + this.users.image
              this.previewUrluser = appConfig.hs + users.image
            } else {

              this.previewUrluser = "assets/images/Picture2.png"
            }

          }

        }
      )
  }
  UserFollow() {
    if (this.varyfietoken != null) {
      // console.log("usernameid", this.usernameid)
      var data = {
        user_id: this.loggedInUser.user_id,
        follow_id: this.user_id,
        icon: this.previewUrluser
      }
      this.spinner.show();
      this.firewireservices.UserFollow(data)
        .subscribe(
          response => {
            var data = response.data;
            if (data.status == 1) {
              // this.toastr.success(data.message);
              // this.getAllFollower();
              // this.getAllFollowing();
              this.getFollowingByUser();
              this.getAllFollowersbyUser();
              this.spinner.hide();
            } else {
              // this.getAllFollowing();
              // this.getAllFollower();
              this.getFollowingByUser();
              this.getAllFollowersbyUser();
              this.spinner.hide();
            }
          },
          err => {
            console.log("error");
            this.spinner.hide();
          }
        )
    } else {
      document.getElementById('registerModal').click();
    }
  }
  getAllFollower() {
    var data = {
      follow_id: this.user_id,
    }
    this.firewireservices.getAllFollower(data)
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {
            this.FollowerList = data
            var cnt = 0;
            var list = [];

            this.FollowerList.forEach(follo => {
              if (follo.follow_id == this.user_id) {
                cnt = cnt + 1;
                if (follo.user_id == this.userid) {
                  this.flag = 1;
                } else {
                  this.flag = 0;
                }
              }
            });
            var str = {
              totalfollowers: cnt
            }
            list.push(str)
            this.FollowerList = list
          } else {
            var list = [];
            var str = {
              totalfollowers: 0
            }
            list.push(str)
            this.FollowerList = list
            this.flag = 0;
            // console.log("FollowerList",this.FollowerList)

          }
        }, err => {
          console.log("error");
        }
      )
  }

  getAllFollowing() {
    var data = {
      user_id: this.user_id
    }
    this.firewireservices.getAllFollowing(data)
      .subscribe(
        response => {
          var data = response.data.result
          // console.log(data)
          if (data.length > 0) {
            this.Followinglist = data;

            var cnt = 0;
            var list = [];
            this.Followinglist.forEach(follo => {
              if (follo.user_id == this.userid) {
                cnt = cnt + 1;
              }
            });
            var str = {
              totalfollowers: cnt
            }
            list.push(str)
            this.Followinglist = list
            // console.log(this.followid)
            // console.log("Followinglist",this.Followinglist)
          } else {
            var list = []
            var str = {
              totalfollowers: 0
            }
            list.push(str)
            this.Followinglist = list
            // console.log(this.Followinglist.length)
          }
        }, err => {
          console.log("error");
        }
      )
  }

  userById() {
    var valdata = {
      user_id: this.user_id
    }
    console.log(valdata)
    this.spinner.show();
    this.firewireservices.getUsersByID(valdata)
      .subscribe(
        response => {
          this.userlist = response.data.result[0]
          this.imagepath = appConfig.hs + this.userlist.image 
          console.log(this.userlist)
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
        }
      )
  }

  getLikes(product) {
    console.log('product', product)
  
    this.ulist = true;
    this.selected_product = product
  }

  closel() {
    this.ulist = false;
    this.selected_product = null
  }

  getAllWishList() {
    var dataval = {
      user_id: JSON.parse(this.userid)
    }
    if (dataval.user_id) {


      this.firewireservices.getAllWishlist(dataval)
        .subscribe(
          response => {
            var data = response.data.result
            if (data.length > 0) {

              data.forEach(element => {
                if (element.image != '' && element.image != null) {
                  element.image = appConfig.hs + '/' + element.image;
                } else {
                  element.image = 'assets/images/Picture2.png'
                }
              })
              this.Whishlist = data;

            } else {
              console.log("not data found");

            }
          },
          err => {
            console.log("error");

          }
        )
    }
  }

  getAllUsers() {
    this.spinner.show();
    this.firewireservices.getAllUsers()
      .subscribe(
        response => {
          var data = response;
          let userData = response.data
          if (data.data.status == 1) {
            console.log("data", userData)
            let users = []
            for (let i = 0; i < userData.result.length; i++) {
              if( userData.result[i].user_id != this.loggedInUser.user_id){
                var str = { label: userData.result[i].first_name, value: userData.result[i]}

              }
              // var str = { label: userData.result[i].first_name, value: userData.result[i].user_id + ',' + userData.result[i].first_name }

              users.push(str)
            }
            console.log(users)
            this.userList = users;
          }
          this.spinner.hide();
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )
  }
}
