import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { SwiperOptions } from 'swiper';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from '../../app.config'

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.css']
})
export class MainpageComponent implements OnInit {
  public imagesUrl;

  image: any = {};
  public marketlist;
  imageObject: Array<object> = [];
  previousUrl: any;
  currentUrl: any;
  isFront = true;
  Whishlist = []
  public userid;
  public varyfietoken;
  boardlist = []
  likelistproduct: any[];
  likeList: any;

  selectedheart: any = [];
  itemval: any = {};

  selectedlike: any = [];

  likeID: any = {};
  likeuser: any = [];
  totallike = [];
  ProductDetails: any;
  hs = appConfig.hs;

  ulist: boolean = false;
  selected_product = null;
  userList = [];
  product_id : any;

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    items: 1,
    dots: true,
    rtl: true,
    nav: false,
    margin: 0,
    navText: ["<i class='icofont-thin-double-left'></i>", "<i class='icofont-thin-double-right'></i>"],
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      768: {
        items: 1,
        nav: false,
      },
      1100: {
        items: 1,
      }
    }
  }


  constructor(private firewireservices: FireWireServices, private formBuilder: FormBuilder, private dataService: DataService, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private _route: ActivatedRoute, private router: Router) {
    this.getAllBrands();
    this.getAllMarket();
    this.userid = localStorage.getItem('userId');
    this.varyfietoken = localStorage.getItem('token');
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects
        this.currentUrl = events[1].urlAfterRedirects
        // console.log("previousUrl", this.previousUrl);
        // console.log("currentUrl", this.currentUrl);


      });
  }

  ngOnInit(): void {
    this.getHomeProduct()
    // this.imagesUrl = ["https://dhol.app/firewireUploads/assets/brandsimg/file-1600750321990.jpg", 'assets/images/Picture2.png', 'assets/images/Picture2.png'];
    // console.log("imageslide",this.imagesUrl)
  }

  //   data = this.image
  //   imageObject: Array<object> = [{


  //     data.forEach(element => {
  //       if (element.image != '' && element.image != null) {                  
  //         element.image = location.origin + '/' + element.image;
  //       } else {
  //         element.image = 'assets/images/Picture2.png'
  //       }
  //     });
  //     image: 'assets/images/slide1.jpg',
  //     thumbImage: 'assets/images/slide1.jpg',
  //     alt: 'alt of image',
  //     title: 'title of image'
  // },{
  //   image:  this.image,
  //   thumbImage:  this.image,
  //   alt: 'alt of image',
  //   title: 'title of image'
  // }]

  DeleteModal(data){
    this.product_id = data.product_id
    console.log(this.product_id)
  }

  flagProduct() {
    if ( localStorage.getItem('token') != null) {
        
    let sendData= {
      product_id: this.product_id,
      flag: true
    }

    this.firewireservices.updateProductFlag(sendData)
    .subscribe(
      response => {
        if (response.data.status == 1) {
          this.getHomeProduct();
            this.toastr.success(response.data.message);
            this.spinner.hide();          
        } else {
          this.spinner.hide();
          this.toastr.error(response.data.message);
        }
      },
      err => {
        console.log("error",err);
        this.spinner.hide();
      }
    )
  } else {
    document.getElementById('registerModal').click()
    // this.router.navigate(['/login'])
  }
  }


  SaleValueU(value) {
    value.image = appConfig.hs + value.image
    var dataval = {
      value: value,
    }
    this.dataService.updateData(dataval)
    // this.router.navigate(['user-product'])
    this.router.navigate(['/user-product'], { queryParams: { user_id: value.user_id } });

  }


  getAllMarket() {

    this.firewireservices.getAllMarketingLogo()
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {

            var imageslide = [];
            // data.forEach(element => {
            //         if (element.image != '' && element.image != null) {                  
            //           element.image ="https://dhol.app/" + element.image;
            //         } else {
            //           element.image = 'assets/images/Picture2.png'
            //         }
            //       });
            var str1;
            response.data.result.forEach(element => {
              if (element.image != '' && element.image != null) {
                str1 = { path: appConfig.hs + element.image }
                imageslide.push(str1)
              } else {
                str1 = { path: "assets/images/Picture2.png" + element.image }
                imageslide.push(str1)
              }
            });
            this.imageObject = imageslide
             console.log("imageslide",this.imageObject)
          } else {
            console.log("No Data Found");
          }
        }
      )
  }

  resetFlip() {
    this.isFront = true;
  }
  getAllBrands() {

    this.firewireservices.getAllBrands()
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {

            data.forEach(element => {
              if (element.image != '' && element.image != null) {
                // element.image = location.origin + '/' + 
                // this.marketlist = "https://dhol.app/" +'/' + element.image;
                element.image = appConfig.hs + element.image;
              } else {
                element.image = 'assets/images/Picture2.png'
              }
            });
            this.marketlist = data
            // console.log(this.marketlist );

          } else {
            console.log("no data found");
          }
        }
      )
  }
  SaleValue(value) {
    var dataval = {
      value: value,
      ProductsDetails: this.ProductDetails
    }
    this.dataService.updateData(dataval)
    this.router.navigate(['sale-page'])
  }

  


  chagesicon(val, no) {
    // console.log(val,no)
    this.itemval = val
    this.selectedheart[no] = val
  }
  chageslikeicon(vals, id) {
    // this.likeID = itemval.id
    this.selectedlike[id] = vals
    if (this.selectedlike[id] == "no") {
      this.getHomeProduct();
    }
    // console.log(this.selectedlike[id]);
  }

  WishListFunction(vale, itemval) {
    var datawish = {
      product_id: vale.product_id,
      user_id: this.userid,
      name: vale.name,
      status: vale.status,
      image: vale.image,
      type: vale.type,
      opt: itemval,
      price: vale.price
    }
    this.spinner.show();
    this.firewireservices.AddWishList(datawish)
      .subscribe(
        response => {
          var data = response.data
          // console.log(data)
          // this.product(data)
          if (data.status == 1) {
            this.toastr.success(data.message);
            this.getAllikeList();
            this.getAlllikebyproduct();
            this.spinner.hide();
          } else {
            this.toastr.error(data.message);
            this.spinner.hide();
          }
        },
        err => {
          this.toastr.error('Add To WishList Unsuccessfully');
          this.spinner.hide();

        }
      )
  }


  removeItemFromWishlist(dataval, val) {
    // console.log("dataval",val)
    var data = {
      wish_id: dataval.wish_id,
    }
    this.spinner.show();
    this.firewireservices.removeItemFromWishlist(data)
      .subscribe(
        response => {
          var data = response.data;
          if (data.status == 1) {
            this.toastr.success(data.message);
            this.getAllikeList();
            this.getAlllikebyproduct();
            // this.getAllWishList();
            this.spinner.hide();
          } else {
            this.toastr.success(data.message);
          }
        },
        err => {
          this.spinner.hide();
          this.toastr.error('Records not Deleted');
        }
      )
  }


  productlike(val) {

    var data = {
      user_id: this.userid,
      product_user: val.user_id,
      product_id: val.product_id,
      name: val.name,
      status: val.status,
      type: val.type,
      price: val.price,
      icon: val.image
    }
    this.spinner.show();
    this.firewireservices.productlike(data)
      .subscribe(
        response => {
          var data = response.data
          if (data.status == 1) {
            // this.toastr.success(data.message);
            this.getAllikeList();
            this.getAlllikebyproduct();
            this.spinner.hide();
          } else {
            this.getAllikeList();
            this.getAlllikebyproduct();
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  getHomeProduct() {
    this.spinner.show();
    this.firewireservices.getHomeProduct().subscribe(response => {
      // var boardtotal = response.data.result
      // console.log(boardtotal)
      this.boardlist =[]
      var data = response.data.result;
      if (data.length > 0) {
        // this.ProductDetails = response.data.result

        data.forEach(element => {
          if (element != '' && element.images != null && element.images.length != 0) {
            element.image = appConfig.hs + element.images[0].image
          } else {
            element.image = 'assets/images/Picture2.png'
          }
          if (element.boardstatus) { 
            // flag status 0 means reject flag from admin and 1 for accept flag
            if (element.boardstatus == '1' && (element.flagstatus == 0 || element.flagstatus == null) && (element.payment_status == 2 ||  element.payment_status == null)) {
              
              if(element.payment_status  == 2 && element.reserve_user_id  != this.userid){
                element.isDisbled =  true;
              }else{
                element.isDisbled = false;
              }
              this.boardlist.push(element)
            }
          }

        });
        this.spinner.hide()
        // for (var i =0; i < data.length;i++)
        // {


        //   this.boardlist = data;

        //  }

        this.getAllWishList();
        this.getAllikeList();
        this.getAlllikebyproduct();
        // this.spinner.hide();
      } else {
        this.spinner.hide()

      }
    })
  }

  dummyFun() {
    
  }

  getAllWishList() {
    var dataval = {
      user_id: JSON.parse(this.userid)
    }
    if (dataval.user_id) {


      this.firewireservices.getAllWishlist(dataval)
        .subscribe(
          response => {
            var data = response.data.result
            if (data.length > 0) {

              data.forEach(element => {
                if (element.image != '' && element.image != null) {
                  element.image = appConfig.hs + '/' + element.image;
                } else {
                  element.image = 'assets/images/Picture2.png'
                }
              })
              this.Whishlist = data;

            } else {
              console.log("not data found");

            }
          },
          err => {
            console.log("error");

          }
        )
    }
  }

  getAllikeList() {

    this.firewireservices.getAllLikelist()
      .subscribe(
        response => {

          var data = response.data.result
          if (data.length > 0) {

            this.likeList = data;
            // console.log( this.likeuser)
            this.boardlist.forEach(element => {

              var status = this.likeList.find(x => x.product_id === element.product_id && x.user_id === this.userid);
              if (status != undefined) {
                element.like = true;
              }
            });
            console.log(this.boardlist)
          } else {
            this.likelistproduct = [];
            console.log("not data found");

          }
        },
        err => {
          console.log("error");

        }
      )
  }

  getLikes(product) {
    this.ulist = true;
    this.selected_product = product
  }
  
  closel() {
    this.ulist = false;
    this.selected_product = null
  }
  getAlllikebyproduct() {
    this.firewireservices.getAlllikebyproduct()
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {

            data.forEach(element => {
              if (element.image != '' && element.image != null) {
                element.image = appConfig.hs + element.image;

              }
            })
            this.likelistproduct = data;
            console.log(data)
            var list = []
            var proImage = []
            this.boardlist.forEach(board => {
              var cnt = 0;
              var product;
              var img1;
              var img2;
              var img = [];
              this.likelistproduct.forEach(likes => {
                if (board.product_id != undefined && likes.product_id != undefined) {
                  if (board.product_id == likes.product_id) {
                    cnt = cnt + 1;
                    product = likes.product_id;
                    var iconImg = "assets/images/Picture2.png"
                    if (likes.image != undefined && likes.image != null && likes.image != '') {
                      img.push(likes.image);
                    } else {
                      img.push(iconImg)
                    }

                    // proImage.push(likes.product_id,likes.image);                                        
                  }
                }
              });
              var str = {
                product_id: product,
                image: img,
                totalLikes: cnt,
                //  proImage : proImage,

              }

              list.push(str)

            });
            this.likelistproduct = list

            // console.log("pro",proImage)
            // console.log("likelistproduct", this.likelistproduct )

            // console.log(list) 

            let res = [];
            // list.map(function (item) {
            //   var existItem = res.find(x => x.product_id == item.product_id);
            //   if (existItem) {
            //  }    
            //   else {                
            //    res.push(item);                
            //     console.log(res)


            //   }    
            // });         




          } else {
            this.likelistproduct = [];
            console.log("not data found");

          }
        },
        err => {
          console.log("error");

        }
      )
  }

}
