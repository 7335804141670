import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';
import { NavigationStart, Router } from '@angular/router';
import { number } from 'ngx-custom-validators/src/app/number/validator';
import { UserService } from 'src/app/services/user.service';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { appConfig } from 'src/app/app.config';

@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.css']
})
export class CartPageComponent implements OnInit {
  public userid;
  
  public subtotal = 0;
  public grandtotal :number =0;
  public Tax = 0;
  public productid: any;
  public isTax: boolean = false;
  catcoutnt
  productval: any = [];
  productList : any = [];
  public cartlist : any = [];
  public cartlistval : any = [];
  qty = 0;
  taxval: any;
  previewURL: any;
  reserve_amount : any = 0;
  public fixrate = null;
  emptyCart:Boolean  = false;
  constructor(private firewireservices: FireWireServices,private userService: UserService,private AuthService: AuthService,private router: Router, private dataService: DataService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private toastr: ToastrService) {
    this.userid = localStorage.getItem('userId');
    this.productval = JSON.parse(localStorage.getItem('Product_Details'));

    console.log(this.productval)
    console.log('getproductdetails data')
    if (this.productval != null) {
      this.emptyCart = false;
      this.getCartPtoductOffline();
    } else {
      this.emptyCart = true;
      this.productval = {}
      this.grandtotal = 0;
      this.Tax = 0;
      this.subtotal = 0;
    }
    this.subtotal =this.productval.calPrice - this.productval.reserve_amount
    this.grandtotal =  Number(this.subtotal)
    // if(this.productval.pic_up != "null" && this.productval.pic_up != "undefined" &&  this.productval.pic_up != null){
    //   this.grandtotal = this.productval.pic_up;
    // } else {
    //   this.grandtotal = this.grandtotal + Number(this.subtotal)

    // }
    // if(this.productval.fixrate != "null"  && this.productval.fixrate != "undefined"){
      // this.fixrate = this.productval.fixrate;
    

    var paymenttoken = localStorage.getItem('token');
   

    // this.getcartProductByUserID();

  }

  ngOnInit(): void {
  }

  getCartPtoductOffline() {
    var dataval = this.productval;
    console.log(dataval)
    this.previewURL = appConfig.hs + this.productval.image
    this.reserve_amount = this.productval.calPrice - this.productval.reserve_amount
    // var data  =[]
    // data = dataval; 
      console.log('in cart-page')
    if (dataval != null) {
      var subT
    //   // dataval.forEach(element => {
       
    //   //   if (element.image != '' && element.image != null) {
    //   //     element.image = appConfig.hs+ element.image;
    //   //   } else {
    //   //     element.image = 'assets/images/Picture2.png'
    //   //   }
    subT = Number(this.productval.calPrice) * Number(this.productval.qty)      

    //   // });
    //   // this.subtotal = subT;

      // this.cartlistval = dataval;
      this.getAllProductById(dataval)
      
  //  if (dataval.status == "New") {
  //    this.taxval = dataval.tax
  //    this.Tax = this.subtotal * parseInt(dataval.tax) / 100;
  //   //  this.grandtotal = this.subtotal + this.Tax;
  //    // this.subtotal = this.subtotal + this.Tax
  //    this.isTax = true;
  //  } else {
  //    this.grandtotal = this.subtotal
  //    this.isTax = false;
  //  }

   console.log(this.Tax)

    } else {
      // this.cartlist = [];
      this.grandtotal = 0;
      this.Tax = 0;
      this.subtotal = 0;
    }
    this.spinner.hide();
  }

  getAllProductById(dataval) {
      var data = {
        product_id :  dataval.product_id
      }
      this.spinner.show();
      this.firewireservices.getProductByID(data)
        .subscribe(
          response => {
            if (response.data.status == 1) {
              var data = response.data.result;
              var datamsg = response.data
              if (data.length > 0) {

                var subT
                data.forEach(element => {
                  if (element.image != '' && element.image != null) {                  
                    element.image = appConfig.hs + element.image;
                  } else {
                    element.image = 'assets/images/Picture2.png'
                  }                              
                 
                });
                this.subtotal = Number(data[0].price);
                this.productList = data; 
                
                var list=[]

            this.cartlistval.forEach(cartval => {
              var cnt = 0;            
              var price;
              var name;
              var tax;
              var color;
              var size;
              var qty;
              var pick;
              var fixrate;
              var img;
              this.productList.forEach(procart => {
                  if((cartval.product_id == procart.product_id)){                     
                        cnt = cnt + 1;
                        name = procart.name;
                        price = procart.price;
                        tax = procart.tax;
                        color = cartval.color;
                        size = cartval.size;
                        img = cartval.image
                        qty = cartval.qty
                  }
                }); 
                var str = {
                price :price,
                name:name, 
                tax : tax,
                color : color,
                size : size,
                image : img, 
                qty : qty             
                }
             
               list.push(str)   
              
            });
            this.cartlist = list
            // console.log(this.productList[0]);
            if (this.productList[0].status == "New") {
              this.taxval = this.productList[0].tax
              this.Tax = this.subtotal * parseInt(this.productList[0].tax) / 100;
              this.grandtotal = this.subtotal + this.Tax;
              // this.subtotal = this.subtotal + this.Tax
              this.isTax = true;
            } else {
              this.grandtotal = this.subtotal
              this.isTax = false;
            }
                this.spinner.hide();
              } else {
                console.log(datamsg.message)
                this.spinner.hide();
              }
            }
          },
          err => {
            this.spinner.hide();
  
          }
        )
  
    }

  getcartProductByUserID() {
    var vals = {
      user_id: JSON.parse(this.userid)
    }
    this.spinner.show();
    this.firewireservices.getcartProductByUserID(vals)
      .subscribe(
        response => {
          var data = response.data.result;
          if (data.length > 0) {
            data.forEach(element => {
              this.subtotal = this.subtotal + Number(element.price);

            });
            this.cartlist = data;
            if (data[0].status == "New") {
              this.Tax = this.subtotal / parseInt(data[0].tax);
              this.grandtotal = this.subtotal + this.Tax;
              this.isTax = true;
            } else {
              this.grandtotal = this.subtotal
              this.isTax = false;
            }

            // console.log(this.Tax)

          } else {
            this.cartlist = [];
            this.grandtotal = 0;
            this.Tax = 0;
            this.subtotal = 0;
          }
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
        }
      )
  }

  ShippingModalValue() {
    var paymenttoken = localStorage.getItem('token');
    if (paymenttoken != null) {
      // if (this.productval.length > 0) {
        var total = {
          product_id: this.productval.product_id,
          product_type: this.productval.product_type,
          name: this.productval.name,
          seller_name: this.productval.seller_name,
          image: this.productval.image,
          SubTotal: this.subtotal,
          trasaction_id: this.productval.trasaction_id,
          calPrice: this.productval.calPrice,
          reserve_amount : this.reserve_amount,
          // Tax: this.subtotal / this.Tax,
          tax:this.Tax,
          taxval:this.taxval ,
          fixrate: this.fixrate,
          pic_up: this.productList.pic_up,
          GrandTotal: this.grandtotal,
        }
        console.log(total)
        localStorage.setItem('checkout-mobile', JSON.stringify(total))
        this.dataService.updateData(total);
        this.router.navigate(['checkout-mobile'])
      // }
      // else {
      //   console.log("No Data Found");
      // }
    } else {

      this.router.events
        .subscribe((event) => {
          if (event instanceof NavigationStart) {
            if (this.router.url === '/cart-page') {
              window.localStorage.setItem('redirectUrl', this.router.url);
              // this.router.navigate(['login']);
            }
          }
        });
        document.getElementById('registerModal').click()

    }
  }

  CardModal() {
    this.productid = this.productval.product_id
  }
  
  removeProductFromCart() {
    localStorage.removeItem('Product_Details');
    window.location.reload();
  }

   // <-----------Facebook------------>

   signInWithFB(): void {
    // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => console.log(x));
    let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    this.AuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((userData) => {
        //this will return user data from facebook. What you need is a user token which you will send it to the server
        // console.log("user facebook",userData.photoUrl)
        var path = userData.photoUrl;
        // sessionStorage.setItem("socialimg",JSON.stringify(path));
        this.SavesresponseFacebook(userData);
        // this._router.navigate(['dashboard']);
        // console.log(userData)
      });
  }
  SavesresponseFacebook(userData) {
    var data = {
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      facebook_id: userData.id,
      image: userData.photoUrl,

    }
    this.spinner.show()
    this.userService.socialRegister(data)
      .subscribe(
        data => {
          var custdetails = data;
          this.socialLoginFacebook(userData.id)
        },
        error => {
          console.log(error);
        });
    // console.log(data)
  }

  socialLoginFacebook(userid) {
    var userSet = {
      facebook_id: userid,
      provider: 'facebook'
    };

    this.userService.social_login(userSet).subscribe((res: any) => {
      this.spinner.hide()
      // debugger;
      this.router.navigate(['my-quiver-account']);
    });
  }

  // <-----------Facebook  End------------>




  // <-----------Google------------>


  signInWithGoogle(): void {
    // alert()
    // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => console.log(x));

    let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    this.AuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((userData) => {
        // var path = userData.photoUrl;
        // sessionStorage.setItem("socialimg",JSON.stringify(path));
        //this will return user data from facebook. What you need is a user token which you will send it to the server
        this.SavesresponseGoogle(userData);
        // this._router.navigate(['dashboard']);
        // console.log(userData)
      });
  }
  SavesresponseGoogle(userData) {
    var data = {
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      google_id: userData.id,
      image: userData.photoUrl,
    }

    this.spinner.show()

    this.userService.socialRegister(data)
      .subscribe(
        data => {
          var custdetails = data;
          this.socialLoginGoogle(userData.id)
        },
        error => {
          console.log(error);
        });
  }
  socialLoginGoogle(userid) {
    var userSet = {
      google_id: userid,
      provider: 'google'
    };
    this.userService.social_login(userSet).subscribe((res: any) => {
      // debugger;
      this.spinner.hide()
      this.router.navigate(['my-quiver-account']);
    });
  }

  // <-----------Google End------------>


}
