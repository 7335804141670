import { Component, ElementRef, OnInit } from '@angular/core';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';
import { appConfig } from 'src/app/app.config';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-search-result-mobile',
  templateUrl: './search-result-mobile.component.html',
  styleUrls: ['./search-result-mobile.component.css']
})
export class SearchResultMobileComponent implements OnInit {
  hs = appConfig.hs;

  ulist: boolean = false;
  selected_product = null;
  sort: any = null;
  public searchType: string[] = ["Product Type", "Longboard", "Shoartboard", "Surfboard", "Fins"];
  public boardStatus: string[] = ["For sale", "Stolen", "Quivers"];
  public sortlist = [
    { id: 1, value: "Name" },
    { id: 2, value: "Price High to Low" },
    { id: 3, value: "Price Low to high" },
    { id: 4, value: "Distance" }
  ]
  filter: any = null;
  selectedsearchType = "Product Type";
  selectedsortlist = "Filter By";
  public boardlist;
  public boardlistTemp = [];
  public itemlist = 0;
  product: any = {};
  username: any = {};
  public userid;
  public ProductDetails;
  selectedheart: any = [];
  itemval: any = {};
  varyfietoken: any;
  likelistproduct: any = [];
  selectedlike: any = [];
  Whishlist: any;
  likeList: any;
  likeID: any = {};
  likeuser: any = [];
  totallike = [];
  Images_1: any;
  Images_2: any;
  Images_3: any;
  zoom: number;
  address: string;
  private geoCoder;
  latitude: any;
  longitude: any;

  constructor(private firewireservices: FireWireServices, private formBuilder: FormBuilder, private dataService: DataService, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private _route: ActivatedRoute, private router: Router,private mapsAPILoader: MapsAPILoader,public activatedRoute: ActivatedRoute) {
    this.userid = localStorage.getItem('userId');
    this.varyfietoken = localStorage.getItem('token');

    // this.activatedRoute.queryParams.subscribe(params => {
    //   debugger
    //   console.log(params)
    //   if (this.product != "") { 
    //     this.searchBoard();
    //   } else {
    //     this.product = ""
    //     this.ProductDetails = ""
    //   }
    // })
  }

  ngOnInit(): void {
    this.product = JSON.parse(localStorage.getItem('searchterm'))
    if (this.product != "") { 
           this.searchBoard();
           }
    // this.dataService.share
    //   .subscribe(
    //     data => {
    //       this.product = data;
    //       if (this.product != "") { 
    //         this.searchBoard();
    //       }
    //     }
    //   )
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder;
      });  }

  onSort(e) {

    if (this.sort == 1) { // name
      // debugger
      this.boardlist = this.boardlistTemp.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
    }
    if (this.sort == 2) { // high to low
      this.boardlist = this.boardlistTemp.sort((a, b) => Number(a.price) < Number(b.price) ? 1 : -1)

    }
    if (this.sort == 3) { //low to high
      this.boardlist = this.boardlistTemp.sort((a, b) => Number(a.price) > Number(b.price) ? 1 : -1)

    }
    if (this.sort == 4) { // distance

    }

  }

  onSelectFilter(e) {
    console.log('e', e)
    // if (e != "Product Type") {
    let tempBs = "";
    if (e == 'For sale') {
      tempBs = "1"
    }
    if (e == 'Stolen') {
      tempBs = "2"
    }
    if (e == 'Quivers') {
      tempBs = "3"
    }
    this.boardlist = this.boardlistTemp.filter(m => m.boardstatus?.toLowerCase() === tempBs.toLowerCase());
    this.itemlist = this.boardlist.length;
    // } else {
    //   this.boardlist = this.boardlistTemp;
    //   this.itemlist = this.boardlist.length;
    // }
  }

  searchBoard() {
    var data = {
      name: this.product.name
    }

    // this.spinner.show();
    this.firewireservices.getAllSearchProduct(data)
      .subscribe(
        response => {
          var boardtotal = response.data.result
          // console.log(boardtotal)
          this.itemlist = boardtotal.length;
          var data = response.data.result;
          if (data.length > 0) {
            this.ProductDetails = response.data.result

            for (var i = 0; i < data.length; i++) {
              if (data[i].images != '' && data[i].images != null) {
                data[i].image = appConfig.hs + data[i].images[0].image
              } else {
                data[i].image = 'assets/images/Picture2.png'

              }

              this.boardlist = data;
              this.boardlistTemp = data;
              // if (data[i].type === "fins") {
              //   this.finsProductList.push(data[i])
              // } else {
              //   this.productList.push(data[i])
              // }
            }
            // data.forEach(element => {
            //   if (element.image != '' && element.image != null) {
            //     // element.image = location.origin + '/' + element.image;
            //     element.image = "https://dhol.app/" +  element.image;
            //     // element.image = element.image;
            //   } else {
            //     element.image = 'assets/images/Picture2.png'
            //   }
            // })

            // this.boardlist = data;
            // this.boardlistTemp = data;
            this.getAllWishList();
            this.getAllikeList();
            this.getAlllikebyproduct();
            this.spinner.hide();
          } else {
            // this.toastr.error(response.data.message);
            this.spinner.hide();
          }
        },
        err => {
          this.spinner.hide();
        }
      )
  }

  SaleValueU(value) {
    var dataval = {
      value: value,

    }
    this.dataService.updateData(dataval)
    this.router.navigate(['user-product'])
  }

  getLikes(product) {
    this.ulist = true;
    this.selected_product = product
    console.log(this.selected_product)
  }

  closel() {
    this.ulist = false;
    this.selected_product = null
  }
  
  SaleValue(value) {
    var dataval = {
      value: value,
      ProductsDetails: this.ProductDetails
    }
    this.dataService.updateData(dataval)
    this.router.navigate(['sale-page'])
  }


  chagesicon(val, no) {
    // console.log(val,no)
    this.itemval = val
    this.selectedheart[no] = val
  }
  chageslikeicon(vals, id) {
    // this.likeID = itemval.id
    this.selectedlike[id] = vals
    if (this.selectedlike[id] == "no") {
      this.searchBoard();
    }
    // console.log(this.selectedlike[id]);
  }

  WishListFunction(vale, itemval) {
    var datawish = {
      product_id: vale.product_id,
      user_id: this.userid,
      name: vale.name,
      status: vale.status,
      image: vale.image,
      type: vale.type,
      opt: itemval,
      price: vale.price
    }
    this.spinner.show();
    this.firewireservices.AddWishList(datawish)
      .subscribe(
        response => {
          var data = response.data
          // console.log(data)
          // this.product(data)
          if (data.status == 1) {
            this.toastr.success(data.message);
            this.getAllikeList();
            this.getAlllikebyproduct();
            this.spinner.hide();
          } else {
            this.toastr.error(data.message);
            this.spinner.hide();
          }
        },
        err => {
          this.toastr.error('Add To WishList Unsuccessfully');
          this.spinner.hide();

        }
      )
  }


  removeItemFromWishlist(dataval, val) {
    // console.log("dataval",val)
    var data = {
      wish_id: dataval.wish_id,
    }
    this.spinner.show();
    this.firewireservices.removeItemFromWishlist(data)
      .subscribe(
        response => {
          var data = response.data;
          if (data.status == 1) {
            this.toastr.success(data.message);
            this.getAllikeList();
            this.getAlllikebyproduct();
            // this.getAllWishList();
            this.spinner.hide();
          } else {
            this.toastr.success(data.message);
          }
        },
        err => {
          this.spinner.hide();
          this.toastr.error('Records not Deleted');
        }
      )
  }

  productlike(val) {

    var data = {
      user_id: this.userid,
      product_user: val.user_id,
      product_id: val.product_id,
      name: val.name,
      status: val.status,
      type: val.type,
      price: val.price,
      icon: val.image
    }
    this.spinner.show();
    this.firewireservices.productlike(data)
      .subscribe(
        response => {
          var data = response.data
          if (data.status == 1) {
            // this.toastr.success(data.message);
            this.getAllikeList();
            this.getAlllikebyproduct();
            this.spinner.hide();
          } else {
            this.getAllikeList();
            this.getAlllikebyproduct();
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }


  getAllWishList() {
    var dataval = {
      user_id: JSON.parse(this.userid)
    }
    this.spinner.show();
    this.firewireservices.getAllWishlist(dataval)
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {

            data.forEach(element => {
              if (element.image != '' && element.image != null) {
                element.image = appConfig.hs + element.image;
              } else {
                element.image = 'assets/images/Picture2.png'
              }
            })
            this.Whishlist = data;
            this.spinner.hide();
          } else {
            console.log("not data found");
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  getAllikeList() {
    // var dataval = {
    //   user_id: this.userid
    // }
    this.spinner.show();
    this.firewireservices.getAllLikelist()
      .subscribe(
        response => {
          var data = response.data.result;
          console.log('data', data)
          if (data.length > 0) {

            this.likeList = data;
            this.boardlist.forEach(element => {
              var status = this.likeList.find(x => x.product_id === element.product_id && x.user_id === this.userid);
              if (status != undefined) {
                element.like = true;
              }
            });
            this.spinner.hide();
          } else {
            this.likelistproduct = [];
            console.log("not data found");
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }
  getAlllikebyproduct() {
    // var dataval = {
    //   user_id: JSON.parse(this.userid)
    // }
    this.spinner.show();
    this.firewireservices.getAlllikebyproduct()
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {

            data.forEach(element => {
              if (element.image != '' && element.image != null) {
                element.image = appConfig.hs + element.image;

              }
            })
            this.likelistproduct = data;
            var list = []
            var proImage = []
            this.boardlist.forEach(board => {
              var cnt = 0;
              var product;
              var img1;
              var img2;
              var img = [];
              this.likelistproduct.forEach(likes => {
                if (board.product_id != undefined && likes.product_id != undefined) {
                  if (board.product_id == likes.product_id) {
                    cnt = cnt + 1;
                    product = likes.product_id;
                    var iconImg = "assets/images/Picture2.png"
                    if (likes.image != undefined && likes.image != null && likes.image != '') {
                      img.push(likes.image);
                    } else {
                      img.push(iconImg)
                    }

                    // proImage.push(likes.product_id,likes.image);                                        
                  }
                }
              });
              var str = {
                product_id: product,
                image: img,
                totalLikes: cnt,
                //  proImage : proImage,

              }

              list.push(str)

            });
            this.likelistproduct = list

            // console.log("pro",proImage)
            console.log("likelistproduct", this.likelistproduct )

            // console.log(list) 

            let res = [];
            // list.map(function (item) {
            //   var existItem = res.find(x => x.product_id == item.product_id);
            //   if (existItem) {
            //  }    
            //   else {                
            //    res.push(item);                
            //     console.log(res)


            //   }    
            // });         



            this.spinner.hide();
          } else {
            this.likelistproduct = [];
            console.log("not data found");
            this.spinner.hide();
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  onSelect(val) {
    console.log(this.filter)
    console.log(this.selectedsearchType)
    let filterList = this.boardlistTemp;
    console.log("filterList", filterList)
    let tempBs = "";
    if (this.filter == 'For sale') {
      tempBs = "1"
    }
    if (this.filter == 'Stolen') {
      tempBs = "2"
    }
    if (this.filter == 'Quivers') {
      tempBs = "3"
    }
    if (this.filter != null && this.selectedsearchType != "Product Type") {
      // alert(1)
      this.boardlist = filterList.filter(m => m.type.toLowerCase() === this.selectedsearchType.toLowerCase() && m.boardstatus === tempBs);
    } else
      if (this.filter == null && this.selectedsearchType != "Product Type") {
        //  alert(2)
        this.boardlist = filterList.filter(m => m.type.toLowerCase() === this.selectedsearchType.toLowerCase());

      } else
        if (this.filter != null && this.selectedsearchType == "Product Type") {
          // alert(3)
          this.boardlist = filterList.filter(m => m.boardstatus === tempBs);

        }
        else {
          this.boardlist = this.boardlistTemp;
        }
    if (this.sort) {
      if (this.sort == 1) { // name
        this.boardlist = this.boardlist.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
      }
      if (this.sort == 2) { // high to low
        this.boardlist = this.boardlist.sort((a, b) => Number(a.price) < Number(b.price) ? 1 : -1)

      }
      if (this.sort == 3) { //low to high
        this.boardlist = this.boardlist.sort((a, b) => Number(a.price) > Number(b.price) ? 1 : -1)

      }
      if (this.sort == 4) { // distance
        // this.boardlist = this.boardlist.sort((a, b) => Number(a.lat) > Number(b.lat) ? 1 : -1)
        let temp = []
        // debugger
        for (var i = 0; i < this.boardlist.length; i++) {
          console.log(this.distance(this.latitude, this.longitude, this.boardlist[i].lat, this.boardlist[i].lng, "K"))
      // temp.push    (this.distance(this.latitude, this.longitude, this.boardlist[i].lat, this.boardlist[i].lng, "K") <= 50)
          // if this location is within 0.1KM of the user, add it to the list
          if (this.distance(this.latitude, this.longitude, this.boardlist[i].lat, this.boardlist[i].lng, "K") <= 10) {
              temp.push (this.boardlist[i])
          }
      }
      
      this.boardlist = temp;
      }
      console.log('this.boardlist', this.boardlist)
    }


    this.itemlist = this.boardlist.length;

    //  let filterList = []

    //   if (val != "Product Type") {
    //     filterList = this.boardlistTemp.filter(m => m.type.toLowerCase() === val.toLowerCase());
    //     this.itemlist = filterList.length;
    //   } else {
    //     filterList = this.boardlistTemp;
    //     this.itemlist = filterList.length;
    //   } 
    //   // filterList = this.boardlistTemp.filter(m => m.boardstatus?.toLowerCase() === tempBs.toLowerCase());
    //   // this.itemlist = this.boardlist.length;

    //   this.boardlist = filterList
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        console.log(this.latitude)
        console.log(this.longitude)
        this.zoom = 8;
        // this.getAddress(this.latitude, this.longitude);
        // this.getstoreList();
      });
    }
  }

  
   distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }else{
      var radlat1 = Math.PI * lat1/180
      var radlat2 = Math.PI * lat2/180
      var theta = lon1-lon2
      var radtheta = Math.PI * theta/180
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist)
      dist = dist * 180/Math.PI
      dist = dist * 60 * 1.1515
      if (unit=="K") { dist = dist * 1.609344 }
      if (unit=="N") { dist = dist * 0.8684 }
      return dist
    }
    
  }
    
  

  // getAddress(latitude, longitude) {
  //   this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
  //     if (status === 'OK') {
  //       if (results[0]) {
  //         this.zoom = 12;
  //         this.address = results[0].formatted_address;
  //       } else {
  //         window.alert('No results found');
  //       }
  //     } else {
  //       window.alert('Geocoder failed due to: ' + status);
  //     }

  //   });
  // }
}