import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from 'src/app/app.config';
import { DataService } from 'src/app/services/data.service';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css']
})
export class TransactionComponent implements OnInit {
  transactionlist : any = [];
  trasaction : any = {};
  userid;
  constructor(private firewireservices: FireWireServices, private dataService: DataService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private _route: ActivatedRoute, private router: Router) { 
      this.userid = localStorage.getItem('userId');
      this.getAllTransaction();
    }

  ngOnInit(): void {
  }

  getAllTransaction() {
    var data = {
      user_id: JSON.parse(this.userid)
    }
    this.spinner.show();
    this.firewireservices.getTransactionByID(data)
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {        
            // data.forEach(element => {
            //   if (element.image != '' && element.image != null) {
            //     element.image = location.origin + '/' + element.image;
            //     // element.image = "https://dhol.app/" + element.image;
            //   } else {
            //     element.image = 'assets/images/Picture2.png'
            //   }
            // })      
            data.forEach(element => {
              if (element.image != '' && element.image != null) {
                element.image = appConfig.hs + element.image;
                // element.image = "https://dhol.app/" + element.image;
              } else {
                // element.image = 'assets/images/Picture2.png'
              }
            })       
            this.transactionlist = data; 
            
            // console.log(this.transactionlist)
            this.spinner.hide();          
          }else{
            this.transactionlist = []
            this.spinner.hide();          

          }
       
        },
        err =>{
          console.log("error");
          this.spinner.hide(); 
        }
      )
  }
  openTransactionModal(data){
    this.trasaction = data;
  }

}
