import { Component, OnInit } from '@angular/core';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { DataService } from 'src/app/services/data.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from 'src/app/app.config';


@Component({
  selector: 'app-sale-page',
  templateUrl: './sale-page.component.html',
  styleUrls: ['./sale-page.component.css']
})
export class SalePageComponent implements OnInit {
  // public slider = new Slider();
  saledata: any = {};
  alldata: any = []
  userid;
  public coloroptions = [];
  public selectedColor: any;
  public systemoptions: string[] = ["Five Fin - Futures", "Five Fin - FCS"];
  selectedSystem;
  public sizeoptions: string[] = [];
  public previewUrl = null
  public cartlist: any = []

  public userlist: any = {};

  dataproduct: any = [];

  public selectedSize = [];
  ulist: boolean = false;
  selected_product = null;
  product: any = {};
  dropproducts: any = {}
  public ShippingValues: any = "null";
  ShippingfixValues: any = "null";
  pro_userid: any;
  sellerName: any = {};
  constructor(private firewireservices: FireWireServices, private dataService: DataService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private _route: ActivatedRoute, private router: Router) {
    this.userid = localStorage.getItem('userId');


    // this.slider.config.loop = true;
    //   this.slider.config.showPreview = false;+++++
  }

  ngOnInit(): void {
    this.dataService.share
      .subscribe(
        data => {
          var dataval: any = data
          if (dataval.value != null || dataval.value != undefined) {
            var productID = dataval.value.product_id;
            var userID = dataval.value.user_id;
          }

          // console.log(this.saledata) 
          // this.dataproduct = dataval.ProductsDetails       
          // if(this.saledata.fin_system != undefined || this.saledata.fin_system != null){
          //   this.selectedSystem = this.saledata.fin_system; 
          // }

          if (productID != null || productID != undefined) {
            localStorage.setItem('ProductID', productID)
          }
          if (userID != null || userID != undefined) {
            localStorage.setItem('Product_userID', userID)
          }
          // if(this.saledata.color != undefined || this.saledata.color != null){
          //   var color = this.saledata.color.replace(/"/g, '');
          //   // console.log(color)
          //   this.coloroptions = color.split(",");
          // }
          // if(this.saledata.size != undefined || this.saledata.size != null){
          //   var sizeopt = this.saledata.size.replace(/"/g, '');

          //   this.sizeoptions = sizeopt.split(",");
          // } 

          // this.ProductDetailsStoreLocalStrorage();


          // this.getcartProductByUserID(this.saledata.user_id);

          this.getUserByUserID(this.saledata.user_id);

        }
      )


    this.getProductImages();
    this.getAllProductByID();

    this.getUserbyID()
  }

  goToProfile(){
  //  this.router.navigate(['user-product'])
   this.router.navigate(['/user-product'], { queryParams: { user_id: this.saledata.user_id } });

  }

  getAllProductByID() {
    var ProductID = localStorage.getItem('ProductID');
    var vals = {
      product_id: ProductID
    }
    this.spinner.show();
    this.firewireservices.getProductByID(vals)
      .subscribe(
        response => {
          if (response.data.result.length > 0) {
            this.dataproduct = response.data.result
            this.saledata = response.data.result[0];
            if (this.saledata.fixrate !== "null" && this.saledata.fixrate != "undefined") {
              this.ShippingfixValues = this.saledata.fixrate;
            }
            if (this.saledata.pic_up != "null" && this.saledata.pic_up != null && this.saledata.pic_up != "undefined") {
              this.ShippingValues = this.saledata.pic_up;
              // console.log(this.ShippingValues);
            }


            var token = localStorage.getItem('token');
            var verifytoken = JSON.stringify(token);

            if (verifytoken !== null) {
              this.pro_userid = this.saledata.user_id;
              // console.log(this.pro_userid)
            }
            // console.log(this.ShippingfixValues) 
            // console.log("ShippingValues",this.ShippingValues) 
            if (this.saledata.color != undefined || this.saledata.color != null) {
              var color = this.saledata.color.replace(/"/g, '');
              // console.log(color)
              var colorval = color.split(",");

              var ins = []
              colorval.forEach(element => {
                var str = { label: element, value: element }
                ins.push(str)
              });
              this.coloroptions = ins;
            }
            if (this.saledata.size != undefined || this.saledata.size != null) {
              var sizeopt = this.saledata.size;
              var sizeval = sizeopt.split(",");

              var ins = []
              sizeval.forEach(element => {
                var str = { label: element, value: element }
                ins.push(str)
              });
              this.selectedSize = ins;
            }
            if (this.saledata.fin_system != undefined || this.saledata.fin_system != null) {
              this.selectedSystem = this.saledata.fin_system;
            }
            if (this.saledata.fixrate !== "null" || this.saledata.fixrate != "" || this.saledata.fixrate != "undefined") {
              this.product.fixrat = this.saledata.fixrate;
            }
            if (this.saledata.pic_up !== "null" || this.saledata.pic_up != "" || this.saledata.pic_up != "undefined") {
              this.ShippingValues = this.saledata.pic_up;
              // this.product.shippingType = "1"
              this.product.pic_up = this.saledata.pic_up;
            }
            this.spinner.hide();
          } else {
            console.log("not data found");
            this.spinner.hide();
          }
          // console.log("saledata",this.saledata);
        },
      )
  }

  public imageList = [];
  img: any = {};
  public isImg;
  getProductImages() {
    var ProductID = localStorage.getItem('ProductID');
    var id = {
      product_id: ProductID
    }
    this.spinner.show();
    this.firewireservices.getProductImagesByProduct_id(id)
      .subscribe(
        response => {

          var imgList = response.data.result;
          var str;
          var list = []

          if (response.data.status != 0) {
            imgList.forEach(element => {
              str = { path: appConfig.hs + element.image }
              list.push(str)
            });
            this.isImg = 1;
          } else {
            this.img = "assets/images/Picture2.png"
            this.isImg = 0;

          }

          // this.imageList = list;
          const slideItems = [
            { path: 'assets/images/1.png', title: 'Title 1' },
            { path: 'assets/images/a.jpg', title: 'Title 2' },
          ];

          this.imageList = list;
          // console.log(this.slider.items)
          this.spinner.hide();
          // console.log("cartlist",this.cartlist)

        },

      )

  }

  public sellerDetails = [];
  sellerData: any = {};
  public isSellerImg;
  salImg: any = []


  getUserbyID() {
    var userIDProduct = localStorage.getItem('Product_userID');
    var id = {
      user_id: JSON.parse(userIDProduct)
    }
    this.spinner.show();
    this.firewireservices.getUsersByID(id)
      .subscribe(
        response => {

          var userList = response.data.result;
          console.log(userList)
          this.sellerName = response.data.result[0].first_name;
          var str;
          var list = []
          if (response.data.status != 0) {
            userList.forEach(element => {
              if (element.image != null) {
                str = { path: appConfig.hs + element.image,
                   first_name: element.first_name,
                   user_id: element.user_id,
                   fcm_token: element.fcm_token,
                   paypal_key: element.paypal_key
                }
              }
              else {
                str = { path: "assets/images/Picture2.png", first_name: element.first_name }
              }
              list.push(str)
            });
            // console.log(str)
            this.sellerData = str;
            localStorage.setItem('sellerData', JSON.stringify(this.sellerData))
            this.spinner.hide();
          } else {
            console.log('No Data Found');
            this.spinner.hide();
          }
          // console.log(this.slider.items)

          // console.log("cartlist",this.cartlist)

        },

      )

  }


  MessageFumctoion() {
    var messagetoken = localStorage.getItem('token');
    var image;
    if(this.saledata.images.length  == 0){
      image = null
    }else{
     
      image = this.saledata.images[0].image
    }
    if (messagetoken != null || messagetoken != undefined) {
      var data = {
        activestatus: this.saledata.activestatus,
        brand_name: this.saledata.brand_name,
        color: this.selectedColor,
        created: this.saledata.created,
        designtype: this.saledata.designtype,
        fin_system: this.saledata.fin_system,
        fixrate: this.saledata.fixrate,
        image: image,
        location: this.saledata.location,
        name: this.saledata.name,
        owner: this.saledata.owner,
        pic_up: this.saledata.pic_up,
        price: this.saledata.price,
        pro_id: this.saledata.pro_id,
        product_id: this.saledata.product_id,
        size: this.selectedSize,
        status: this.saledata.status,
        technology: this.saledata.technology,
        type: this.saledata.type,
        tax: this.saledata.tax,
        updated: this.saledata.updated,
        user_id: this.saledata.user_id,
        qty: "1"
      }
      this.dataService.updateData(data)
      // console.log(data)
      this.router.navigate(['message-page'])
    } else {
      this.router.navigate(['login'])
    }
  }


  getUserByUserID(userid) {
    // this.userid = localStorage.getItem('userId');
    var vals = {
      user_id: userid
    }
    this.firewireservices.getUsersByID(vals)
      .subscribe(
        response => {
          if (response.data.result.length > 0) {
            this.userlist = response.data.result[0];
            // console.log("userlist",this.userlist)
            this.spinner.hide();
          } else {
            console.log("not data found");
          }

          // console.log("cartlist",this.cartlist)

        },

      )
  }
  // getcartProductByUserID(userid) {
  //   // this.userid = localStorage.getItem('userId');
  //   var vals = {
  //     user_id:userid
  //   }
  //   this.firewireservices.getcartProductByUserID(vals)
  //     .subscribe(
  //       response => {
  //         if(response.data.result.length > 0)
  //         {
  //           this.cartlist = response.data.result;
  //           this.spinner.hide();
  //         }else{
  //           console.log("not data found");
  //         }

  //         // console.log("cartlist",this.cartlist)

  //       },

  //     )
  // }

  purchase() {
    // this.router.navigate(['paypal_page'])

    console.log(this.dataproduct)   
    if (this.saledata.color != undefined && this.saledata.size != undefined) {
      var image;
      if(this.saledata.images.length  == 0){
        image = null
      }else{
       
        image = this.saledata.images[0].image
      }

      this.cartlist = localStorage.getItem('Product_Details');
      var dataval = JSON.parse(this.cartlist);
      if (dataval == null) {

        var val = {
          product_id: this.saledata.product_id,
          seller_name: this.sellerName,
          user_id: this.userid,
          name: this.saledata.name,
          address: this.saledata.address,
          product_type: this.saledata.type,
          fin_system: this.saledata.fin_system,
          color: this.saledata.color,
          size: this.saledata.size,
          technology: this.saledata.technology,
          price: this.saledata.price,
          calPrice:this.saledata.price,

          image :image,
          tax: this.saledata.tax,
          fixrate: this.saledata.fixrate,
          pic_up: this.saledata.pic_up,
          status: this.saledata.status,
          qty: "1",

        }
        var pro_list = []
        pro_list.push(val)
        this.spinner.show();

        localStorage.setItem('Product_Details', JSON.stringify(pro_list));
        this.router.navigate(['cart-page'])
        // this.firewireservices.addtocart(val)
        //   .subscribe(
        //     response => {
        //       console.log(response.data);
        //       if(response.data.status == 1)
        //       {
        //         this.toastr.success(response.data.message);
        //         this.router.navigate(['cart-page'])
        //         // this.getcartProductByUserID();
        //         this.spinner.hide();
        //       }else{
        //         console.log("error");
        //       }

        //     },
        //     err => {
        //       this.toastr.error('Add To Cart Unsuccessfully');
        //       this.spinner.hide();
        //     }
        //   )

      } else {
        if (this.saledata.product_id == dataval[0].product_id) {
          var localdata = JSON.parse(localStorage.getItem('Product_Details'));
          
          var calQnt = Number(localdata[0].qty) + 1;
          // var calPrice = Number(localdata[0].price)
          var val1 = {
            product_id: this.saledata.product_id,
            seller_name: this.sellerName,
            user_id: this.userid,
            name: this.saledata.name,
            address: this.saledata.address,
            product_type: this.saledata.type,
            fin_system: this.saledata.fin_system,
            color: this.saledata.color,
            size: this.saledata.size,
            technology: this.saledata.technology,
            price: this.saledata.price,
            calPrice:this.saledata.price,
            image : image,
            tax: this.saledata.tax,
            fixrate: this.saledata.fixrate,
            pic_up: this.saledata.pic_up,
            status: this.saledata.status,
            qty:calQnt
          }
          var pro_list = []
          pro_list.push(val1)
          this.spinner.show();
          localStorage.setItem('Product_Details', JSON.stringify(pro_list));
          this.router.navigate(['cart-page'])
        } else {
          this.toastr.error('One Product Alerdy Exist in Cart');
        }
      }
    }
  }

  purchaseProduct() {
    console.log(this.dataproduct)   
    if (this.saledata.color != undefined && this.saledata.size != undefined) {
      var image;
      if(this.saledata.images.length  == 0){
        image = null
      }else{
       
        image = this.saledata.images[0].image
      }

      var val = {
        product_id: this.saledata.product_id,
        seller_name: this.sellerName,
        user_id: this.userid,
        name: this.saledata.name,
        address: this.saledata.address,
        product_type: this.saledata.type,
        fin_system: this.saledata.fin_system,
        color: this.saledata.color,
        size: this.saledata.size,
        technology: this.saledata.technology,
        price: this.saledata.price,
        calPrice:this.saledata.price,
        reserve_amount: this.saledata.reserve_amount,
        image :image,
        tax: this.saledata.tax,
        fixrate: this.saledata.fixrate,
        pic_up: this.saledata.pic_up,
        status: this.saledata.status,
        qty: "1",

      }
      console.log('val', val)
      // var pro_list = []
      // pro_list.push(val)
      this.spinner.show();
      localStorage.setItem('purchaseData', JSON.stringify(val));
      this.router.navigate(['purchase'])
    }
   
  }

  reservePurchase() {
    console.log(this.dataproduct)   
    if (this.saledata.color != undefined && this.saledata.size != undefined) {
      var image;
      if(this.saledata.images.length  == 0){
        image = null
      }else{
       
        image = this.saledata.images[0].image
      }
      let checkCart = JSON.parse(localStorage.getItem('Product_Details'))
      if(checkCart){
        console.log('in if')
        this.toastr.error('One Product Alerdy Exist in Cart');
      }else{
        console.log('in else')
        var val = {
          product_id: this.saledata.product_id,
          seller_name: this.sellerName,
          user_id: this.userid,
          name: this.saledata.name,
          address: this.saledata.address,
          product_type: this.saledata.type,
          fin_system: this.saledata.fin_system,
          color: this.saledata.color,
          size: this.saledata.size,
          technology: this.saledata.technology,
          price: this.saledata.price,
          calPrice:this.saledata.price,
          reserve_amount: this.saledata.reserve_amount,
          image :image,
          tax: this.saledata.tax,
          fixrate: this.saledata.fixrate,
          pic_up: this.saledata.pic_up,
          status: this.saledata.status,
          qty: "1",
  
        }
        console.log('val', val)
        // var pro_list = []
        // pro_list.push(val)
        this.spinner.show();
  
        localStorage.setItem('Product_reserve', JSON.stringify(val));
        this.router.navigate(['paypal-page'])
      }
     
    }
  }

  // getAllProductById() {
  //   var data = {
  //     product_id :  this.product.product_id
  //   }
  //   this.spinner.show();
  //   this.firewireservices.getProductImagesByProduct_id(data)
  //     .subscribe(
  //       response => {
  //         if (response.data.status == 1) {
  //           var data = response.data.result;
  //           var datamsg = response.data
  //           if (data.length > 0) {
  //             data.forEach(element => {
  //               if (element.image != '' && element.image != null) {                  
  //                 element.image = "https://dhol.app" + '/' + element.image;
  //               } else {
  //                 element.image = 'assets/images/Picture2.png'
  //               }
  //             });
  //             this.productList = data;
  //             console.log(this.productList);
  //             this.spinner.hide();
  //           } else {
  //             console.log(datamsg.message)
  //             this.spinner.hide();
  //           }
  //         }
  //       },
  //       err => {
  //         this.spinner.hide();

  //       }
  //     )

  // }

  // getAllProductDetailsById() {
  //   var data = {
  //     product_id :  this.product.product_id
  //   }

  //   this.firewireservices.getProductByID(data)
  //     .subscribe(
  //       response => {
  //         if (response.data.status == 1) {
  //           var data = response.data.result;
  //           var datamsg = response.data
  //           if (data.length > 0) {
  //              this.productList = data;
  //              var color = [];
  //              var size = [];
  //              data.forEach(element => {
  //                color.push(element.color);
  //               size.push(element.size);
  //              });
  //              this.sizeoptions = size;
  //              this.coloroptions = color;
  //             console.log(this.productList);
  //             this.spinner.hide();
  //           } else {
  //             console.log(datamsg.message)
  //             this.spinner.hide();
  //           }
  //         }
  //       },
  //       err => {
  //         this.spinner.hide();

  //       }
  //     )

  // }

  closel() {
    this.ulist = false;
  }
}
