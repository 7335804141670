import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-board',
  templateUrl: './about-board.component.html',
  styleUrls: ['./about-board.component.css']
})
export class AboutBoardComponent implements OnInit {
  public userid = null;

  private coloroptions: string[] = ["black","blue","White"];
  selectedColor = "White";
  private systemoptions: string[] = ["Five Fin - Futures","Five Fin - FCS"];
  selectedSystem = "Five Fin - Futures";
  private sizeoptions: string[] = ["5'7 x 18 7/8 x 2 3/8 - 27.5L"];
  selectedSize = "5'7 x 18 7/8 x 2 3/8 - 27.5L";
  private technoptions  : string[] = ["Linear Flex Technology","Helium","Timber Tek","Artisans","Harley Ingleby Series"];
  selectTechnology = "Linear Flex Technology";
  constructor() { 
    this.userid = localStorage.getItem('userId');
  }

  ngOnInit(): void {
  }

}
