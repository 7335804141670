import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from 'src/app/app.config';
import { DataService } from 'src/app/services/data.service';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-paypal-page',
  templateUrl: './paypal-page.component.html',
  styleUrls: ['./paypal-page.component.css']
})
export class PaypalPageComponent implements OnInit {

  public paypal: any = {}
  public userid;
  loggedInUser: any;
  public users: any = {};
  public productId: any;

  // --------------Paypal---------------
  
  public payPalConfig?: IPayPalConfig;
  public subtotal: any = 0;
  public grandtotal: any = 0;
  public Tax: any = 0;
  public val: any = 0;
  payData: any = {}
  usernameid: any = {};

  constructor(private firewireservices: FireWireServices, private dataService: DataService,
    private formBuilder: FormBuilder, private _route: ActivatedRoute, private router: Router, private toastr: ToastrService,
    private spinner: NgxSpinnerService) {

    this.userid = localStorage.getItem('userId');
    this.productId = localStorage.getItem('ProductID')
    this.loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))
    this.usernameid = JSON.parse(localStorage.getItem('sellerData'))

    this.userById();
    this.getUserProfile();
  }


  ngOnInit(): void {
  }

  reserve() {
    localStorage.setItem('buyerData', this.paypal.paypal_key)
    this.router.navigate(['reserve-payment-page'])
    // var valdata = {
    //   user_id: this.loggedInUser.user_id,
    //   product_id: this.productId,
    //   status: 1
    // }
    // console.log(valdata)
    // this.spinner.show();
    // this.firewireservices.reserveBoard(valdata)
    //   .subscribe(
    //     response => {
    //       if (response.data.status == 1) {
    //         // this.initConfig();
    //         this.spinner.hide();
    //       } else {
    //         this.spinner.hide();
    //       }
    //     },
    //     err => {
    //       this.spinner.hide();
    //     }
    //   )
  }

  getUserProfile() {
    var data = {
      user_id: JSON.parse(this.userid)
    }
    this.firewireservices.getUsersByID(data)
      .subscribe(
        response => {
          var data = response.data;
          this.editcliendataResponse(data)
        }
      )
  }

  editcliendataResponse(data) {
    this.paypal.paypal_key = data.result[0].paypal_key;
  }

  userById() {
    var valdata = {
      user_id: this.loggedInUser.user_id
    }
    console.log(valdata)
    this.spinner.show();
    this.firewireservices.getUsersByID(valdata)
      .subscribe(
        response => {
          this.users = response.data.result[0]
          console.log(this.users)
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
        }
      )
  }


  // Paypal Account
  // --------------------------------
  // private initConfig(): void {
  //   this.payPalConfig = {
  //     currency: 'USD',
  //     clientId: 'sb',
  //     createOrderOnClient: (data) => <ICreateOrderRequest>{
  //       intent: 'CAPTURE',
  //       purchase_units: [
  //         {
  //           amount: {
  //             currency_code: 'USD',
  //             value: this.payData,
  //             breakdown: {
  //               item_total: {
  //                 currency_code: 'USD',
  //                 value: this.payData
  //               }
  //             }
  //           },
  //           items: [
  //             {
  //               name: 'Enterprise Subscription',
  //               quantity: '1',
  //               category: 'DIGITAL_GOODS',
  //               unit_amount: {
  //                 currency_code: 'USD',
  //                 value: this.payData,
  //               },
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     advanced: {
  //       commit: 'true'
  //     },
  //     style: {
  //       label: 'paypal',
  //       layout: 'vertical'
  //     },

  //     onApprove: (data, actions) => {
  //       // console.log('onApprove - transaction was approved, but not authorized', data, actions);
  //       // console.log(data)
  //       actions.order.get().then(details => {
  //         // console.log('onApprove - you can get full order details inside onApprove: ', details);
  //         // var paymenttoken = localStorage.getItem('token');
  //         // if (paymenttoken != null) {
  //         if (details.status == 'APPROVED') {
  //           // document.getElementById("openModalsubUserButton").click();
  //           // console.log(details)
  //           var dataget = {
  //             orderID: data.orderID,
  //             user_id: this.userid,
  //             paypal_reference_id: details.id,
  //             sub_total: this.subtotal,
  //             shipping: this.val,
  //             tax: this.Tax,
  //             total: this.grandtotal,
  //             created: new Date()
  //           };
  //           // c9onsole.log(dataget)
  //           this.firewireservices.buyPackage(dataget).subscribe(
  //             (response) => {
  //               // this.AddTransaction();
  //               this.router.navigate(['thankyou'])
  //             },
  //             (error) => {
  //               console.log(error);
  //             }
  //           );
  //         } else {

  //           this.toastr.error('Payment Declined')

  //         }
  //         // }else{
  //         //   var dataurl = '/payment';
  //         //   this.dataService.updateData(dataurl)
  //         //   this.router.navigate(['login']);
  //         // }
  //       });
  //     },
  //     onClientAuthorization: (data) => {
  //       // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
  //       // this.showSuccess = true;
  //     },
  //     onCancel: (data, actions) => {
  //       // console.log('OnCancel', data, actions);
  //     },
  //     onError: err => {
  //       // console.log('OnError', err);
  //     },
  //     onClick: (data, actions) => {
  //       // console.log('onClick', data, actions);
  //     },
  //   };
  // }


}
