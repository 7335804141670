import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {
  TransactionID : any;
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.share
      .subscribe(
        data => {     
          if(data !=null)    
          {
            var dataval : any = data
            if(dataval.value !=undefined)
            {
              this.TransactionID = dataval.value.transaction_id; 
            }            
          }          
           
        });
  }

}
