import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { appConfig } from 'src/app/app.config';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-how-to',
  templateUrl: './how-to.component.html',
  styleUrls: ['./how-to.component.css']
})
export class HowToComponent implements OnInit {

  videoList = [];
  searchText: any;
  public video : any = {}
  
  constructor(public firewireservices: FireWireServices,
    public spinner: NgxSpinnerService, public router: Router) {
    this.getAllVideo();
  }

  ngOnInit(): void {
  }

  getAllVideo() {
    this.spinner.show();
    this.firewireservices.getAllVideo()
      .subscribe(
        response => {
          if (response.data.status == 1) {
            this.videoList = response.data.result;
            this.videoList.forEach(element => {
              element.video_path = appConfig.hs + element.video_path
            });
          } else {
            this.videoList = [];
          }
          this.spinner.hide();
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )
  }

}
