import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services';
import { DataService } from 'src/app/services/data.service';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  returnUrl: string; 
  social : any;

  userid = localStorage.getItem("userid");
  facebookid= JSON.parse(localStorage.getItem("Facebook_ID"));
   
  googleid= JSON.parse(localStorage.getItem("Google_ID"));

  constructor(private userService: UserService,private toastr: ToastrService,private firewireservices:FireWireServices,private dataService: DataService,private route:ActivatedRoute,private spinner: NgxSpinnerService, private authService: AuthService, private _router: Router, private AuthService: AuthService) { }

  // ngOnInit() {
  //   localStorage.removeItem('token');
  //   localStorage.clear();
  //   this._router.navigate(['/scan']);
  
  // }

  ngOnInit() {
    // this.signOut();
    
    // sessionStorage.removeItem('token');
    // sessionStorage.clear();
    // this._router.navigate(['login']);
    if(this.googleid !="" && this.googleid !=null){
      // alert("google")
    this.authService.signOut();  
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    localStorage.removeItem('redirectUrl');
    localStorage.removeItem('userProduct');
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('chatTo');
    localStorage.removeItem('sellerData');
    localStorage.removeItem('Product_userID');
    localStorage.removeItem('subject_name');
    localStorage.removeItem('recid');
    localStorage.removeItem('id');
    localStorage.removeItem('searchterm');
    localStorage.removeItem('msgtbl_id');
    localStorage.removeItem('ProductID');
    localStorage.removeItem('first_name');
    localStorage.removeItem('current_user');
    // localStorage.removeItem('Product_Details');
    localStorage.removeItem('__paypal_storage__');
    localStorage.removeItem('messageData');
    localStorage.removeItem('cartTotal');
    localStorage.removeItem('paymentData');
    localStorage.removeItem('checkout');
    localStorage.removeItem('Product_reserve');
    localStorage.removeItem('checkout-mobile');
    
    
    
      // localStorage.clear();
      this._router.navigate(['/mainpage']);
    // this.signOut();
    
  }else if(this.facebookid !="" && this.facebookid !=null){
    // alert("facebook")
    this.authService.signOut();  
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    localStorage.removeItem('redirectUrl');
    localStorage.removeItem('userProduct');
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('chatTo');
    localStorage.removeItem('sellerData');
    localStorage.removeItem('Product_userID');
    localStorage.removeItem('subject_name');
    localStorage.removeItem('recid');
    localStorage.removeItem('id');
    localStorage.removeItem('searchterm');
    localStorage.removeItem('msgtbl_id');
    localStorage.removeItem('ProductID');
    localStorage.removeItem('first_name');
    localStorage.removeItem('current_user');
    // localStorage.removeItem('Product_Details');
    localStorage.removeItem('__paypal_storage__');
    localStorage.removeItem('messageData');
    localStorage.removeItem('cartTotal');
    localStorage.removeItem('paymentData');
    localStorage.removeItem('checkout');
    localStorage.removeItem('Product_reserve');
    localStorage.removeItem('checkout-mobile');
      // localStorage.clear();
      this._router.navigate(['/mainpage']);
  }else{
    // alert("simple")
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userType');
    localStorage.removeItem('redirectUrl');
    localStorage.removeItem('userProduct');
    localStorage.removeItem('loggedInUser');
    localStorage.removeItem('name');
    localStorage.removeItem('chatTo');
    localStorage.removeItem('sellerData');
    localStorage.removeItem('Product_userID');
    localStorage.removeItem('subject_name');
    localStorage.removeItem('recid');
    localStorage.removeItem('id');
    localStorage.removeItem('searchterm');
    localStorage.removeItem('msgtbl_id');
    localStorage.removeItem('ProductID');
    localStorage.removeItem('first_name');
    localStorage.removeItem('current_user');
    // localStorage.removeItem('Product_Details');
    localStorage.removeItem('__paypal_storage__');
    localStorage.removeItem('messageData');
    localStorage.removeItem('cartTotal');
    localStorage.removeItem('paymentData');
    localStorage.removeItem('checkout');
    localStorage.removeItem('Product_reserve');
    localStorage.removeItem('checkout-mobile');

      this._router.navigate(['/mainpage']);
      
    // console.log(this.userid)
 }
  }
  signOut(): void {
    this.authService.signOut();
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('userId');
      localStorage.removeItem('userType');
      localStorage.removeItem('redirectUrl');
      localStorage.removeItem('userProduct');
      localStorage.removeItem('loggedInUser');
      localStorage.removeItem('chatTo');
      localStorage.removeItem('sellerData');
      localStorage.removeItem('Product_userID');
      localStorage.removeItem('subject_name');
      localStorage.removeItem('recid');
      localStorage.removeItem('id');
      localStorage.removeItem('searchterm');
      localStorage.removeItem('msgtbl_id');
      localStorage.removeItem('ProductID');
      localStorage.removeItem('first_name');
      localStorage.removeItem('current_user');
      // localStorage.removeItem('Product_Details');
      localStorage.removeItem('__paypal_storage__');
      localStorage.removeItem('messageData');
      localStorage.removeItem('cartTotal');
      localStorage.removeItem('paymentData');
      localStorage.removeItem('checkout');
      localStorage.removeItem('Product_reserve');
      localStorage.removeItem('checkout-mobile');
      
      // localStorage.clear();
      this._router.navigate(['/mainpage']);

  }

        // <-----------Facebook------------>

        signInWithFB(): void {
          // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => console.log(x));
          let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
          this.AuthService
            .signIn(FacebookLoginProvider.PROVIDER_ID)
            .then((userData) => {
              //this will return user data from facebook. What you need is a user token which you will send it to the server
              // console.log("user facebook",userData.photoUrl)
              var path = userData.photoUrl;
              // sessionStorage.setItem("socialimg",JSON.stringify(path));
              this.SavesresponseFacebook(userData);
              // this._router.navigate(['dashboard']);
              // console.log(userData)
            });
        }
        SavesresponseFacebook(userData) {
          var data = {
            first_name: userData.firstName,
            last_name: userData.lastName,
            email: userData.email,
            facebook_id: userData.id,
            image: userData.photoUrl,
      
          }
          this.spinner.show()
          this.userService.socialRegister(data)
            .subscribe(
              data => {
                var custdetails = data;
                this.socialLoginFacebook(userData.id)
              },
              error => {
                console.log(error);
              });
          // console.log(data)
        }
      
        socialLoginFacebook(userid) {
          var userSet = {
            facebook_id: userid,
            provider: 'facebook'
          };
      
          this.userService.social_login(userSet).subscribe((res: any) => {
            this.spinner.hide()
            // debugger;
            this._router.navigate(['my-quiver-account']);
          });
        }
      
        // <-----------Facebook  End------------>
      
      
      
      
        // <-----------Google------------>
      
      
        signInWithGoogle(): void {
          // alert()
          // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => console.log(x));
      
          let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
          this.AuthService
            .signIn(GoogleLoginProvider.PROVIDER_ID)
            .then((userData) => {
              // var path = userData.photoUrl;
              // sessionStorage.setItem("socialimg",JSON.stringify(path));
              //this will return user data from facebook. What you need is a user token which you will send it to the server
              this.SavesresponseGoogle(userData);
              // this._router.navigate(['dashboard']);
              // console.log(userData)
            });
        }
        SavesresponseGoogle(userData) {
          var data = {
            first_name: userData.firstName,
            last_name: userData.lastName,
            email: userData.email,
            google_id: userData.id,
            image: userData.photoUrl,
          }
      
          this.spinner.show()
      
          this.userService.socialRegister(data)
            .subscribe(
              data => {
                var custdetails = data;
                this.socialLoginGoogle(userData.id)
              },
              error => {
                console.log(error);
              });
        }
        socialLoginGoogle(userid) {
          var userSet = {
            google_id: userid,
            provider: 'google'
          };
          this.userService.social_login(userSet).subscribe((res: any) => {
            // debugger;
            this.spinner.hide()
            this._router.navigate(['my-quiver-account']);
          });
        }
      
        // <-----------Google End------------>
}
