import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  userid;
  footerlist;

  shopUrl : any ;
  WireUrl : any ;
  twitterUrl : any ;
  instagramUrl : any ;
  facebookUrl : any;
  constructor(private firewireservices: FireWireServices, private dataService: DataService, private formBuilder: FormBuilder, private _route: ActivatedRoute, private router: Router, private toastr: ToastrService, private spinner: NgxSpinnerService) {
    this.userid = localStorage.getItem('userId');
    this.getAllFooterURL();
    
  }
  

  ngOnInit(): void {
  }

    getAllFooterURL() {   
      this.spinner.show();
    this.firewireservices.getFooterData()
      .subscribe(
        response => {
          if(response.data.result.length == 1)
          // if(response.data.result.length != 0)

          // if(response.data.result.length != 0)
          {
           
              this.shopUrl = response.data.result[0].shopURL;
              // console.log(this.shopUrl)
              this.WireUrl = response.data.result[0].wireURL;
              this.twitterUrl = response.data.result[0].twitterURL;
              this.instagramUrl = response.data.result[0].instagramURL;
              this.facebookUrl =  response.data.result[0].facebookURL;
          
          

            this.spinner.hide();
          
          }else{
            this.spinner.hide();
            console.log("not recods found")
          }       

        },

      )
  }
}
