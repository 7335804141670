import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder } from '@angular/forms';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { ToastrService } from 'ngx-toastr';
import { AuthService, FacebookLoginProvider } from 'angularx-social-login';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  modal: any = {};
  users: any = {};
  model: any = {};

  private optionstype: string[] = ["Admin", "Customer"];
  selectedUserType = "Customer";
  isTextFieldType: boolean;
  isConfirmTextFieldType : boolean;
  iconconfirm : any ={};
  icon : any;
  constructor(private userService: UserService, private _router: Router, private toastr: ToastrService, private firewireservices: FireWireServices, private AuthService: AuthService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }



  register() {  

    if(this.model.first_name !=undefined && this.model.email !=undefined && this.model.phone != undefined && this.model.password !=undefined && this.model.confirmpassword != undefined  && this.model.terms != undefined)
    {
      if(this.model.phone.length === 10){
      if(this.model.password === this.model.confirmpassword)
      {
    
      var datavalue = {
        first_name: this.model.first_name,
        last_name: this.model.last_name,
        user_name: this.model.user_name,
        address: this.model.address,
        country: this.model.country,
        state: this.model.state,
        city: this.model.city,
        phone: this.model.phone,
        post_code: this.model.post_code,        
        email: this.model.email,
        password: this.model.password,
        user_type: "Customer"
      }
      this.spinner.show();
      this.firewireservices.registerInsert(datavalue)
        .subscribe(
          response => {
            var data = response.data        
            if(data.status == 1)
            {
              this.toastr.success(data.message);
              this._router.navigate(['/login']);
              this.spinner.hide();
            }else{
              this.toastr.error(data.message);
              this.spinner.hide();
            }         
          },
          err => {
            this.toastr.error('Register Unsuccessfully');
            this.spinner.hide();
          });
    }
   }
  }
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  RegisterModal(){
    document.getElementById('registerModal').click();
  }
  togglePasswordFieldType(id) {
    this.icon = id    
    this.isTextFieldType = !this.isTextFieldType;
  }
  toggleConfirmPasswordFieldType(val){
    this.iconconfirm = val
    this.isConfirmTextFieldType = !this.isConfirmTextFieldType;
  }

      // <-----------Facebook------------>

      signInWithFB(): void {
        // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => console.log(x));
        let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
        this.AuthService
          .signIn(FacebookLoginProvider.PROVIDER_ID)
          .then((userData) => {
            //this will return user data from facebook. What you need is a user token which you will send it to the server
            // console.log("user facebook",userData.photoUrl)
            var path = userData.photoUrl;
            // sessionStorage.setItem("socialimg",JSON.stringify(path));
            this.SavesresponseFacebook(userData);
            // this._router.navigate(['dashboard']);
            // console.log(userData)
          });
      }
      SavesresponseFacebook(userData) {
        var data = {
          first_name: userData.firstName,
          last_name: userData.lastName,
          email: userData.email,
          facebook_id: userData.id,
          image: userData.photoUrl,
    
        }
        this.spinner.show()
        this.userService.socialRegister(data)
          .subscribe(
            data => {
              var custdetails = data;
              this.socialLoginFacebook(userData.id)
            },
            error => {
              console.log(error);
            });
        // console.log(data)
      }
    
      socialLoginFacebook(userid) {
        var userSet = {
          facebook_id: userid,
          provider: 'facebook'
        };
    
        this.userService.social_login(userSet).subscribe((res: any) => {
          this.spinner.hide()
          // debugger;
          this._router.navigate(['my-quiver-account']);
        });
      }
    
      // <-----------Facebook  End------------>
}

