import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-message-chat',
  templateUrl: './message-chat.component.html',
  styleUrls: ['./message-chat.component.css']
})
export class MessageChatComponent implements OnInit {
userid
chatName
subjectName
ID
msgtblID
  constructor(private dataService: DataService,private firewireservices:FireWireServices) {

    this.userid = localStorage.getItem('userId')
    this.chatName = localStorage.getItem('chatTo')
    this.subjectName = localStorage.getItem('subject_name')
    this.ID = localStorage.getItem('id')
    this.msgtblID = localStorage.getItem('msgtbl_id')
   }
   public receId ={}

  ngOnInit(): void {
    this.dataService.share
    .subscribe(
      data => {
        var list;
        list = data
        var id = list.user_id 
        this.getmessageConvo() 
      }
    )
  }

  
public chatList = [];
  getmessageConvo(){
    this.receId = localStorage.getItem('recid')
     var uid={
      sent:this.receId,
      received:this.userid,
      id:localStorage.getItem('id'),
      msgtbl_id:localStorage.getItem('msgtbl_id')
    }
    
    this.firewireservices.getmessageConvo(uid)
    .subscribe(
      response => {
         
        var str;
        var list = []

          this.chatList = response.data.result
      
        // console.log(this.chatList)
 
      },

    )
      
  }
  sendMsg:any={};

  // send(){
  //   this.chatList =[]
  //   console.log(this.sendMsg)

  // }

  send(){

    var dataval = {
      received:this.receId,
      sent:this.userid,
      message:this.sendMsg.message,
      subject_name:this.subjectName
    }
    // console.log(dataval)
    // this.router.navigate(['checkout-mobile']) 
  
    this.firewireservices.sendMessage(dataval)
      .subscribe(
        response => {
          var data = response.data
          // console.log(data)
          if (data.status == 1) {
            this.getmessageConvo();
            this.sendMsg.message =""
            
           
          } else {
            
          }
        },
        err => {
          // this.toastr.error('Add To WishList Unsuccessfully');
         

        }
      )
  
    }

}
