import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { appConfig } from 'src/app/app.config';
import { AuthenticationService } from 'src/app/services';
import { DataService } from 'src/app/services/data.service';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
}) 
export class HeaderComponent implements OnInit {
  public isActive = false;
  isToken
  user = localStorage.getItem('userId');
  public msgtotalCount
  public messageList = [];
  public isRead: any = [];
  public productval: any = [];
  public cartnotification: boolean = false;
  msgData: any;

  constructor(private router: Router, private userService: UserService, private toastr: ToastrService, private firewireservices: FireWireServices, private dataService: DataService, private route: ActivatedRoute,
     private spinner: NgxSpinnerService, private authService: AuthenticationService, private _router: Router, private AuthService: AuthService) {
    this.isToken = localStorage.getItem('token');
    //  this. getAllUserMessages();
  }

  ngOnInit(): void {
    if(this.isToken){
      this.getAllUserMessages()

    }
    this.dataService.productcontentmsg
      .subscribe(
        data => {
          this.msgData = data;
           console.log(this.msgData)
           if(this.msgData == true) {
            this.getAllUserMessages()
           }
        })

    this.productval = JSON.parse(localStorage.getItem('Product_Details'))

    if (this.productval != null) {
      this.getnotification()
    }
  }

  getAllUserMessages() {
    var userID = {
      user_id: this.user
    }
    this.spinner.show();
    this.firewireservices.getMessageByUser(userID)
      .subscribe(
        res => {
          if (res.data.verified == 1) {
          var list = []
          var messageData = res.data.info;
          this.spinner.hide();
          this.firewireservices.getAllUsers()
            .subscribe(
              response => {
                // var data = response
                var userData = response.data.result;
                var subjectname;
                userData.forEach(user => {
                  var cnt = 0;
                  var tempid;
                  var userid1;
                  var userid2;
                  var userstatus1;
                  var userstatus2;
                  var icon;
                  var id;
                  var msgtblID;
                  var product_name;
                  messageData.forEach(msg => {
                    if (user.user_id == msg.sent || user.user_id == msg.received) {
                      if (user.user_id != this.user) {
                        if (msg.received == this.user && msg.is_read == "0") {
                          cnt = cnt + 1;
                        }
                        tempid = user.user_id
                      }
                      subjectname = msg.subject_name
                      msgtblID = msg.msgtbl_id
                      id = msg.id
                      userid1 = msg.userid_1;
                      userid2 = msg.userid_2;
                      userstatus1 = msg.user1status;
                      userstatus2 = msg.user2status;
                      icon = appConfig.hs + msg.icon;
                      product_name = msg.product_name;
                    }
                  });
                  var str = {
                    user_id: user.user_id,
                    id: id,
                    msgtbl_id: msgtblID,
                    first_name: user.first_name,
                    image: 'assets/images/Picture2.png',
                    icon: icon,
                    product_name: product_name,
                    subject_name: subjectname,
                    msgUnread: cnt,
                    userid1: userid1,
                    userid2: userid2,
                    userstatus1: userstatus1,
                    userstatus2: userstatus2
                  }
                  if (user.user_id == tempid) {
                    list.push(str)
                  }
                });
                let res = [];
                list.map(function (item) {
                  var existItem = res.find(x => x.first_name == item.first_name);
                  if (existItem) {
                  }
                  else {
                    res.push(item);
                  }
                });
                this.messageList = res;
                let totalCount = 0;
                this.messageList.forEach(msgelem => {
                  if(msgelem.msgUnread > 0){
                 totalCount = totalCount + msgelem.msgUnread;

                  }
                });
                this.msgtotalCount = totalCount
                var listmsg = []
                this.spinner.hide();
             
              },
              err => {
              }
            )
            } else {
              this.spinner.hide();
              this.messageList = []
            }
        },
        err => {
        }
      )
  }
  getnotification() {


    if (this.productval.length != 0) {
      this.cartnotification = true
    } else {
      this.cartnotification = false

    }

  }

  verifyMsgToken() {
    if (this.isToken != null) {
      this.router.navigate(['/message-list'])

    } else {
      document.getElementById('registerModal').click()
      // this.router.navigate(['/login'])
    }
  }

  verifyUserToken() {
    if (this.isToken != null) {
      this.router.navigate(['/my-quiver-account'])

    } else {
      document.getElementById('registerModal').click()
      // this.router.navigate(['/login'])
    }
  }


  onChange() {
    // this.isActive =! this.isActive;     
    this.router.navigate(['menu-drop-down'])

  }


  SalepageDetailsModal() {
    if (this.isToken != null) {
      this.router.navigate(['/saledetailpage']);
      this.UpdateDetails();
    } else {
      document.getElementById('registerModal').click()
      //  this.router.navigate(['/login']);      
    }
  }
  MessageModal() {
    if (this.isToken != null) {
      this.router.navigate(['/message-list']);
    } else {
      document.getElementById('registerModal').click()
      //  this.router.navigate(['/login']);      
    }
  }
  ProfileModal() {
    if (this.isToken != null) {
      this.router.navigate(['/my-quiver-account']);
    } else {
      document.getElementById('registerModal').click()
      //  this.router.navigate(['/login']);      
    }
  }

  reviewModal() {
    if (this.isToken != null) {
      this.router.navigate(['/review']);
    } else {
      document.getElementById('registerModal').click()
      //  this.router.navigate(['/login']);      
    }
  }

  videoModal() {
    if (this.isToken != null) {
      this.router.navigate(['/how-to']);
    } else {
      document.getElementById('registerModal').click()
      //  this.router.navigate(['/login']);      
    }
  }

  TransactionModal() {
    if (this.isToken != null) {
      this.router.navigate(['/transaction']);
    } else {
      document.getElementById('registerModal').click()
      //  this.router.navigate(['/login']);      
    }
  }
  // LogtoutModal(){
  //   if(this.paymenttoken !=null){
  //     this.router.navigate(['/logout']);
  //   }else{
  //          this.router.navigate(['/login']);      
  //   }      
  // }
  // searchModal(){
  //   if(this.isToken !=null){
  //     this.router.navigate(['/search-mobile']);
  //   }else{
  //     document.getElementById('registerModal').click()
  //          this.router.navigate(['/login']);      
  //   } 
  // }
  UpdateDetails() {
    var odersum = {
      data: undefined,
    }
    this.dataService.updateData(odersum);
    this.router.navigate(['saledetailpage'])
  }




  // <-----------Facebook------------>

  signInWithFB(): void {
    // this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => console.log(x));
    let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    this.AuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((userData) => {
        //this will return user data from facebook. What you need is a user token which you will send it to the server
        // console.log("user facebook",userData.photoUrl)
        var path = userData.photoUrl;
        // sessionStorage.setItem("socialimg",JSON.stringify(path));
        this.SavesresponseFacebook(userData);
        // this._router.navigate(['dashboard']);
        // console.log(userData)
      });
  }
  SavesresponseFacebook(userData) {
    var data = {
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      facebook_id: userData.id,
      image: userData.photoUrl,

    }
    this.spinner.show()
    this.userService.socialRegister(data)
      .subscribe(
        data => {
          var custdetails = data;
          this.socialLoginFacebook(userData.id)
        },
        error => {
          console.log(error);
        });
    // console.log(data)
  }

  socialLoginFacebook(userid) {
    var userSet = {
      facebook_id: userid,
      provider: 'facebook'
    };

    this.userService.social_login(userSet).subscribe((res: any) => {
      this.spinner.hide()
      // debugger;
      this._router.navigate(['my-quiver-account']);
    });
  }

  // <-----------Facebook  End------------>




  // <-----------Google------------>


  // signInWithGoogle(): void {
  //   // alert()
  //   // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => console.log(x));

  //   let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
  //   this.AuthService
  //     .signIn(GoogleLoginProvider.PROVIDER_ID)
  //     .then((userData) => {
  //       // var path = userData.photoUrl;
  //       // sessionStorage.setItem("socialimg",JSON.stringify(path));
  //       //this will return user data from facebook. What you need is a user token which you will send it to the server
  //       this.SavesresponseGoogle(userData);
  //       // this._router.navigate(['dashboard']);
  //       // console.log(userData)
  //     });
  // }

  signInWithGoogle(): void {
    // debugger
    this.AuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
        this.SavesresponseGoogle(userData);
        // this._router.navigate(['dashboard']);
        console.log(userData)
      });
  }
  SavesresponseGoogle(userData) {
    var data = {
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      google_id: userData.id,
      image: userData.photoUrl,
    }

    this.spinner.show()

    this.userService.socialRegister(data)
      .subscribe(
        data => {
          var custdetails = data;
          this.socialLoginGoogle(userData.id)
        },
        error => {
          console.log(error);
        });
  }
  socialLoginGoogle(userid) {
    var userSet = {
      google_id: userid,
      provider: 'google'
    };
    this.userService.social_login(userSet).subscribe((res: any) => {
      // debugger;
      this.spinner.hide()
      this._router.navigate(['my-quiver-account']);
    });
  }

  // <-----------Google End------------>

}
