import { Component } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
// import { AngularFireMessaging } from '@angular/fire/messaging';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'firewire';
  message;

  constructor(private afMessaging: AngularFireMessaging,) { }

  async ngOnInit() {
    this.requestPermission()
    await this.listen();
    //  this.messagingService.requestPermission()
    // this.messagingService.receiveMessage()
    // this.message = this.messagingService.currentMessage
   }

  requestPermission() {
    this.afMessaging.requestToken
      .subscribe((token) => {
        // alert()
        console.log(token)
        localStorage.setItem('fcm_token', JSON.stringify(token))
      },
        (error) => { console.log(error) })
  }

   async listen() {
    // return new Promise(async resolve => {
    const resp = this.afMessaging.messages
      .subscribe(async (message: any) => {
        console.log(message)
        // localStorage.setItem("notification", JSON.stringify(this.list))
      })
  }
  

}
