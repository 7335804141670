import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';
import { FireWireServices } from 'src/app/services/FireWireServices';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  model: any = {};
  public userid;
  isTextFieldType: boolean;
  isConfirmTextFieldType: boolean;
  iconconfirm: any = {};
  icon: any;
  constructor(private datePipe: DatePipe, private firewireservices: FireWireServices, private dataService: DataService, private formBuilder: FormBuilder, private toastr: ToastrService, private _route: ActivatedRoute, private router: Router, private spinner: NgxSpinnerService) {
    this.userid = localStorage.getItem('userId');
  }

  ngOnInit(): void {
  }

  ChangePassword() {

    if (this.model.password != undefined && this.model.confirmpassword != undefined) {
      if (this.model.password == this.model.confirmpassword) {
        var data = {
          user_id: this.userid,
          password: this.model.password
        }
        this.spinner.show();
        this.firewireservices.changePassword(data)
          .subscribe(respouse => {
            var dataval = respouse.data
            if (dataval.status == 1) {
              this.spinner.hide();
              this.toastr.success(dataval.message);
              this.model = {};
            } else {
              this.spinner.hide();
              this.toastr.error("Password not Updated");
            }
          }), err => {
            console.log("error");
            this.spinner.hide();
          }
      }
    }

  }
  togglePasswordFieldType(id) {
    this.icon = id
    this.isTextFieldType = !this.isTextFieldType;
  }
  toggleConfirmPasswordFieldType(val) {
    this.iconconfirm = val
    this.isConfirmTextFieldType = !this.isConfirmTextFieldType;
  }
}
