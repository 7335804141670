import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { appConfig } from '../../app.config';
import { element } from 'protractor';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-saledetailpage',
  templateUrl: './saledetailpage.component.html',
  styleUrls: ['./saledetailpage.component.css']
})
export class SaledetailpageComponent implements OnInit {



  public coloroptions: string[] = ["black", "blue", "White"];
  public selectedColor: any = "black";
  // public priceList: string[] = ["US DOLLAR", "AUS DOLLAR", "EURO", "GBP", "JPY", "RAND"];

  public productType: string[] = ["Surfboard", "Fins"];
  public selectedPType: any = "Surfboard";
  files: File[] = [];
  file: any = {};
  public FileList;
  fileData: File = null;
  previewUrl: any = null;
  uploadForm: FormGroup;
  fileid: any;
  product: any = {};
  public locationL: any = {};
  public isTabActive;

  public priceList = [
    { value: "US DOLLAR", label: "US DOLLAR" },
    { value: "AUS DOLLAR", label: "AUS DOLLAR" },
    { value: "EURO", label: "EURO" },
    { value: "GBP", label: "GBP" },
    { value: "JPY", label: "JPY" },
    { value: "RAND", label: "RAND" },
  ];


  public locationdetails = [
    { value: "five fin - futures", label: "Five Fin - Futures" }
  ];
  public locationdetailslist = [];
  public ftype = [
    { value: "five fin - futures", label: "Five Fin - Futures" },
    { value: "five fin - fCS", label: "Five Fin - FCS" },
  ];

  public colortypes = [
    { value: "black", label: "Black" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" },
  ];

  public producttypes = [
    { value: "surfboard", label: "Surfboard" },
    { value: "fins", label: "Fins" },
    { value: "longboard", label: "Longboard" },
    { value: "shoartboard", label: "Shortboard" },
    { value: "kiteboard", label: "Kiteboard" },
    { value: "sup", label: "Sup" },
    { value: "foilboard", label: "Foilboard" }
  ];
  public productstatus = [
    { value: "New", label: "NEW" },
    { value: "Used", label: "USED" }

  ];
  public userType = [
    { value: "Retailer", label: "Retailer" },
    { value: "Warehouse", label: "Warehouse" },
    { value: "Customer", label: "Customer" }

  ];
  public shippingType = [
    { value: "1", label: "Pick up" },
    { value: "2", label: "Shipping" }, 
  ];

  public boardStatusList = [
    { value: "1", label: "For Sale" },
    { value: "2", label: "Lost/Stolen" }, 
    { value: "3", label: "My Online Quiver" }
  ];

  public ShippingValues;
  public productstatusval;
  public locoptions: string[] = ["Retailer", "Warehouse", "Customer"];
  public selectedType: any = "Retailer";
  public userid;
  public types = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  public sizefield: any = [];
  public addColor: any = [];
  saledata: any = {};

  public formFlag = false;
  userForm: FormGroup;
  usersizeForm: FormGroup;

  public value: boolean = true;
  public testValue = { value: true };
  @ViewChild('search', {static: false}) searchElementRef: ElementRef;
  lat : any = 0
  lng : any = 0
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;

  
  @ViewChild('takeInput', {static: false}) 
    
// this InputVar is a reference to our input. 
  
  InputVar: ElementRef;

  constructor(private firewireservices: FireWireServices, private formBuilder: FormBuilder,
     private elementRef: ElementRef, private fb: FormBuilder, private http: HttpClient,
      private dataService: DataService, private spinner: NgxSpinnerService,private toastr: ToastrService,
    private _route: ActivatedRoute, private router: Router,  private mapsAPILoader: MapsAPILoader,private ngZone: NgZone) {
    this.userid = localStorage.getItem('userId');
    // console.log(this.userid)
    this.getAllLocation();
    this.userForm = this.fb.group({
      color: this.fb.array([
        this.fb.control(null, [Validators.required])
      ])
    }),

      this.usersizeForm = this.fb.group({
        size: this.fb.array([
          this.fb.control(null, [Validators.required])
        ])
      })

  }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    }),
      this.dataService.share
        .subscribe(
          data => {
            this.saledata = data;
            // console.log(this.saledata)
            if (this.saledata.data != undefined) {
              this.editdetails();
              // this.patchValue1(this.saledata);
            }

          }
        )

  }


  editdetails() {
    // console.log('patchValue1', this.saledata)
    this.isTabActive = 0;
    if (this.saledata.data != undefined) {
      this.product = this.saledata.data
      this.product.resamt = this.saledata.data.reserve_amount;
      // this.product.boardStatus = this.saledata.data.boardstatus
      // this.product.product_id = this.saledata.data.product_id
      // this.product.pro_id = this.saledata.data.pro_id
      // this.product.name = this.saledata.data.name
      // this.product.brand_name = this.saledata.data.brand_name
      // this.product.technology = this.saledata.data.technology
      // this.product.fin_system = this.saledata.data.fin_system
      // this.product.price = this.saledata.data.price
      // this.product.type = this.saledata.data.type
      // this.product.status = this.saledata.data.status

      // if (this.saledata.data.status == "New") {
      //   this.productstatusval = "New";
      //   this.product.tax = this.saledata.data.tax
      // }

      // this.product.location = this.saledata.data.location
      // var sizeval = this.saledata.data.size;
      // let SizeArray = sizeval.split(",");
      // var size = { size: SizeArray };
      // this.updateSize(size);
      // console.log(SizeArray)
      // SizeArray.forEach(element => {
      //   this.usersizeForm.push({ size: element })
      // });

      // var colorval = this.saledata.data.color.replace(/"/g, '');
      // let toArray = colorval.split(",");
      // var color = { color: toArray };
      // this.updateColor(color);
      
      if (this.saledata.data.fixrate != null && this.saledata.data.fixrate !=undefined ) {
        this.ShippingValues = "2";
        this.product.shipptype = "2"
        this.product.fixrate = this.saledata.data.fixrate;
      }
      if (this.saledata.data.pic_up != null && this.saledata.data.pic_up !=undefined) {
        this.ShippingValues = "1";
        this.product.shipptype = "1"
        this.product.pic_up = this.saledata.data.pic_up;
      }

      this.getAllEditProductImages(this.saledata.data.product_id)


      
// if (this.beauty.galleryImages == null) {
//   this.files = [];
//   this.beauty = data;
// } else {
//   if (this.beauty.galleryImages != "")
//     this.files = [];
//   var gallery_Images = this.beauty.galleryImages;
//   // var images = gallery_images.split(",");
//   var list = [];
//   gallery_Images.forEach(element => {
//     element.image_url = this.serverPath + element.image_url;
    
//   });
//   // this.files = list;
//   this.gallImages = gallery_Images;
//   console.log(this.gallImages)
// }
    }


  }

  public productImages = [];
  getAllEditProductImages(product_id){
    var data = {
      product_id:product_id
    }
    this.spinner.show();
    this.firewireservices.getProductImagesByProduct_id(data)
    .subscribe(
      response => {

        if (response.data.status == 1) { 
          var list=[];
          var results =[]
          results = response.data.result
          results.forEach(element => {
            var str={id:element.id,product_id:element.product_id,image: appConfig.hs + element.image}
            list.push(str)
          });
          this.productImages = list;
          // console.log(this.productImages)

          this.spinner.hide(); 
        } else {
          // this.toastr.error(response.data.message);
          this.productImages = [];
          this.spinner.hide();
        }
        

      },
      err => {
        console.log("error");
        this.spinner.hide();
      }
    )

  }
  galleryImageObj:any={}
  deleteGalleryImgModal(img, i) {
    // 
    
      this.galleryImageObj = img;
      this.galleryImageObj.index = i
      // console.log(this.galleryImageObj)
  }
  deleteGalleryImg() {
    this.spinner.show();
    this.firewireservices.deleteGalleryImg(this.galleryImageObj)
      .subscribe(
        data => {
          if (data.data.status == 1) {
            // this.getAllClinics();
            this.getAllEditProductImages(this.saledata.data.product_id)
            // this.beautyflag = false;
            this.toastr.success(data.data.message);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        },
        error => {
          console.log(error);
          // this.toastr.error(error.message);
          this.spinner.hide();
        });
  
  }


  onFileSelectproduct(event, param) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.formData.append('file', file, file.name);  
      // console.log(file);
      this.fileid = param;
      this.uploadForm.get('profile').setValue(file);
      this.fileData = file;
      // this.Addimage(file);
    }

    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.fileData = <File>event.target.files[0]
      var mimeType = this.fileData.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = (_event) => {
        this.previewUrl = reader.result;
        // this.img = this.fileData.name;
      }
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPressShip(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  ShippMethod(data) {
    setTimeout(() => {
      this.findAdress();
    }, 1000);
    // console.log(data);
    if (data != undefined) {
      this.ShippingValues = data.value;
      if (this.ShippingValues == "1") {
        this.product.pic_up = null
        this.product.fixrate = null
      } 
      if(this.ShippingValues == "2") {
        this.product.fixrate = null
        this.product.pic_up = null
      }
    } else {
      // console.log("no data found");
      this.ShippingValues = 0;
    }
  }

  ProductMethod(data) {
    // console.log(data)
    if (data != undefined) {
      this.productstatusval = data.value;
    } else {
      // console.log("no data found");
      this.productstatusval = 0;
    }
  }



  onFileSelected(event) {
    // this.files.push(event.addedFiles);
    event.addedFiles.forEach(element => {
      this.files.push(element)
    });
    // console.log(this.files);
  }
  async uploadGallery(id) {

    if(this.files.length !=0)
    {
    for (let j = 0; j < this.files.length; j++) {
      let data = new FormData();
      let fileItem = this.files[j]
      data.append('file', fileItem);
      this.uploadFile2(id, data);
    }
  }else{
    this.router.navigate(["/my-quiver-account"]);
  }
  }

  // async uploadFile2(id, data: FormData) {
  //   // https://208.109.15.102:3020/shops/gallery_images/1
  //   this.http.post(appConfig.apiUrl + '/product/product_images/' + id, data, { reportProgress: true, observe: 'events' })
  //     .subscribe(event => {
  //       if (event.type === HttpEventType.UploadProgress) {
  //       } else if (event instanceof HttpResponse) {
  //         // this.toastr.success(response.data.message); 
  //       }
  //     });
  // }

  async uploadFile2(id, data: FormData) {
    return new Promise((resolve, reject) => {
      // your upload code
      this.http.post(appConfig.apiUrl + '/product/product_images/'+ id, data, { reportProgress: true, observe: 'events' })
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
          } else if (event instanceof HttpResponse) {
            if (event.status == 200) {
              resolve("");
              // this.toastr.success("Product Added successfully.");
              // this.spinner.hide();
              // this.toastr.success("Product Added successfully.");
              this.router.navigate(["/my-quiver-account"]);

            }
          }
        },
        error => {
          console.log(error);
          this.toastr.error("Something went wrong..");
          this.spinner.hide();
        });
    });
  }

  deleteFromGalley(i) {
    this.files.splice(this.files.indexOf(i), 1);

  }

  findAdress() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // some details
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          let address = place.formatted_address;
          let web_site = place.website;
          let name = place.name;
          let zip_code = place.address_components[place.address_components.length - 1].long_name;
          //set latitude, longitude and zoom
          let latitude = place.geometry.location.lat();
          let longitude = place.geometry.location.lng();
          let zoom = 12;
          console.log(latitude, longitude, address)
          this.lat = latitude;
          this.lng = longitude;
          this.address = address
          this.product.pic_up = this.address
        });
      });
    });
  }
  

  AddProduct() {
    // var data = colorval.color;
    // console.log("color",data)
    // var sizedta = sizeval.size;

    // if (this.product.pro_id != undefined && this.product.name != undefined && this.product.brand_name != undefined && this.product.technology != undefined && this.product.fin_system != undefined && this.product.technology != undefined && this.product.type != undefined && this.product.status != undefined && this.product.location != undefined && this.product.price != undefined) {
      // var file = this.files[0]
      this.spinner.show();
      
      var formData = new FormData();     
      formData.append('pro_id', this.product.pro_id)
      formData.append('user_id', this.userid);
      formData.append('name', this.product.name);
      formData.append('brand_name', this.product.brand_name);
      formData.append('shaper', this.product.shaper);
      formData.append('technology', this.product.technology);
      formData.append('fin_system', this.product.fin_system);
      formData.append('type', this.product.type);
      formData.append('status', this.product.status);
    
      // if (this.product.status == "New") {
      //   formData.append('tax', this.product.tax);
      //   formData.append('status', this.product.status);
      // } else {
      //   formData.append('status', this.product.status);
      // }

      formData.append('color', this.product.color);
      formData.append('size',  this.product.size);
      formData.append('owner', "Firewire");
      // formData.append('location', this.product.location);
      formData.append('currency', this.product.currency);
      formData.append('price', this.product.price);
      formData.append('resamt', this.product.resamt);
      formData.append('boardstatus', this.product.boardstatus); //1- forsale, 2-- stolen , 3-- quiver
      formData.append('details', this.product.details);
      if (this.product.fixrate != "" && this.product.fixrate != undefined) {
        formData.append('fixrate', this.product.fixrate);
      }
      if (this.product.pic_up != "" && this.product.pic_up != undefined) {
        formData.append('pic_up', this.product.pic_up);
        formData.append('lat', this.lat ? this.lat : 0);
        formData.append('lng', this.lng ? this.lng : 0);
      }
      // if(this.product.boardStatus == '1'){
      //   formData.append('boardStatus', 'forsale');

      // }
      // if(this.product.boardStatus == '2'){
      //   formData.append('boardStatus', 'stolen');

      // }
      // if(this.product.boardStatus == '3'){
      //   formData.append('boardStatus', 'quiver');

      // }

      this.firewireservices.AddProduct(formData)
        .subscribe(
          response => {

            if (response.data.status == 1) {
              var id = response.data.result.product_id
              this.toastr.success(response.data.message)
              this.spinner.hide(); 
              this.uploadGallery(id)       
               
              this.reset();   
                     
             
              // this.getAllProduct();
              // this.addColors();
              // this.addSizeText();
            } else {
              this.toastr.error(response.data.message);
              this.spinner.hide();
            }
            this.product = {};

          },
          err => {
            console.log("error");
            this.spinner.hide();
          }
        )
   
      
  }



  updateProduct(sizes, colors) {
    // console.log("color", colors)
    // var data = colors.color;
    // var list = []
    // colors.color.forEach(element => {
    //   if (element != null) {
    //     list.push(element)
    //   }
    // });
    // var data = list
    // // console.log("color",data)
    // var listsize = []
    // sizes.size.forEach(element => {
    //   if (element != null) {
    //     listsize.push(element)
    //   }
    // });
    // console.log(listsize) 
    // var sizedta: any = listsize;
    // console.log("size", sizedta);
    // var file = this.files[0]
    var formData = new FormData();
    // formData.append('file', file);
    formData.append('product_id', this.saledata.data.product_id)
    formData.append('pro_id', this.product.pro_id)
    formData.append('user_id', this.userid);
    formData.append('name', this.product.name);
    formData.append('brand_name', this.product.brand_name);
    formData.append('shaper', this.product.shaper);
    formData.append('technology', this.product.technology);
    formData.append('fin_system', this.product.fin_system);
    formData.append('type', this.product.type);
    formData.append('status', this.product.status);

    formData.append('color', this.product.color);
    formData.append('size',  this.product.size);
    formData.append('owner', "Firewire");
    // formData.append('location', this.product.location);
    formData.append('currency', this.product.currency);
    formData.append('price', this.product.price);
    formData.append('resamt', this.product.resamt);
    formData.append('boardstatus', this.product.boardstatus); //1- forsale, 2-- stolen , 3-- quiver
    formData.append('details', this.product.details);

    if (this.product.fixrate != "" && this.product.fixrate != undefined) {
      formData.append('fixrate', this.product.fixrate);
    }
    if (this.product.pic_up != "" && this.product.pic_up != undefined) {
      formData.append('pic_up', this.product.pic_up);
      formData.append('lat', this.lat ? this.lat : 0);
      formData.append('lng', this.lng ? this.lng : 0);
    }
    // formData.append('pro_id', this.product.pro_id)
    // formData.append('user_id', this.userid);
    // formData.append('name', this.product.name);
    // formData.append('brand_name', this.product.brand_name);
    // formData.append('technology', this.product.technology);
    // formData.append('fin_system', this.product.fin_system);
    // formData.append('type', this.product.type);
    // if (this.product.status == "New") {
    //   formData.append('tax', this.product.tax);
    //   formData.append('status', this.product.status);
    // } else {
    //   formData.append('status', this.product.status);
    // }
    // formData.append('color', JSON.stringify(data));
    // formData.append('size', (sizedta));
    // formData.append('owner', "Firewire");
    // formData.append('location', this.product.location);
    // formData.append('price', this.product.price);
    // if (this.product.fixrate != '' && this.product.fixrate != undefined) {
    //   formData.append('fixrate', this.product.fixrate);
    // }
    // if (this.product.pic_up != '' && this.product.pic_up != undefined) {
    //   formData.append('pic_up', this.product.pic_up);
    // }

    // this.spinner.show();
    this.firewireservices.updateProductDetails(formData)
      .subscribe(
        response => {
          //  console.log(response.data.result)
          if (response.data.status == 1) {
            var id = response.data.result.product_id
           var productlist = response.data.result
              this.uploadGallery(id);
            //   var productval = localStorage.getItem('Product_Details');
            //   var dataval = JSON.parse(productval);
            //   dataval.forEach(element => {   
            //     var status = productlist.find(x => x.product_id === element.product_id);
            //     if(status !=undefined){
            //       element.price = response.data.result.price;
            //     }
            // });
            // console.log("update",dataval)
              this.toastr.success(response.data.message);
              // this.router.navigate(["/my-quiver-account"]);
              this.spinner.hide();
              this.product = {};               
              
          } else {
            this.spinner.hide();
            // this.product = {};
            this.toastr.error(response.data.message);
          }
        },
        err => {
          console.log("error");
          this.spinner.hide();
        }
      )
  }

  reset()  
  { 
    
// We will clear the value of the input  
// field using the reference variable. 

    this.InputVar.nativeElement.value = ""; 
    this.userForm.clearValidators();
  }
  getAllLocation() {
    var data = {
      user_id: this.userid
    }
    // console.log(data)
    this.spinner.show();
    this.firewireservices.getLocationByuserID(data)
      .subscribe(
        response => {
          var data = response.data.result
          if (data.length > 0) {
            var list = []
            data.forEach(element => {
              var str = { label: element.name, value: element.name }
              list.push(str)
            });
            this.locationdetailslist = list;
            // console.log("location", this.locationdetailslist);
            this.spinner.hide();
          } else {
            console.log("no data found");
            this.spinner.hide();
          }

        }, err => {
          console.log("error");
          this.spinner.hide();

        }
      )
  }



  addLocation() {
    // alert()
    // console.log(this.locationL)

    if (this.locationL.loc_id != undefined && this.locationL.loc_name != undefined && this.locationL.contact_name != undefined && this.locationL.address != undefined && this.locationL.country != undefined && this.locationL.state != undefined && this.locationL.city != undefined && this.locationL.post_code != undefined && this.locationL.email != undefined && this.locationL.phone_number != undefined) {

      var datavalue = {
        loc_id: this.locationL.loc_id,
        name: this.locationL.loc_name,
        user_id: this.userid,
        contact_name: this.locationL.contact_name,
        address: this.locationL.address,
        country: this.locationL.country,
        state: this.locationL.state,
        city: this.locationL.city,
        post_code: this.locationL.post_code,
        email: this.locationL.email,
        phone_number: this.locationL.phone_number,
        user_type: this.selectedType,

      }

      this.spinner.show();
      // console.log(datavalue)
      this.firewireservices.AddLocation(datavalue)
        .subscribe(
          response => {
            if (response.data.status == 1) {
              this.getAllLocation();
              this.spinner.hide();
              this.toastr.success(response.data.message);
              document.getElementById('openModalForschedule').click()


            } else {
              this.spinner.hide();
              this.toastr.error(response.data.message);
            }

          },
          err => {
            console.log("error");
            this.spinner.hide();
          }
        )
    }
  }

  addPhone(): void {
    (this.userForm.get('color') as FormArray).push(
      this.fb.control(null)
    );
  }

  updateColor(colors) {
    colors.color.forEach(element => {
      (this.userForm.get('color') as FormArray).push(
        this.fb.control(element)
      );
    });

  }

  removePhone(index) {
    (this.userForm.get('color') as FormArray).removeAt(index);
  }

  getPhonesFormControls(): AbstractControl[] {
    return (<FormArray>this.userForm.get('color')).controls
  }

  // sendcolor(values) {
  //   console.log(values);
  // }


  addSize(): void {
    (this.usersizeForm.get('size') as FormArray).push(
      this.fb.control(null)
    );
  }
  updateSize(sizes) {
    sizes.size.forEach(element => {
      (this.usersizeForm.get('size') as FormArray).push(
        this.fb.control(element)
      );
    });

  }
  removeSize(index) {
    (this.usersizeForm.get('size') as FormArray).removeAt(index);
  }

  getSizesFormControls(): AbstractControl[] {
    return (<FormArray>this.usersizeForm.get('size')).controls
  }

  sendsize(values) {
    // console.log(values);
  }



  // urls = [];
  // onSelectFile(event) {
  //   if (event.target.files && event.target.files[0]) {
  //       var filesAmount = event.target.files.length;
  //       for (let i = 0; i < filesAmount; i++) {
  //               var reader = new FileReader();

  //               reader.onload = (event:any) => {
  //                 // console.log(event.target.result);
  //                  this.urls.push(event.target.result); 
  //               }

  //               reader.readAsDataURL(event.target.files[i]);
  //       }
  //   }
  // }
}
