import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FireWireServices } from 'src/app/services/FireWireServices';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-search-mobile',
  templateUrl: './search-mobile.component.html',
  styleUrls: ['./search-mobile.component.css']
})
export class SearchMobileComponent implements OnInit {

  public userid;
  public searchuserlist : any = [];
  model: any = {};
  modelChanged: Subject<string> = new Subject<string>();

  constructor(private firewireservices: FireWireServices, private dataService: DataService, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private toastr: ToastrService,
    private _route: ActivatedRoute, private router: Router) {
    this.userid = localStorage.getItem('userId');

    // this.modelChanged.pipe(
    //   debounceTime(2000))
    //   .subscribe(model => {
    //     // console.log(model);
    //     this.searchUser();
    //   });
  }

  changed(text: string) {
    this.modelChanged.next(text);
    
}
  ngOnInit(): void {

  }
  // keyup(){
  //   this.searchUser();
  // }

  Onclick(){
   
  }

  // searchUser() {
  //   var data = {
  //     first_name: this.model.first_name,
  //   }
  //   this.spinner.show();
  //   this.firewireservices.getAllSearch(data)
  //     .subscribe(
  //       response => {
  //         var data = response.data.result
  //         if (data.length > 0) {        
  //           data.forEach(element => {
  //             if (element.image != '' && element.image != null) {
  //               // element.image = location.origin + '/' + element.image;
  //               element.image = "https://dhol.app/" + element.image;
  //             } else {
  //               element.image = 'assets/images/Picture2.png'
  //             }
  //           })           
  //           this.searchuserlist = data;  
  //           this.spinner.hide();          
  //         }else{
  //           this.searchuserlist = [];
  //           this.spinner.hide();         
  //         }
       
  //       },
  //       err =>{
  //         console.log("error");
  //         this.spinner.hide(); 
  //       }
  //     )
  // }

  searchUser()
  {    
    var data = {
      first_name: this.model.first_name
    }    
    console.log(data)
    this.dataService.updateData(data)
    this.router.navigate(['search-result-user']);
  }
}
