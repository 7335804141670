import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './helper';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './views/header/header.component';
import { FooterComponent } from './views/footer/footer.component';
import { HomeComponent } from './views/home/home.component';
import { LoginComponent } from './views/login/login.component';
import { AuthGuard } from './guards';
import { AuthenticationService } from './services';
import { CommonModule, LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './views/register/register.component';
import { CustomFormsModule} from 'ng2-validation';
import { UserService } from './services/user.service';

import { SocialLoginModule, AuthService } from 'angularx-social-login';
import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { LogoutComponent } from './views/logout/logout.component';
import { ScanComponent } from './views/scan/scan.component';
import { AboutBoardComponent } from './views/about-board/about-board.component';
import { MyQuiverAccountComponent } from './views/my-quiver-account/my-quiver-account.component';
import { MenuDropDownComponent } from './views/menu-drop-down/menu-drop-down.component';
import { CartPageComponent } from './views/cart-page/cart-page.component';
import { FireWireServices } from './services/FireWireServices';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ThankyouComponent } from './views/thankyou/thankyou.component';
import { MainpageComponent } from './views/mainpage/mainpage.component';

import { NgImageSliderModule } from 'ng-image-slider';
import { SaledetailpageComponent } from './views/saledetailpage/saledetailpage.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { SearchMobileComponent } from './views/search-mobile/search-mobile.component';
import { ToastrModule } from 'ngx-toastr';
import { PaymentComponent } from './views/payment/payment.component';
import { SearchResultMobileComponent } from './views/search-result-mobile/search-result-mobile.component';
import { MessagePageComponent } from './views/message-page/message-page.component';
import { SalePageComponent } from './views/sale-page/sale-page.component';
import { ModalModule, TooltipConfig } from 'ngx-bootstrap';
import { SearchMobileBoardComponent } from './views/search-mobile-board/search-mobile-board.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LocationComponent } from './views/location/location.component';
import { MessageListComponent } from './views/message-list/message-list.component';
import { MessageChatComponent } from './views/message-chat/message-chat.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
// import { SliderModule } from 'angular-image-slider';
// import { ViewuserProductComponent } from './views/viewuser-product/viewuser-product.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
// import { SliderModule } from 'ngx-slider';
import {IvyCarouselModule} from 'angular-responsive-carousel'; 
import { ForgotComponent } from './views/forgot/forgot.component';
import { TransactionComponent } from './views/transaction/transaction.component';
import { CheckoutMobileComponent } from './views/checkout-mobile/checkout-mobile.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SearchResultUserComponent } from './views/search-result-user/search-result-user.component';
import { UserProductComponent } from './views/user-product/user-product.component';
import { ReviewComponent } from './views/review/review.component';
import { AgmCoreModule } from '@agm/core';
import { FollowerComponent } from './views/follower/follower.component';
import { FollowingComponent } from './views/following/following.component';
import {RatingModule} from "ngx-rating";
import { TermsComponent } from './views/terms/terms.component';
import { PaypalPageComponent } from './views/paypal-page/paypal-page.component';
import { HowToComponent } from './views/how-to/how-to.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ReservePaymentPageComponent } from './views/reserve-payment-page/reserve-payment-page.component';
// import {TooltipModule} from "ngx-tooltip";
import { TooltipModule, TypeaheadModule  } from 'ngx-bootstrap';
import { PurchaseComponent } from './views/purchase/purchase.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
// import { MessagingService } from './services/messaging.service';


const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('895770916259-ek6tldnmgnn9965uv09acmb2q1clc8l1.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('659322038104517')
  },
  // {
  //   id: LinkedInLoginProvider.PROVIDER_ID,
  //   provider: new LinkedInLoginProvider("78iqy5cu2e1fgr")
  // }
]);
// const config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     // provider: new GoogleLoginProvider('17498439726-opbu80ciirqrgtml7tikfu5l3pudenfu.apps.googleusercontent.com')
//     provider: new GoogleLoginProvider('17498439726-k387trbd3jkrtahsrgm8r32p5a84054l.apps.googleusercontent.com')

//   },
//   {
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider('2256700081100205')
//   },
//   // {
//   //   id: LinkedInLoginProvider.PROVIDER_ID,
//   //   provider: new LinkedInLoginProvider("78iqy5cu2e1fgr")
//   // }
// ]);
export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    LogoutComponent,
    ScanComponent,
    AboutBoardComponent,
    MyQuiverAccountComponent,
    MenuDropDownComponent,
    CartPageComponent,
    CheckoutMobileComponent,
    ThankyouComponent,
    MainpageComponent,
    SaledetailpageComponent,
    SearchMobileComponent,
    PaymentComponent,
    SearchResultMobileComponent,
    MessagePageComponent,
    SalePageComponent,
    SearchMobileBoardComponent,
    LocationComponent,
    MessageListComponent,
    MessageChatComponent,
    ForgotComponent,
    TransactionComponent,
    ChangePasswordComponent,  
    SearchResultUserComponent, UserProductComponent, FollowerComponent, FollowingComponent,    
    ReviewComponent, TermsComponent, PaypalPageComponent, HowToComponent, ReservePaymentPageComponent, PurchaseComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    CommonModule,
    FormsModule,   
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgImageSliderModule,
    NgxDropzoneModule,
    CustomFormsModule,
     NgSelectModule,
     Ng2SearchPipeModule,
    NgxPayPalModule,
    ToastrModule.forRoot({enableHtml: true}),
    IvyCarouselModule,
    NgxUsefulSwiperModule,
    CarouselModule,
    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyCBvLOSy-pquUBu-q6hUZvLQUBhNddmDZs',
      apiKey: 'AIzaSyDK_8fPB07DLQhW7ttLAuOffsQ0DO3yqs8',
      libraries: ['places']
    }),
    TooltipModule,
    RatingModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),


  ],
  providers: [
    AuthGuard,
    AuthService,
    AuthenticationService,
    FireWireServices,
    NgxSpinnerService,
    UserService,
    TooltipConfig, 
    // MessagingService,
    { provide: HTTP_INTERCEPTORS,useClass: JwtInterceptor, multi: true },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide : DatePipe
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
